import { Link } from "react-router-dom";
import Parallax from "../Main Components/Parallax";
import ButtonCTA from "./ButtonCTA";
import bloglist from "./constants/bloglist";
import motorcyclelist from "./constants/motorcycleslist";

const HomeHero = () => {
    return (
        <>
            <ButtonCTA/>
            <div className="home_motorcycle_category">
            {
                motorcyclelist.map((motorcycle) => (
                    <>
                     <div className="motorcycle_category_inner" key={motorcycle.id}>
                        <Link to={motorcycle.link}><img src={motorcycle.image} alt=""/></Link>
                        <h1>{motorcycle.title}</h1>
                     </div>
                    </>
                ))
            }
            </div>

            <div className="home_blog_container">
            {
                bloglist.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <Link to={item.link} className="blog_item_title">{item.title}</Link>
                        </div>
                    </>
                ))
            }
            </div>

            <Parallax/>
        </>
    )
}

export default HomeHero;