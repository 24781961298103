import { useState } from "react";
import MotorcyclesDropdownFamily from "../Constants/MotorcyclesDropdownFamily";
import dropdownlogobottom from '../Main Images/Motorcycles Dropdown Family/imrg-drawer2.webp';
import MotorcycleDropdownFamily from "./MotorcyclesDropdownFamily";
import { IoMdClose } from 'react-icons/io';

const MotorcyclesDropdown = ({setPopUp, popUp}) => {
    
    const [familyData, setFamilyData] = useState(MotorcyclesDropdownFamily[0]);

    const handleClickedFamily = (family) => {
        const temp = MotorcyclesDropdownFamily.filter((item) => item.family === family);
        setFamilyData(temp[0]);
    }

    const handlePopUp = () => {
        setPopUp(!popUp)
    }

   

    return (
        <div className="motorcycles_dropdown">
            <div className="motorcycles_dropdown_wrapper">
                <div className="motorcycles_dropdown_list">
                <IoMdClose onClick={handlePopUp}/>
                <ul>
                    {
                        MotorcyclesDropdownFamily.map((item) => (
                            <li key={item.id} onClick={() => handleClickedFamily(item.family)}><p>{item.family}</p></li>
                        ))
                    }
                    <li><img src={dropdownlogobottom} alt=''/><p>Indian Motorcycle</p></li>
                </ul>
                </div>
                <div className="motorcycles_dropdown_content">
                    <MotorcycleDropdownFamily family={familyData}/>
                </div>
            </div>
        </div>
    )
}

export default MotorcyclesDropdown;