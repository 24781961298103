import scout1 from '../Carousel Images/Scout-1.webp';
import scout2 from '../Carousel Images/Scout-2.webp';
import scout3 from '../Carousel Images/Scout-3.webp';
import scout4 from '../Carousel Images/Scout-4.webp';
import scout5 from '../Carousel Images/Scout-5.webp';
import scout6 from '../Carousel Images/Scout-6.webp';

import bobber1 from '../Carousel Images/bobber-1.webp';
import bobber2 from '../Carousel Images/bobber-2.webp';
import bobber3 from '../Carousel Images/bobber-3.webp';
import bobber4 from '../Carousel Images/bobber-4.webp';
import bobber5 from '../Carousel Images/bobber-5.webp';
import bobber6 from '../Carousel Images/bobber-6.webp';


import rogue1 from '../Carousel Images/Rogue-1.webp';
import rogue2 from '../Carousel Images/Rogue-2.webp';
import rogue3 from '../Carousel Images/Rogue-3.webp';
import rogue4 from '../Carousel Images/Rogue-4.webp';
import rogue5 from '../Carousel Images/Rogue-5.webp';
import rogue6 from '../Carousel Images/Rogue-6.webp';

import twenty1 from '../Carousel Images/twenty-1.webp';
import twenty2 from '../Carousel Images/twenty-2.webp';
import twenty3 from '../Carousel Images/twenty-3.webp';
import twenty4 from '../Carousel Images/twenty-4.webp';
import twenty5 from '../Carousel Images/twenty-5.webp';
import twenty6 from '../Carousel Images/twenty-6.webp';


const ScoutCarouselImages = [
    {
        id: 1,
        image: scout1
    },
    {
        id: 2,
        image: scout2
    },
    {
        id: 3,
        image: scout3
    },
    {
        id: 4,
        image: scout4
    },
    {
        id: 5,
        image: scout5
    },
    {
        id: 6,
        image: scout6
    },
]

export { ScoutCarouselImages }

const ScoutBobberCarouselImages = [
    {
        id: 1,
        image: bobber1
    },
    {
        id: 2,
        image: bobber2
    },
    {
        id: 3,
        image: bobber3
    },
    {
        id: 4,
        image: bobber4
    },
    {
        id: 5,
        image: bobber5
    },
    {
        id: 6,
        image: bobber6
    },
]

export { ScoutBobberCarouselImages }

const ScoutRogueCarouselImages = [
    {
        id: 1,
        image: rogue1
    },
    {
        id: 2,
        image: rogue2
    },
    {
        id: 3,
        image: rogue3
    },
    {
        id: 4,
        image: rogue4
    },
    {
        id: 5,
        image: rogue5
    },
    {
        id: 6,
        image: rogue6
    },
]

export { ScoutRogueCarouselImages }



const ScoutBobberTwentyCarouselImages = [
    {
        id: 1,
        image: twenty1
    },
    {
        id: 2,
        image: twenty2
    },
    {
        id: 3,
        image: twenty3
    },
    {
        id: 4,
        image: twenty4
    },
    {
        id: 5,
        image: twenty5
    },
    {
        id: 6,
        image: twenty6
    },
]

export { ScoutBobberTwentyCarouselImages }