import Header from '../../../../Main Components/Header';
import BaggerBackground from './Bagger Family Images/bagger-background.webp';
import HeroHeading from "../../components/HeroHeading";
import Overview from "../../components/Overview";
import BaggerOverviewDetails from './BaggerOverviewDetails';
import MeetTheFamily from "../../components/MeetTheFamily";
import BaggerMTFDetails from './BaggerMTFDetails';
import BaggerHistoryLogo from './Bagger Family Images/bagger-history-logo.webp';
import BaggerHistoryDouble from './Bagger Family Images/bagger-history-double.webp';
import BaggerHistoryBottom from './Bagger Family Images/bagger-history-bottom.webp';
import './baggerstyle.css';
import { useEffect, useState } from 'react';
import bloglist from "../../../../Home Page/constants/bloglist";
import Parallax from "../../../../Main Components/Parallax";
import Footer from "../../../../Main Components/Footer";

const BaggerFamily = () => {

    const heroTitle = `BAGGER`;
    const heroDescription = `Овие удобни мотоцикли се подготвени да тргнат на отворен пат - преполни со модерна технологија и удобности за возење низ градот или подолги патувања.`;

    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
       }, [])


    return (
        <>
          <Header/>
          <div className="family_background">
                <img src={BaggerBackground} alt=""></img>
        </div>
        <HeroHeading heroTitle={heroTitle} heroDescription={heroDescription}/>
        <Overview details={BaggerOverviewDetails}/>
        <MeetTheFamily mtfdetails={BaggerMTFDetails}/>
        <div className="bagger_history">
                <div className="bagger_history_container">
                    <div className="bagger_history_logo">
                        <img src={BaggerHistoryLogo} alt=""></img>
                    </div>
                    <div className="bagger_history_text">
                        <h3>Роден од неопходност, трансформиран во наследство</h3>
                        <p>Родени во почетокот на 20-от век, овие мотоцикли ги надминаа епохите и станаа вистински икони на патот. Од нивното воведување, Indian Motorcycle ја олицетворува комбинацијата на стил и супстанца што ја дефинира класата.</p>
                    </div>
                    <div className="bagger_history_images">
                        <img src={BaggerHistoryDouble} alt=""/>
                    </div>
                    <div className="bagger_history_bottom">
                        <div className="bagger_history_bottom_inner">
                            <h3>Форма и функција</h3>
                            <p>Обично опремен со тврди торби и шофершајбна, ова е мотоцикл познат по својата разновидност. Лесни за персонализирање за ваша сопствена цел, дизајнирани да се справат со се што наидува на патот.</p>
                        </div>
                        <img src={BaggerHistoryBottom} alt=""></img>
                    </div>
                </div>
        </div>
        <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default BaggerFamily;