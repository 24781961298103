import FTRRCarbon1 from '../FTR Motorcycles Images/ftr-r-carbon-item-1.webp';
import FTRRCarbon2 from '../FTR Motorcycles Images/ftr-r-carbon-item-2.webp';
import FTRRCarbon3 from '../FTR Motorcycles Images/ftr-r-carbon-item-3.webp';
import FTRRCarbon4 from '../FTR Motorcycles Images/ftr-r-carbon-item-4.webp';
import FTRRCarbon5 from '../FTR Motorcycles Images/ftr-r-carbon-item-5.webp';
import FTRRCarbon6 from '../FTR Motorcycles Images/ftr-r-carbon-item-6.webp';
import FTRRCarbon7 from '../FTR Motorcycles Images/ftr-r-carbon-item-7.webp';
import FTRRCarbon8 from '../FTR Motorcycles Images/ftr-r-carbon-item-8.webp';
import FTRRCarbon9 from '../FTR Motorcycles Images/ftr-r-carbon-item-9.webp';


import FTRRally1 from '../FTR Motorcycles Images/ftrrally-item-1.webp';
import FTRRally2 from '../FTR Motorcycles Images/ftrrally-item-2.webp';
import FTRRally3 from '../FTR Motorcycles Images/ftrrally-item-3.webp';
import FTRRally4 from '../FTR Motorcycles Images/ftrrally-item-4.webp';
import FTRRally5 from '../FTR Motorcycles Images/ftrrally-item-5.webp';
import FTRRally6 from '../FTR Motorcycles Images/ftrrally-item-6.webp';
import FTRRally7 from '../FTR Motorcycles Images/ftrrally-item-7.webp';
import FTRRally8 from '../FTR Motorcycles Images/ftrrally-item-8.webp';


import FTRSport1 from '../FTR Motorcycles Images/ftrsport-item-1.webp';
import FTRSport2 from '../FTR Motorcycles Images/ftrsport-item-2.webp';
import FTRSport3 from '../FTR Motorcycles Images/ftrsport-item-3.webp';
import FTRSport4 from '../FTR Motorcycles Images/ftrsport-item-4.webp';
import FTRSport5 from '../FTR Motorcycles Images/ftrsport-item-5.webp';
import FTRSport6 from '../FTR Motorcycles Images/ftrsport-item-6.webp';
import FTRSport7 from '../FTR Motorcycles Images/ftrsport-item-7.webp';
import FTRSport8 from '../FTR Motorcycles Images/ftrsport-item-8.webp';
import FTRSport9 from '../FTR Motorcycles Images/ftrsport-item-9.webp';

import FTR1 from '../FTR Motorcycles Images/ftr-item-1.webp';
import FTR2 from '../FTR Motorcycles Images/ftr-item-2.webp';
import FTR3 from '../FTR Motorcycles Images/ftr-item-3.webp';
import FTR4 from '../FTR Motorcycles Images/ftr-item-4.webp';
import FTR5 from '../FTR Motorcycles Images/ftr-item-5.webp';
import FTR6 from '../FTR Motorcycles Images/ftr-item-6.webp';
import FTR7 from '../FTR Motorcycles Images/ftr-item-7.webp';
import FTR8 from '../FTR Motorcycles Images/ftr-item-8.webp';
import FTR9 from '../FTR Motorcycles Images/ftr-item-9.webp';

import FTRXRCarbon1 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-1.jpg';
import FTRXRCarbon2 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-2.jpg';
import FTRXRCarbon3 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-3.jpg';
import FTRXRCarbon4 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-4.jpg';
import FTRXRCarbon5 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-5.jpg';
import FTRXRCarbon6 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-6.jpg';
import FTRXRCarbon7 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-7.jpg';
import FTRXRCarbon8 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-8.jpg';
import FTRXRCarbon9 from '../FTR Motorcycles Images/ftr-x-r-carbon-item-9.jpg';

import FTRSuperHooligan1 from '../FTR Motorcycles Images/ftr-super-hooligan-item-1.jpg';
import FTRSuperHooligan2 from '../FTR Motorcycles Images/ftr-super-hooligan-item-2.jpg';
import FTRSuperHooligan3 from '../FTR Motorcycles Images/ftr-super-hooligan-item-3.jpg';
import FTRSuperHooligan4 from '../FTR Motorcycles Images/ftr-super-hooligan-item-4.jpg';
import FTRSuperHooligan5 from '../FTR Motorcycles Images/ftr-super-hooligan-item-5.jpg';
import FTRSuperHooligan6 from '../FTR Motorcycles Images/ftr-super-hooligan-item-6.jpg';


const FTRRCarbonFeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: `FTR R Carbon може да се пофали со шампионско педигре и врвен уличен стил.`,
    members: [
      {
        id: 1.1,
        title: `АМЕРИКАНСКИ ОРИГИНАЛ`,
        description: `Мускулест и софистициран, FTR R Carbon е суштината на тоа како треба да изгледа еден мотоцикл. Целосно инспириран од нашите корени од рамна патека, самите работи што го прават стилски го прават исто така неверојатно функционален.`,
        image: FTRRCarbon1,
      },
      {
        id: 1.2,
        title: `ЦЕЛОСНО ПРИЛАГОДЛИВА ÖHLINS® СУСПЕНЗИЈА`,
        description: `Целосно прилагодливата предна и задна суспензија Öhlins® ви овозможува да го вклучите вашето возење со точното чувство што го сакате - одржувајќи ве на градските улици без да го жртвувате телепатското лесно ракување.`,
        image: FTRRCarbon2,
      },
      {
        id: 1.3,
        title: `4" TOUCHSCREEN ОД RIDE COMMAND`,
        description: `Деталите на дизајнот ја исполнуваат модерната функција во водечкиот во класата и првиот индустриски екран на допир од 4 инчи, напојуван со RIDE COMMAND, кој се одликува со интуитивна навигација за пребарување со поврзување преку Bluetooth.`,
        image: FTRRCarbon3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: `ПЕРФОМАНСИ ДОМИНАНТНИ НА УЛИЦА`,
    mainDescription: ``,
    members: [
      {
        id: 2.1,
        title: `ПОДГОТВЕН ЗА ВОЗБУДУВАЊА`,
        description: `Карактерот на американскиот V-twin со високи вртежи и течно ладење е уникатен и во срцето на она што го прави FTR извонреден. 123 КС и вртежен момент од 118 Nm, и без напор оди до нејзината црвена линија од 6000 вртежи во минута.`,
        image: FTRRCarbon4,
      },
      {
        id: 2.2,
        title: `САМОДОВЕРБА`,
        description: `Новиот FTR е целосно оптимизиран за врвни улични перформанси, каде што доминираат вистинските возачи. Лесните тркала од 17 инчи напред и назад лесно се превртуваат и носат супер леплива гума Metzeler Sportec што ве лепи на патот.`,
        image: FTRRCarbon5,
      },
      {
        id: 2.3,
        title: `ДЕТАЛИ`,
        description: `Неспоредливото вклопување и завршница доаѓаат благодарение на врвното зашиено седиште и компонентите од јаглеродни влакна насекаде. Ќе добивате неспоредлива удобност - и емоции - секој пат кога ќе седнете на него.`,
        image: FTRRCarbon6,
      },
    ],
  },
  {
    id: 3,
    mainTitle: `НАПРАВЕН ЗА МОДЕРЕН ПАТ`,
    mainDescription: `Наполнет со сета технологија за подобрување на возењето што ја сакате и ви треба.`,
    members: [
      {
        id: 3.1,
        title: `НАЈДОБРАТА ТЕХНИКА ЗА ПОДОБРУВАЊЕ НА ВОЗЕЊЕТО`,
        description: `Три режими на возење (Sport, Standard, Rain) ги максимизираат вашите возбудувања, а Lean Angle Sensitive Stability Control и ABS обезбедуваат сигурно, предвидливо управување во сите услови. Traction control, претконтрола на свиоци и ублажување на тркалата се исто така тука. Ако вашиот внатрешен "демон" сака да ги направи работите поинтересни, Traction Control и Wheelie Mitigation лесно се исклучуваат.`,
        image: FTRRCarbon7,
      },
      {
        id: 3.2,
        title: `МОЌНОСТ НА ЗАПИРАЊЕ`,
        description: `Целосно прилагодливата предна вилушка наопаку од 43 mm и единечниот, изложен заден удар го прават FTR издвоен во толпата. Масивните моноблок клешти со 4 клипни Brembo зграпчуваат двојни ротори од 320 mm и се специјално наместени за неверојатна контрола и моќ на сопирање.`,
        image: FTRRCarbon8,
      },
      {
        id: 3.3,
        title: `НАПРАВЕТЕ СОПСТВЕН FTR CARBON`,
        description: `FTR е Swiss Army knife на светот на мото - разноврсна и неопходна алатка за задоволување на сите ваши потреби на две тркала. И со оригиналните фабрички додатоци, можете да го подигнете FTR на уште едно ниво. Без разлика дали барате дополнителни перформанси, врвен стил, зголемена удобност или посветена функција - персонализирањето е лесно и ви дава можност да го трансформирате FTR онака како што сакате.`,
        image: FTRRCarbon9,
      },
    ],
  },
];

export { FTRRCarbonFeaturedItems };




const FTRRallyFeaturedItems = [
  {
    id: 1,
    mainTitle: `РЕТРО СТИЛОТ СЕ СРЕТНУВА СО МОДЕРНИОТ ПАТ`,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `АМЕРИКАНСКИ ОРИГИНАЛ`,
        description: `Мускулесто и софистицирано, релито FTR е целосно инспирирано од нашите корени на рамни патеки, а самите нешта што го прават стилски го прават и неверојатно функционално. Гумите Knobby, Aviator седиште, Rally шофершајбната и мат финишот докажуваат дека модерните перформанси не се контрадикторни со неверојатниот стил.`,
        image: FTRRally1,
      },
      {
        id: 1.2,
        title: `V-TWIN КАРАКТЕР`,
        description: `Со 123 КС и 118 Nm вртежен момент, она што бројките го кажуваат е едноставно: при помали вртежи низ градот, V-twin од 60 степени од 1203cc може да биде целосно цивилизиран. Но, зграпчете гас и брзо и без напор оди до својата црвена линија од 6000 вртежи во минута.`,
        image: FTRRally2,
      },
      {
        id: 1.3,
        title: `4" TOUCHSCREEN ОД RIDE COMMAND`,
        description: `Деталите на дизајнот ја исполнуваат модерната функција во водечкиот во класата и првиот индустриски екран на допир од 4 инчи, напојуван со RIDE COMMAND, кој се одликува со интуитивна навигација за пребарување со поврзување преку Bluetooth.`,
        image: FTRRally3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: `ДИЗАЈНИРАН ЗА СТИЛ И ФУНКЦИЈА`,
    mainDescription: `FTR Rally потврдува дека може добро да изгледате и да се чувствувате добро.`,
    members: [
      {
        id: 2.1,
        title: `ПОДГОТВЕН`,
        description: `Удобен ергос и ретро стил за каде и да ве однесе патот. Рачките на ProTaper® со 2-инчи повисока положба на рацете даваат посигурна контрола при тешки услови.`,
        image: FTRRally4,
      },
      {
        id: 2.2,
        title: `ГУМИ KNOBBY`,
        description: `Пневматиците Pirelli® Scorpion Rally STR монтирани на лиени црни тркала даваат непогрешлива естетика на гребење, а исто така додаваат способност за возење по патот.`,
        image: FTRRally5,
      },
      {
        id: 3.3,
        title: `НАПРАВЕТЕ СОПСТВЕН FTR RALLY`,
        description: `FTR е Swiss Army knife на светот на мото - разноврсна и неопходна алатка за задоволување на сите ваши потреби на две тркала. И со оригиналните фабрички додатоци, можете да го подигнете FTR на уште едно ниво. Без разлика дали барате дополнителни перформанси, врвен стил, зголемена удобност или посветена функција - персонализирањето е лесно и ви дава можност да го трансформирате FTR онака како што сакате.`,
        image: FTRRally6,
      },
    ],
  },
  {
    id: 3,
    mainTitle: `ПЕРФОМАНСИ ЗА СЕКОЈ ДЕН`,
    mainDescription: `Ретрото не мора да значи застарено. Повеќе од она што ви треба; помалку од она што не ви треба.`,
    members: [
      {
        id: 3.1,
        title: `МОЌНОСТ НА ЗАПИРАЊЕ`,
        description: `Целосно прилагодливата предна вилушка наопаку од 43 mm и единечниот, изложен заден удар го прават FTR издвоен во толпата. Масивните моноблок клешти со 4 клипови на Brembo зграпчуваат двојни ротори од 320 mm и се специјално наместени за неверојатна контрола и моќ на сопирање. Стопите се опционални и само два прста се оддалечени ако сте толку наклонети.`,
        image: FTRRally7,
      },
      {
        id: 3.2,
        title: `ЦЕЛОСНО ПРИЛАГОДЛИВА СУСПЕНЗИЈА`,
        description: `Целосно прилагодливиот заден амортизер ви овозможува да го вклучите вашето возење со точното чувство што го сакате - одржувајќи на градските улици или позади патишта без да го жртвувате телепатското лесно ракување.`,
        image: FTRRally8,
      },
      {
        id: 3.3,
        title: ``,
        description: ``,
        image: ``,
      },
    ],
  },
];

export { FTRRallyFeaturedItems };



const FTRSportFeaturedItems = [
  {
    id: 1,
    mainTitle: `FTR SPORT: ПОВИСОК СТАНДАРД`,
    mainDescription: `FTR Sport е дизајниран на спојот на способноста и автентичноста.`,
    members: [
      {
        id: 1.1,
        title: `АМЕРИКАНСКИ ОРИГИНАЛ`,
        description: `Мускулесто и софистицирано, релито FTR е целосно инспирирано од нашите корени на рамни патеки, а самите нешта што го прават стилски го прават и неверојатно функционално. Гумите Knobby, Aviator седиште, Rally шофершајбната и мат финишот докажуваат дека модерните перформанси не се контрадикторни со неверојатниот стил.`,
        image: FTRSport1,
      },
      {
        id: 1.2,
        title: `ПОДГОТВЕН ЗА ВОЗБУДУВАЊА`,
        description: `Карактерот на американскиот V-twin со високи вртежи и течно ладење е уникатен и во срцето на она што го прави FTR извонреден. 123 КС и 118 Nm вртежен момент обезбедуваат крива на вртежниот момент што е исто толку дебела колку што е рамна.`,
        image: FTRSport2,
      },
      {
        id: 1.3,
        title: `4" TOUCHSCREEN ОД RIDE COMMAND`,
        description: `Деталите на дизајнот ја исполнуваат модерната функција во водечкиот во класата и првиот индустриски екран на допир од 4 инчи, напојуван со RIDE COMMAND, кој се одликува со интуитивна навигација за пребарување со поврзување преку Bluetooth.`,
        image: FTRSport3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: `ВОЗЕТЕ НА ПОВИСОКО НИВО`,
    mainDescription: `Карактеристики дизајнирани да го подигнат искуството во возењето.`,
    members: [
      {
        id: 2.1,
        title: `ДОВЕРБА`,
        description: `новиот FTR е целосно оптимизиран за врвни улични перформанси, каде што доминираат вистинските возачи. Лесните тркала од 17 инчи напред и назад лесно се превртуваат и носат супер леплива гума Metzeler Sportec што ве лепи на патот.`,
        image: FTRSport4,
      },
      {
        id: 2.2,
        title: `МОЌНОСТ НА ЗАПИРАЊЕ`,
        description: `Целосно прилагодливата предна вилушка наопаку од 43 mm и единечниот, изложен заден удар го прават FTR издвоен во толпата. Масивните моноблок клешти со 4 клипови на Brembo зграпчуваат двојни ротори од 320 mm и се специјално наместени за неверојатна контрола и моќ на сопирање. Стопите се опционални и само два прста се оддалечени ако сте толку наклонети.`,
        image: FTRSport5,
      },
      {
        id: 3.3,
        title: `НАПРАВЕТЕ СОПСТВЕН FTR SPORT`,
        description: `FTR е Swiss Army knife на светот на мото - разноврсна и неопходна алатка за задоволување на сите ваши потреби на две тркала. И со оригиналните фабрички додатоци, можете да го подигнете FTR на уште едно ниво. Без разлика дали барате дополнителни перформанси, врвен стил, зголемена удобност или посветена функција - персонализирањето е лесно и ви дава можност да го трансформирате FTR онака како што сакате.`,
        image: FTRSport6,
      },
    ],
  },
  {
    id: 3,
    mainTitle: `НАПРАВЕН ЗА МОДЕРЕН ПАТ`,
    mainDescription: `Наполнет со сета технологија за подобрување на возењето што ја сакате и ви треба.`,
    members: [
      {
        id: 3.1,
        title: `НАЈДОБРАТА ТЕХНОЛОГИЈА ЗА ПОДОБРУВАЊЕ НА ВОЗЕЊЕТО`,
        description: `Три режими на возење (Sport, Standard, Rain) ги максимизираат вашите возбудувања, а Lean Angle Sensitive Stability Control и ABS обезбедуваат сигурно, предвидливо управување во сите услови.`,
        image: FTRSport7,
      },
      {
        id: 3.2,
        title: `ОСТАНЕТЕ ПРИЗЕМЈЕНИ`,
        description: `Traction control, cornering pre-control и wheelie mitigation се исто така тука. Ако вашиот внатрешен демон сака да ги направи работите поинтересни, Traction Control и Wheelie Mitigation лесно се исклучуваат.`,
        image: FTRSport8,
      },
      {
        id: 3.3,
        title: `СУСПЕНЗИЈА`,
        description: `Целосно прилагодливата предна суспензија и задните амортизери ви овозможуваат да го вклучите вашето возење со точното чувство што го сакате - држејќи на градските улици без да го жртвувате телепатското лесно ракување.`,
        image: FTRSport9,
      },
    ],
  },
];

export { FTRSportFeaturedItems };




const FTRFeaturedItems = [
  {
    id: 1,
    mainTitle: `ФТР: СОЗДАДЕН ЗА УЛИЦА`,
    mainDescription: `Преземете го иконскиот стил, контролата и уличните перформанси на сосема ново ниво`,
    members: [
      {
        id: 1.1,
        title: `АМЕРИКАНСКИ ОРИГИНАЛ`,
        description: `FTR е суштината на тоа како треба да изгледа еден мотоцикл - брутален V-twin мотор на јасен поглед, цврсто опкружен со рамка со елегантен изглед.`,
        image: FTR1,
      },
      {
        id: 1.2,
        title: `ПОДГОТВЕН ЗА ВОЗБУДУВАЊА`,
        description: `Карактерот на американскиот V-twin со високи вртежи и течно ладење е уникатен и во срцето на она што го прави FTR извонреден. Со 123 КС и 118 Nm вртежен момент, 1203cc може да биде целосно цивилизиран низ градот или зграпчете гас и неговата целосна "хулиганска личност" не може да се сокрие.`,
        image: FTR2,
      },
      {
        id: 1.3,
        title: `САМОДОВЕРБА`,
        description: `Новиот FTR е целосно оптимизиран за врвни улични перформанси, каде што доминираат вистинските возачи. Лесните тркала од 17 инчи напред и назад лесно се превртуваат и носат супер леплива гума Metzeler Sportec што ве лепи на патот.`,
        image: FTR3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 2.1,
        title: `СУСПЕНЗИЈА`,
        description: `Целосно прилагодливата предна суспензија и задните амортизери ви овозможуваат да го вклучите вашето возење со точното чувство што го сакате - држејќи на градските улици без да го жртвувате телепатското лесно ракување.`,
        image: FTR4,
      },
      {
        id: 2.2,
        title: `МОЌНОСТ НА ЗАПИРАЊЕ`,
        description: `Целосно прилагодливата предна вилушка наопаку од 43 mm и единечниот, изложен заден удар го прават FTR издвоен во толпата. Масивните моноблок клешти со 4 клипови на Brembo зграпчуваат двојни ротори од 320 mm и се специјално наместени за неверојатна контрола и моќ на сопирање. Стопите се опционални и само два прста се оддалечени ако сте толку наклонети.`,
        image: FTR5,
      },
      {
        id: 2.3,
        title: `НАПРАВЕТЕ СОПСТВЕН FTR`,
        description: `FTR е Swiss Army knife на светот на мото - разноврсна и неопходна алатка за задоволување на сите ваши потреби на две тркала. И со оригиналните фабрички додатоци, можете да го подигнете FTR на уште едно ниво. Без разлика дали барате дополнителни перформанси, врвен стил, зголемена удобност или посветена функција - персонализирањето е лесно и ви дава можност да го трансформирате FTR онака како што сакате.`,
        image: FTR6,
      },
    ],
  },
  {
    id: 3,
    mainTitle: `ПРОГРЕСИВНА ТЕХНОЛОГИЈА`,
    mainDescription: `Дизајниран само со технологијата што ви е потребна за возбудливо и сигурно возење.`,
    members: [
      {
        id: 3.1,
        title: `ВОЗЕТЕ КОМФОРНО`,
        description: `Возете подолго во удобност со темпоматот бидејќи штом ќе излезете, сигурно ќе одите на долги дестинации.`,
        image: FTR7,
      },
      {
        id: 3.2,
        title: `ОСВЕТЛУВАЊЕ`,
        description: `Осветлете ја ноќта со стил со целосно LED осветлување.`,
        image: FTR8,
      },
      {
        id: 3.3,
        title: `УДОБНОСТ СО СЕКОЈА БРЗИНА`,
        description: `Благодарение на ефикасното управување со топлината и деактивирањето на задниот цилиндар што ја испраќа топлината од задната гума - а не во вас.`,
        image: FTR9,
      },
    ],
  },
];

export { FTRFeaturedItems };

const FTRXRCarbonFeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `НЕПОГРЕШЛИВ СТИЛ`,
        description: `Впечатлив финиш од Candy Blue Carbon Fiber со бела рамка на решетката и црвени акценти..`,
        image: FTRXRCarbon1,
      },
      {
        id: 1.2,
        title: `ДОМИНАНТНА ИЗВЕДБА`,
        description: `FTR x 100% R Carbon може да се пофали со целосно модерен американски V-Twin, цврсто спакуван во совршено избалансирана улична шасија што дава пргав, сигурно управување и спортско возење како ништо друго.`,
        image: FTRXRCarbon2,
      },
      {
        id: 1.3,
        title: `ПОДГОТВЕН ЗА ВОЗБУДУВАЊА`,
        description: `Карактерот на американскиот V-twin со високи вртежи и течно ладење е уникатен и во срцето на она што го прави FTR извонреден. Вртежниот момент од 120 nm обезбедува крива на вртежен момент што е исто толку дебела колку што е рамна и без напор се движи до нејзината црвена линија од 9000 вртежи во минута.`,
        image: FTRXRCarbon3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 2.1,
        title: `4" TOUCHSCREEN ОД RIDE COMMAND`,
        description: `Деталите на дизајнот ја исполнуваат модерната функција во водечкиот во класата и првиот индустриски екран на допир од 4 инчи, напојуван со RIDE COMMAND, кој се одликува со интуитивна навигација за пребарување со поврзување преку Bluetooth.`,
        image: FTRXRCarbon4,
      },
      {
        id: 2.2,
        title: `НАЈДОБРА ТЕХНОЛОГИЈА ПРИ ВОЗЕЊЕ`,
        description: `Трите режими на возење ги максимизираат вашите возбудувања, а Контролата на стабилност и ABS чувствителна на тенок агол обезбедуваат сигурно, предвидливо управување во сите услови. Контрола на тракција, претконтрола на свиоци и ублажување на тркалата се исто така тука. Ако вашиот внатрешен демон сака да ги направи работите поинтересни, Traction Control и Wheelie Mitigation лесно се исклучуваат.`,
        image: FTRXRCarbon5,
      },
      {
        id: 2.3,
        title: `САМОДОВЕРБА`,
        description: `Оригинално дизајниран по нашиот доминантен, шампионски победник FTR 750 тркач, новиот FTR е целосно оптимизиран за врвни улични перформанси, каде што доминираат вистинските возачи. Лесните тркала од 17 инчи напред и назад лесно се превртуваат и носат супер леплива гума Metzeler Sportec што ве лепи на патот. Доверба инспиративна позиција на возење`,
        image: FTRXRCarbon6,
      },
    ],
  },
  {
    id: 3,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 3.1,
        title: `ПРИЛАГОДЛИВА СУСПЕНЗИЈА`,
        description: `Целосно прилагодливата предна и задна суспензија Blacked Out Öhlins® ви овозможува да го вклучите вашето возење со точното чувство што го сакате - што ќе ве држи насадени на градските улици или задните патишта без да го жртвувате телепатското лесно ракување.`,
        image: FTRXRCarbon7,
      },
      {
        id: 3.2,
        title: `ЗАПИРАЧКА МОЌ`,
        description: `Целосно прилагодливата предна вилушка од 43 мм и единечниот, изложен заден удар го прават FTR издвоен во толпата. Масивните моноблок клешти со 4 клипни Brembo зграпчуваат двојни ротори од 320 mm и се специјално наместени за неверојатна контрола и моќ на сопирање.`,
        image: FTRXRCarbon8,
      },
      {
        id: 3.3,
        title: `ДЕТАЛИ`,
        description: `Неспоредливото вклопување и завршница доаѓаат благодарение на врвното зашиено седиште и компонентите од јаглеродни влакна насекаде. Ќе добивате неспоредлива удобност - и емоции - секој пат кога ќе седнете на него.`,
        image: FTRXRCarbon9,
      },
    ],
  },
];

export { FTRXRCarbonFeaturedItems };

const FTRSuperHooliganFeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `СОРАБОТКА`,
        description: `Инспирирани од денешниот доминантен индиски мото-фабрички тим за трки, го создадовме ограниченото издание FTR x RSD Super Hooligan во партнерство со Роланд Сендс за да биде врвниот хулигански мотоцикл што ќе доминира на улица.`,
        image: FTRSuperHooligan1,
      },
      {
        id: 1.2,
        title: `ДОКАЖАНИ ХУЛИГАНСКИ ПЕРФОМАНСИ`,
        description: `За целосно да го живеете хулиганскиот начин на живот, потребен ви е мотор кој ве моли да ја запалите таа свеќа. А токму тој тип на мотор е во срцето на FTR x RSD Super Hooligan: моќен V-Twin со течно ладење со 120 Nm вртежен момент со 123 коњски сили.`,
        image: FTRSuperHooligan2,
      },
      {
        id: 1.3,
        title: `ПРИЛАГОДЛИВА СУСПЕНЗИЈА`,
        description: `Целосно прилагодливата предна и задна суспензија Öhlins® ви овозможува да го вклучите вашето возење со точното чувство што го сакате - одржувајќи ве насадени на градските улици или задните патишта без да го жртвувате управувањето.`,
        image: FTRSuperHooligan3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 2.1,
        title: `ДЕТАЛИ`,
        description: `Akrapovič пригушувачот и топлинскиот штит додаваат стил и го подобруваат звукот, додека деловите Gilles Tooling, вклучително и прилагодливите задни сетови, капачето за масло, и тежините на крајот на шипката, додаваат на врвната завршница на мотоциклот - обезбедувајќи автентичен стил на возење со RSD Hooligan за возачот.`,
        image: FTRSuperHooligan4,
      },
      {
        id: 2.2,
        title: `НАЈДОБРА ТЕХНОЛОГИЈА ПРИ ВОЗЕЊЕ`,
        description: `Дизајнерските детали одговараат на модерната функција во водечкиот во класата и во индустријата, првиот 4-инчен екран на допир, напојуван со RIDE COMMAND, кој се одликува со интуитивна навигација за пребарување со поврзување со Bluetooth®, што може да се приспособи на вашите желби со допир на прст.`,
        image: FTRSuperHooligan5,
      },
      {
        id: 2.3,
        title: `ЗАПИРАЧКА МОЌ`,
        description: `Целосно прилагодливата предна вилушка од 43 мм и единечниот, изложен заден удар го прават FTR издвоен во толпата. Масивните моноблок клешти со 4 клипни Brembo зграпчуваат двојни ротори од 320 mm и се специјално наместени за неверојатна контрола и моќ на сопирање.`,
        image: FTRSuperHooligan6,
      },
    ],
  }
];

export { FTRSuperHooliganFeaturedItems };