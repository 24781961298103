const RomaniaDealerInformation = [
    {
        id: `1.`,
        title: `ASP GROUP EAST`,
        location: `Strada Ciobanului 129, Mogosoaia 077135 Ilfov`,
        phone: `40728 203 814`,
        website: `https://www.indianmotorcycle.ro`
    },
    {
        id: `2.`,
        title: `ASP GROUP EAST TIMISOARA`,
        location: `Calea Sagului 235 307221 Timisoara`,
        phone: `40722 752 742`,
        website: `https://www.indianmotorcycle.ro`
    },
    {
        id: `3.`,
        title: `ASP GROUP EAST CLUJ`,
        location: `Strada Clujului 2A, Sannicoara, comuna Apahida 407042 Cluj-Napoca`,
        phone: `40728 960 870`,
        website: `https://www.indianmotorcycle.ro`
    },
    {
        id: `4.`,
        title: `MOTO24`,
        location: `Bulevardul Grivitei 1H 500177 Brasov`,
        phone: `40745 747 575`,
        website: ''
    },
    {
        id: `5.`,
        title: `MADRAS MOTOPARTS`,
        location: `Strada Bratuleni, Parc Industrial Miroslava 707307 Iasi`,
        phone: `40748 137 749`,
        website: ``
    },
    {
        id: `6.`,
        title: `EST BIKE ADVENTURE`,
        location: `Strada Medeea 1A 900154 Constanta`,
        phone: `40752 312 097`,
        website: ``
    },
];

const BulgariaDealerInformation = [
    {
        id: '1.',
        title: `INDIAN MOTORCYCLE BULGARIA`,
        location: `Vodenicharski Put 4 1387 Sofia`,
        phone: `+359 876 333 263`,
        website: `https://www.indianmotorcycle.bg`,
    },
];

const MoldovaDealerInformation = [
    {
        id: `1.`,
        title: `MOTOPARTS`,
        location: `Calea Mosilor 4 2024 Chisinau`,
        phone: `373 690 00114`,
    }
];

const NorthMacedoniaDealerInformation = [
    {
        id: `1.`,
        title: `LYNX ADVENTURES`,
        location: `Ul: Skupi 67 1000 Skopje`,
        phone: `+389 712 999 48`,
        contact: `info@indianmotorcycles.mk`,
        website: `https://www.indianmotorcycle.mk`
    },
    {
        id: `2.`,
        title: `ELIPSO MOTO`,
        location: `Ul: Ilindeska Br. 1 Tetovo 1220`,
        phone: `+389 722 423 33`,
        contact: `elipsomoto@gmail.com`,
        website: `https://www.indianmotorcycle.mk`
    },
];

export { NorthMacedoniaDealerInformation };
export { BulgariaDealerInformation };
export { RomaniaDealerInformation };
export { MoldovaDealerInformation };