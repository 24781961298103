const Widget = ({headingText, innerText}) => {
    return (
        <div className="widget_container">
            <div className="widget_wrapper">
                <div className="widget_inner">
                    <h1 className="widget_heading">{headingText} <span className="widget_hero">{innerText}</span></h1>
                </div>
            </div>
        </div>
    )
}

export default Widget;