import { useEffect, useState } from "react";
import Header from "../../Main Components/Header";
import '../Find A Dealer/findadealerstyle.css';
import Footer from "../../Main Components/Footer";
import Parallax from "../../Main Components/Parallax";
import bloglist from "../../Home Page/constants/bloglist";
import ListNorthMacedonia from "./Dealer Countries/ListNorthMacedonia";

const FindADealer = () => {
    
    const [sortedBlogList, setSortedBlogList] = useState();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const sortedArray = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedBlogList(sortedArray);
    }, [])

    return (
        <>
            <Header/>
                <div className="dealers_list">
                <ul>
                <li className='currentTab'>Македонија</li>
                    </ul>
                </div>
                <ListNorthMacedonia/>
                <div className="home_blog_container">
            {
                sortedBlogList&&sortedBlogList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
                <Parallax/>
                <Footer/>
        </>
        
    )
}

export default FindADealer;