const Box = ({ list }) => {
    return (
        <>
            <div className="history_box_layout">
                {
                    list.map((item) => (
                        <div className="history_box_inner">
                            <img alt="" src={item.image}></img>
                            <h3>{item.title}</h3>
                            <p>{item.date}</p>
                            <p>{item.text}</p>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default Box;