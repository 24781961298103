import scoutclassic1 from '../Scout 1250cc Motorcycles Images/scout-classic-item-1.jpg';
import scoutclassic2 from '../Scout 1250cc Motorcycles Images/scout-classic-item-2.jpg';
import scoutclassic3 from '../Scout 1250cc Motorcycles Images/scout-classic-item-3.jpg';
import scoutclassic5 from '../Scout 1250cc Motorcycles Images/scout-classic-item-5.jpg';
import scoutclassic6 from '../Scout 1250cc Motorcycles Images/scout-classic-item-6.jpg';
import scoutclassic7 from '../Scout 1250cc Motorcycles Images/scout-classic-item-7.jpg';

import scoutbobber1 from '../Scout 1250cc Motorcycles Images/scout-bobber-item-1.jpg';
import scoutbobber2 from '../Scout 1250cc Motorcycles Images/scout-bobber-item-2.jpg';
import scoutbobber3 from '../Scout 1250cc Motorcycles Images/scout-bobber-item-3.jpg';

import sportscout1 from '../Scout 1250cc Motorcycles Images/sport-scout-item-1.jpg';
import sportscout2 from '../Scout 1250cc Motorcycles Images/sport-scout-item-2.jpg';
import sportscout3 from '../Scout 1250cc Motorcycles Images/sport-scout-item-3.jpg';

import superscout1 from '../Scout 1250cc Motorcycles Images/super-scout-item-1.jpg';
import superscout2 from '../Scout 1250cc Motorcycles Images/super-scout-item-2.jpg';
import superscout3 from '../Scout 1250cc Motorcycles Images/super-scout-item-3.jpg';
import superscout4 from '../Scout 1250cc Motorcycles Images/super-scout-item-4.jpg';
import superscout5 from '../Scout 1250cc Motorcycles Images/super-scout-item-5.jpg';

import scout1011 from '../Scout 1250cc Motorcycles Images/scout-101-item-1.jpg';
import scout1012 from '../Scout 1250cc Motorcycles Images/scout-101-item-2.jpg';
import scout1013 from '../Scout 1250cc Motorcycles Images/scout-101-item-3.jpg';
import scout1014 from '../Scout 1250cc Motorcycles Images/scout-101-item-4.jpg';
import scout1015 from '../Scout 1250cc Motorcycles Images/scout-101-item-5.jpg';
import scout1016 from '../Scout 1250cc Motorcycles Images/scout-101-item-6.jpg';


const ScoutClassicFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `STANDARD`,
          description: `Стандардите ви ги дава неопходните работи што ви се потребни за возење на патот, вклучувајќи аналоген мерач, палење со клуч, нов LED фар, нов мерач на нивото на горивото и ABS сега стандардно кај сите модели.`,
          image: scoutclassic1,
        },
        {
          id: 1.2,
          title: `LIMITED`,
          description: `За оние кои бараат поголема контрола врз нивното возење, Limited Trim им дава на возачите сè, од стандардната опрема, но додава контрола на патувањето, контрола на влечење за да обезбеди сигурно, предвидливо управување во сите услови, три режими на возење за максимизирање вашите возбудувања и USB полнач.`,
          image: scoutclassic2,
        },
        {
          id: 1.3,
          title: `LIMITED + TECH`,
          description: `Дизајнерските детали се среќаваат со модерната функција во водечкиот 4-инчен екран на допир во класата, напојуван со RIDE COMMAND, со навигација на одборот, интуитивно пребарување, предупредување за влечење/удирање, состојба на возилото и локатор на возилото. Уште повеќе, RIDE COMMAND има шест екрани за прикажување и два екрани со мерач за избор. Ќе се прашувате како некогаш сте возеле без него. Опремата со LIMITED + TECH го заменува и палењето со клуч за палење без клуч.`,
          image: scoutclassic3,
        },
      ],
    },
    {
        id: 2,
        mainTitle: ``,
        mainDescription: ``,
        members: [
          {
            id: 2.1,
            title: `ОПЦИИ`,
            description: `Изберете кои технолошки надградби се важни за вас и додајте ги на нивото на уредување по ваш избор. Целосно новиот Scout Lineup за 2025 година е дизајниран со архитектура на plug and play што може да се надгради преку фабричките нивоа на опременост или преку додатоци и додатоци. Дизајнот е оптимизиран за додатоци и електрични додатоци што го олеснуваат надградбата на вашиот мотоцикл полесно од кога било.`,
            image: scoutclassic7,
          },
          {
            id: 2.2,
            title: `ДОВЕРБА`,
            description: `Со најниската висина на седиштата во линијата индиски мотоцикли, патување со суспензија од 3 инчи и опуштена ергономија, Scout Classic овозможува лесно, удобно и сигурно возење со секоја брзина, без разлика дали патувате или одите на викенд патување.`,
            image: scoutclassic5,
          },
          {
            id: 2.3,
            title: `ХРОМ`,
            description: `Премиум хром, класични Indian браници и беспрекорна боја сигурно ќе ги свртат главите на секоја раскрсница. Жичените тркала го додаваат безвременскиот, автентичен стил.`,
            image: scoutclassic6,
          },
        ],
      },
]

export { ScoutClassicFeaturedItems };

const ScoutBobber1250FeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `STANDARD`,
          description: `Стандардите ви ги дава неопходните работи што ви се потребни за возење на патот, вклучувајќи аналоген мерач, палење со клуч, нов LED фар, нов мерач на нивото на горивото и ABS сега стандардно кај сите модели.`,
          image: scoutclassic1,
        },
        {
          id: 1.2,
          title: `LIMITED`,
          description: `За оние кои бараат поголема контрола врз нивното возење, Limited Trim им дава на возачите сè, од стандардната опрема, но додава контрола на патувањето, контрола на влечење за да обезбеди сигурно, предвидливо управување во сите услови, три режими на возење за максимизирање вашите возбудувања и USB полнач.`,
          image: scoutclassic2,
        },
        {
          id: 1.3,
          title: `LIMITED + TECH`,
          description: `Дизајнерските детали се среќаваат со модерната функција во водечкиот 4-инчен екран на допир во класата, напојуван со RIDE COMMAND, со навигација на одборот, интуитивно пребарување, предупредување за влечење/удирање, состојба на возилото и локатор на возилото. Уште повеќе, RIDE COMMAND има шест екрани за прикажување и два екрани со мерач за избор. Ќе се прашувате како некогаш сте возеле без него. Опремата со LIMITED + TECH го заменува и палењето со клуч за палење без клуч.`,
          image: scoutclassic3,
        },
      ],
    },
    {
        id: 2,
        mainTitle: ``,
        mainDescription: ``,
        members: [
          {
            id: 2.1,
            title: `ИКОНИЧЕН BOBBER СТАЈЛИНГ`,
            description: `Затемнет од напред до назад со сецкани браници и предни фарови, силно се наведнува во лоша атмосфера.`,
            image: scoutbobber1,
          },
          {
            id: 2.2,
            title: `САМОДОВЕРБА`,
            description: `Седиштето во Bobber стил ја зајакнува минималистичката естетика на Bobber со удобност и цврсто ви дава контрола над мотоциклот. Суспензијата од 2 инчи создава малку помала висина на седиштето, но сепак задржува агресивен, прилагоден изглед.`,
            image: scoutbobber2,
          },
          {
            id: 2.3,
            title: `16" ТРКАЛА`,
            description: `Тркалата од 16 инчи и широките странични гуми го додаваат иконскиот стил на Scout Bobber, без да ги жртвуваат перформансите, за непречено возење и препознатлив стил.`,
            image: scoutbobber3,
          },
        ],
      },
]

export { ScoutBobber1250FeaturedItems };

const SportScoutFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `STANDARD`,
          description: `Стандардите ви ги дава неопходните работи што ви се потребни за возење на патот, вклучувајќи аналоген мерач, палење со клуч, нов LED фар, нов мерач на нивото на горивото и ABS сега стандардно кај сите модели.`,
          image: scoutclassic1,
        },
        {
          id: 1.2,
          title: `LIMITED`,
          description: `За оние кои бараат поголема контрола врз нивното возење, Limited Trim им дава на возачите сè, од стандардната опрема, но додава контрола на патувањето, контрола на влечење за да обезбеди сигурно, предвидливо управување во сите услови, три режими на возење за максимизирање вашите возбудувања и USB полнач.`,
          image: scoutclassic2,
        },
        {
          id: 1.3,
          title: `LIMITED + TECH`,
          description: `Дизајнерските детали се среќаваат со модерната функција во водечкиот 4-инчен екран на допир во класата, напојуван со RIDE COMMAND, со навигација на одборот, интуитивно пребарување, предупредување за влечење/удирање, состојба на возилото и локатор на возилото. Уште повеќе, RIDE COMMAND има шест екрани за прикажување и два екрани со мерач за избор. Ќе се прашувате како некогаш сте возеле без него. Опремата со LIMITED + TECH го заменува и палењето со клуч за палење без клуч.`,
          image: scoutclassic3,
        },
      ],
    },
    {
        id: 2,
        mainTitle: ``,
        mainDescription: ``,
        members: [
          {
            id: 2.1,
            title: `ИКОНA`,
            description: `Четврт феринг му дава на Sport Scout стилски и прилагоден изглед директно од фабриката, а истовремено обезбедува заштита од ветер.`,
            image: sportscout1,
          },
          {
            id: 2.2,
            title: `АГРЕСИВНОСТ`,
            description: `Стилски сјајни црни 6-инчни кревачи во мото стил со машински врвови се стандардни и значајни. Машинските тројки и мото рачките го комплетираат пакетот, како во прилагодениот изглед, така и во ергономијата со бирање што одговара на неговиот заканувачки стил со подобрен досег и контрола за агресивно возење.`,
            image: sportscout2,
          },
          {
            id: 2.3,
            title: `АГИЛНОСТ`,
            description: `Тркалото од 19 инчи напред ја додава агресивната природа на Sport Scout - и во стилот и во агилното управување - додека патувањето на суспензијата од 3" назад дава зголемена удобност и способност за агресивно возење, без да се загрозува стилот. седиштето им обезбедува на возачите поголема поддршка за грбот и удобност за подолго и потешко возење.`,
            image: sportscout3,
          },
        ],
      },
]

export { SportScoutFeaturedItems };

const SuperScoutFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `ВОЗЕТЕ СО ДОВЕРБА`,
          description: `Контрола на влечење, за да се обезбеди сигурно, предвидливо управување во сите услови, три режими на возење (спортски, стандарден или турнеја) за максимизирање на возбудувањата и палење без клуч, за создавање приказни вредни за споделување со притискање на едно копче, сите се стандардни.`,
          image: superscout1,
        },
        {
          id: 1.2,
          title: `ТУРИТЕ НАПРАВЕНИ ЕДНОСТАВНИ`,
          description: `Чантите за седло ви овозможуваат без напор да ги носите основните работи и нудат едноставна лесна употреба со јазичето за брзо повлекување. Ветробранското стакло со брзо ослободување се откачува за неколку секунди за поинаков изглед и да го почувствувате ветрот кога го сакате. Патувањето со суспензија од 3 инчи дава зголемена удобност за возење подолго и подалеку.`,
          image: superscout2,
        },
        {
          id: 1.3,
          title: `СПОДЕЛИ ВОЗЕЊЕТО`,
          description: `Директно од фабриката со патнички пилон, Super Scout е дизајниран да биде подготвен да го сподели возењето со вашиот омилен патник. Патничките штипки се стандардни`,
          image: superscout3,
        },
      ],
    },
    {
        id: 2,
        mainTitle: ``,
        mainDescription: ``,
        members: [
          {
            id: 2.1,
            title: `ДИЗАЈНИРАН ЗА ДЕНЕШНИОТ ВОЗАЧ`,
            description: `Деталите на дизајнот ја исполнуваат модерната функција во водечкиот 4-инчен екран на допир во класата, напојуван со RIDE COMMAND, кој има навигација на одборот, интуитивно пребарување, преклопување на времето и сообраќајот, предупредување за влечење/суди, состојба на возилото и локатор на возилото. Уште повеќе, RIDE COMMAND има шест екрани за прикажување и два екрани со мерач за избор. Ќе се прашувате како некогаш сте возеле без него.`,
            image: superscout4,
          },
          {
            id: 2.2,
            title: `ПЕРСОНАЛИЗАЦИЈА`,
            description: `Без разлика дали барате врвен стил, зголемена удобност или посветена функција - персонализирањето е лесно и ви дава можност да го трансформирате вашиот Indian Scout токму онака како што сакате.`,
            image: superscout5,
          },
        ],
      },
]

export { SuperScoutFeaturedItems };

const Scout101FeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `ВОЗЕТЕ СО ДОВЕРБА`,
        description: `Контрола на влечење, за да се обезбеди сигурно, предвидливо управување во сите услови, три режими на возење (спортски, стандарден или турнеја) за максимизирање на возбудувањата и палење без клуч, за создавање приказни вредни за споделување со притискање на едно копче, сите се стандардни.`,
        image: scout1011,
      },
      {
        id: 1.2,
        title: `ПЕРФОМАНСИ ОД НОВАТА ГЕНЕРАЦИЈА`,
        description: `Со 10% зголемување на зафатнината, целосно редизајнираниот V-Twin со течно ладење од 1250 кубици испорачува 111 коњски сили и од 82 ft-lbs вртежен момент, за избалансирано, но сепак возбудливо возење.`,
        image: scout1012,
      },
      {
        id: 1.3,
        title: `ПРЕМИУМ`,
        description: `Со прилагодливи задни амортизери, превртени прилагодливи предни вилушки и сопирачки со двојни дискови Brembo®, 101 Scout е преполн со врвни компоненти кои даваат изјава за вашата способност да одите брзо и брзо да застанете.`,
        image: scout1013,
      },
    ],
  },
  {
      id: 2,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `ДИЗАЈНИРАН ЗА ДЕНЕШНИОТ ВОЗАЧ`,
          description: `Деталите на дизајнот ја исполнуваат модерната функција во водечкиот 4-инчен екран на допир во класата, напојуван со RIDE COMMAND, кој има навигација на одборот, интуитивно пребарување, преклопување на времето и сообраќајот, предупредување за влечење/суди, состојба на возилото и локатор на возилото. Уште повеќе, RIDE COMMAND има шест екрани за прикажување и два екрани со мерач за избор. Ќе се прашувате како некогаш сте возеле без него.`,
          image: scout1014,
        },
        {
          id: 2.2,
          title: `ИКОНИЧЕН АМЕРИКАНСКИ ДИЗАЈН`,
          description: `Точното внимание на деталите го завршува изгледот со ознака 101 Scout и ексклузивна боја и графика. Indian 101 Scout сигурно ќе привлече погледи и ќе ги сврти главите ако некој може да го фати.`,
          image: scout1015,
        },
        {
          id: 2.3,
          title: `АГРЕСИВНОСТ`,
          description: `Горе, стилски сјајни црни кревачи во мото стил од 5 инчи со машински врвови, обработени тројки и мото рачки ги врзуваат сите заедно, со прилагодена привлечност и намерна ергономија што одговара на неговиот агресивен карактер во клубски стил.`,
          image: scout1016,
        },
      ],
    },
]

export { Scout101FeaturedItems };