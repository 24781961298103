import IndianSpringfield from './Bagger Family Images/springfield-spirit-blue-metallic.webp'
import IndianSpringfiledDarkHorse from './Bagger Family Images/springfield-dh-black.webp';
import ChieftainLimited from './Bagger Family Images/chieftain-limited-black.webp';
import ChieftainDarkHorse from './Bagger Family Images/chieftain-dh-black-smoke-4.webp';
import IndianChallengerLimited from './Bagger Family Images/challenger-limited-maroon-metallic-5.webp';
import IndianChallengerDarkHorse from './Bagger Family Images/challengerdarkhorse-black-smoke-6.webp';
import ISSilver from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/IndianSpringfieldSilver.webp';
import ISSilverIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/IndianSpringfieldSilverIcon.png';
import ISBlackMetallic from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/IndianSpringfieldBlackMetallic.jpg';
import ISBlackMetallicIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/IndianSpringfieldBlackMetallicIcon.png';
import ISDHBlackSmoke from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ISDHBlack.webp';
import ISDHBlackSmokeIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ISDHBlackIco.png';
import ISDHSagebrushSmoke from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ISDHSagebrushSmoke.jpg';
import ISDHSagebrushSmokeIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ISDHSagebrushSmokeIcon.png';
import CLBlack from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ChieftainLimitedBlack.webp';
import CLBlackIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ChieftainLimitedBlackIcon.webp';
import CLMaroonMetallic from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ChieftainLimitedMaroonMetallic.jpg';
import CLMaroonMetallicIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ChieftainLimitedMaroonMetallicIcon.png';
import CLBlueMetallic from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ChieftainLimitedBlueMetallic.jpg';
import CLBlueMetallicIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ChieftainLimitedBlueMetallicIcon.png';
import CDHBlack from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/CDHBlack.webp';
import CDHBlackIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/CDHBlackIcon.png';
import CDHWhiteMetallic from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/CDHWhiteMetallic.jpg';
import CDHWhiteMetallicIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/CDHWhiteMetallicIcon.png';
import CDHGrayAzure from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/CDHGrayAzure.jpg';
import CDHGrayAzureIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/CDHGrayAzureIcon.png';
import ICLBlack from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICLBlack.webp';
import ICLBlackIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICLBlackIcon.png';
import ICLWhiteMetallic from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICLWhiteMetallic.jpg';
import ICLWhiteMetallicIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICLWhiteMetallicIcon.png';
import ICLRedMetallic from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICLRedMetallic.jpg';
import ICLRedMetallicIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICLRedMetallicIcon.png';
import ICDHBlack from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images//ICDHBlack.webp';
import ICDHBlackIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images//ICDHBlackIco.png';
import ICDHRedSmoke from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICDHRedSmoke.jpg';
import ICDHRedSmokeIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICDHRedSmokeIcon.png';
import ICDHBlueSmoke from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICDHBlueSmoke.jpg';
import ICDHBlueSmokeIcon from '../../../Single Motorcycle/Bagger Motorcycles/Bagger Motorcycles Images/ICDHBlueSmokeIcon.png';

const BaggerMTFDetails = [
    {
        id: 1,
        image: IndianSpringfield,
        title: `Indian Springfield`,
        price: `30 290 €`,
        link: `/indian-springfield`,
        members: [
            {
                color: `Bronze Pearl / Silver Quartz`,
                price: `30 290 €`,
                icon: ISSilverIcon,
                image: ISSilver
            },
            {
                color: `Black Metallic / Titanium Metallic`,
                price: `30 290 €`,
                icon: ISBlackMetallicIcon,
                image: ISBlackMetallic
            }
        ]
    },
    {
        id: 2,
        image: IndianSpringfiledDarkHorse,
        title: `Indian Springfield Dark Horse`,
        price: `30 790 €`,
        link: `/indian-springfield-dark-horse`,
        members: [
            {
                color: `Black Smoke`,
                price: `30 790 €`,
                icon: ISDHBlackSmokeIcon,
                image: ISDHBlackSmoke
            },
            {
                color: `Sagebrush Smoke`,
                price: `31 040 €`,
                icon: ISDHSagebrushSmokeIcon,
                image: ISDHSagebrushSmoke
            }
        ]
    },
    {
        id: 3,
        image: ChieftainLimited,
        title: `Chieftain Limited`,
        price: `32 290 €`,
        link: `/chieftain-limited`,
        members: [
            {
                color: `Black Metallic`,
                price: `32 290 €`,
                icon: CLBlackIcon,
                image: CLBlack
            },
            {
                color: `Maroon Metallic`,
                price: `32 290 €`,
                icon: CLMaroonMetallicIcon,
                image: CLMaroonMetallic
            },
            {
                color: `Spirit Blue Metallic`,
                price: `32 290 €`,
                icon: CLBlueMetallicIcon,
                image: CLBlueMetallic
            }
        ]
    },
    {
        id: 4,
        image: ChieftainDarkHorse,
        title: `Chieftain Dark Horse`,
        price: `32 790 €`,
        link: `/chieftain-dark-horse`,
        members: [
            {
                color: `Black Smoke`,
                price: `32 790 €`,
                icon: CDHBlackIcon,
                image:  CDHBlack
            },
            {
                color: `Ghost White Metallic Smoke`,
                price: `33 090 €`,
                icon: CDHWhiteMetallicIcon,
                image:  CDHWhiteMetallic
            },
            {
                color: `Stealth Gray Azure`,
                price: `33 090 €`,
                icon: CDHGrayAzureIcon,
                image:  CDHGrayAzure
            }
        ]
    },
    {
        id: 5,
        image: IndianChallengerLimited,
        title: `Indian Challenger Limited`,
        price: `32 890 €`,
        link: `/indian-challenger-limited`,
        members: [
            {
                color: `Black Metallic`,
                price: `32 890 €`,
                icon: ICLBlackIcon,
                image: ICLBlack
            },
            {
                color: `Ghost White Metallic`,
                price: `33 390  €`,
                icon: ICLWhiteMetallicIcon,
                image: ICLWhiteMetallic
            },
            {
                color: `Sunset Red Metallic`,
                price: `33 390 €`,
                icon: ICLRedMetallicIcon,
                image: ICLRedMetallic
            }
        ]
    },
    {
        id: 6,
        image: IndianChallengerDarkHorse,
        title: `Indian Challenger Dark Horse`,
        price: `33 390 €`,
        link: `/indian-challenger-dark-horse`,
        members: [
            {
                color: `Black Smoke`,
                price: `33 390 €`,
                icon: ICDHBlackIcon,
                image: ICDHBlack
            },
            {
                color: `Sunset Red Smoke`,
                price: `33 690 €`,
                icon: ICDHRedSmokeIcon,
                image: ICDHRedSmoke
            },
            {
                color: `Spirit Blue Smoke`,
                price: `33 690 €`,
                icon: ICDHBlueSmokeIcon,
                image: ICDHBlueSmoke
            },
       
        ]
    },
]

export default BaggerMTFDetails;