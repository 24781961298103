import ftr from '../Home  Page Images/ftr.webp';
import scout from '../Home  Page Images/scout.webp';
import cruiser from '../Home  Page Images/cruiser.webp';
import bagger from '../Home  Page Images/bagger.webp';
import touring from '../Home  Page Images/touring.webp';
import darkhorse from '../Home  Page Images/dark-horse.webp';
import elite from '../../Main Images/Motorcycles Dropdown Family/indian-challenger-elite.png';
import scout101 from '../Home  Page Images/scout101.jpg';

const motorcyclelist = [
    {
        id: 1,
        title: `FTR`,
        image: ftr,
        link: `/ftr-family`
    },
    {
        id: 2,
        title: `Scout 1133cc`,
        image: scout,
        link: `/scout-family`
    },
    {
        id: 3,
        title: `Scout 1250cc`,
        image: scout101,
        link: `/scout-1250cc-family`
    },
    {
        id: 4,
        title: `Cruiser`,
        image: cruiser,
        link: `/cruiser-family`
    },
    {
        id: 5,
        title: `Bagger`,
        image: bagger,
        link: `/bagger-family`
    },
    {
        id: 6,
        title: `Touring`,
        image: touring,
        link: `/touring-family`
    },
    {
        id: 7,
        title: `Dark Hourse`,
        image: darkhorse,
        link: `/dark-horse-family`
    },
    {
        id: 8,
        title: `Elite`,
        image: elite,
        link: `/elite-family`,
    },
];

export default motorcyclelist;