const ScoutOverviewDetails = [
    {
        id: 1,
        title: `Стил за секого`,
        description: `Од класичните линии на Scout до заоблените браници и затемнети завршетоци на Scout Rogue, има Scout за секој вкус.`
    },
    {
        id: 2,
        title: `Неспоредлива стабилност`,
        description: `Со едноставниот дизајн на Scout и нискиот центар на гравитација, секој може да го вози со сигурност и стабилност.`
    },
    {
        id: 3,
        title: `Перформанси`,
        description: `Најдобрите перформанси во класата се испорачуваат преку исклучителен сооднос моќност и тежина.`
    },
    {
        id: 4,
        title: `Многу опции`,
        description: `Има огромна разновидност на достапни додатоци, од поголеми седишта до ретровизори, и чанти и облоги за подолго возење.`
    },
]

export default ScoutOverviewDetails;