import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import FindADealer from './Sub Pages/Find A Dealer/FindADealer';
import FtrFamily from './Sub Pages/Motorcycles Family/Single Motorcycle Family/FTR Family/FtrFamily';
import ScoutFamily from './Sub Pages/Motorcycles Family/Single Motorcycle Family/SCOUT Family/ScoutFamily';
import CruiserFamily from './Sub Pages/Motorcycles Family/Single Motorcycle Family/CRUISER Family/CruiserFamily';
import BaggerFamily from './Sub Pages/Motorcycles Family/Single Motorcycle Family/BAGGER Family/BaggerFamily';
import TouringFamily from './Sub Pages/Motorcycles Family/Single Motorcycle Family/TOURING Family/TouringFamily';
import DarkHorseFamily from './Sub Pages/Motorcycles Family/Single Motorcycle Family/DARK HORSE Family/DarkHorseFamily';
import FTRRCarbon from './Sub Pages/Single Motorcycle/FTR Motorcycles/FTRRCarbon';
import TestRide from './Sub Pages/Test Ride/TestRide';
import FTRRally from './Sub Pages/Single Motorcycle/FTR Motorcycles/FTRRally';
import FTRSport from './Sub Pages/Single Motorcycle/FTR Motorcycles/FTRSport';
import FTR from './Sub Pages/Single Motorcycle/FTR Motorcycles/FTR';
import Scout from './Sub Pages/Single Motorcycle/Scout Motorcycles/Scout';
import ScoutBobber from './Sub Pages/Single Motorcycle/Scout Motorcycles/ScoutBobber';
import ScoutRogue from './Sub Pages/Single Motorcycle/Scout Motorcycles/ScoutRogue';
import ScoutBobberTwenty from './Sub Pages/Single Motorcycle/Scout Motorcycles/ScoutBobberTwenty';
import ChiefDarkHorse from './Sub Pages/Single Motorcycle/Cruiser Motorcycles/ChiefDarkHorse';
import ChiefBobberDarkHorse from './Sub Pages/Single Motorcycle/Cruiser Motorcycles/ChiefBobberDarkHorse';
import SportChief from './Sub Pages/Single Motorcycle/Cruiser Motorcycles/SportChief';
import SuperChiefLimited from './Sub Pages/Single Motorcycle/Cruiser Motorcycles/SuperChiefLimited';
import IndianSpringfield from './Sub Pages/Single Motorcycle/Bagger Motorcycles/IndianSpringfield';
import IndianSpringfieldDarkHorse from './Sub Pages/Single Motorcycle/Bagger Motorcycles/IndianSpringfieldDarkHorse';
import ChieftainLimited from './Sub Pages/Single Motorcycle/Bagger Motorcycles/ChieftainLimited';
import ChieftainDarkHorse from './Sub Pages/Single Motorcycle/Bagger Motorcycles/ChieftainDarkHorse';
import IndianChallengerLimited from './Sub Pages/Single Motorcycle/Bagger Motorcycles/IndianChallengerLimited';
import IndianChallengerDarkHorse from './Sub Pages/Single Motorcycle/Bagger Motorcycles/IndianChallengerDarkHorse';
import Roadmaster from './Sub Pages/Single Motorcycle/Touring Motorcycles/Roadmaster';
import RoadmasterLimited from './Sub Pages/Single Motorcycle/Touring Motorcycles/RoadmasterLimited';
import RoadmasterDarkHorse from './Sub Pages/Single Motorcycle/Touring Motorcycles/RoadmasterDarkHorse';
import IndianPursuitLimited from './Sub Pages/Single Motorcycle/Touring Motorcycles/IndianPursuitLimited';
import IndianPursuitDarkHorse from './Sub Pages/Single Motorcycle/Touring Motorcycles/IndianPursuitDarkHorse';
import Imrg from './Sub Pages/IMRG/Imrg';
import History from './Sub Pages/History/History';
import AboutIndian from './Sub Pages/About Indian/AboutIndian';
import IndianRacing from './Sub Pages/Indian Racing/IndianRacing';
import BecomingLegendary from './Sub Pages/History Sub Pages/Becoming Legendary/BecomingLegendary';
import TumultuousTimes from './Sub Pages/History Sub Pages/Tumultuous Times/TumultuousTimes';
import AndBeyond from './Sub Pages/History Sub Pages/2011 and Beyond/AndBeyond';
import RideCommand from './Sub Pages/Ride Command/RideCommand';
// import ChallengerElite from './Sub Pages/Single Motorcycle/Touring Motorcycles/ChallengerElite';
import ContactUs from './Sub Pages/Contact Us/ContactUs';
import FTRSuperHooligan from './Sub Pages/Single Motorcycle/FTR Motorcycles/FTRSuperHooligan';
import FTRXRCarbon from './Sub Pages/Single Motorcycle/FTR Motorcycles/FTRXRCarbon';
import EliteFamily from './Sub Pages/Motorcycles Family/Single Motorcycle Family/ELITE Family/EliteFamily';
import IndianChallengerElite from './Sub Pages/Single Motorcycle/Elite Motorcycles/IndianChallengerElite';
import IndianRoadmasterElite from './Sub Pages/Single Motorcycle/Elite Motorcycles/IndianRoadmasterElite';
import Scout1250ccFamily from './Sub Pages/Motorcycles Family/Single Motorcycle Family/SCOUT 1250CC Family/Scout1250ccFamily';
import ScoutClassic from './Sub Pages/Single Motorcycle/Scout 1250cc Motorcycles/ScoutClassic';
import ScoutBobber1250cc from './Sub Pages/Single Motorcycle/Scout 1250cc Motorcycles/ScoutBobber1250cc';
import SportScout from './Sub Pages/Single Motorcycle/Scout 1250cc Motorcycles/SportScout';
import SuperScout from './Sub Pages/Single Motorcycle/Scout 1250cc Motorcycles/SuperScout';
import Scout101 from './Sub Pages/Single Motorcycle/Scout 1250cc Motorcycles/Scout101';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "find-a-dealer/map-view",
    element: <FindADealer/>
  },
  {
    path: "/ftr-family",
    element: <FtrFamily/>
  },
  {
    path: "scout-family",
    element: <ScoutFamily/>
  },
  {
    path: "cruiser-family",
    element: <CruiserFamily/>
  },
  {
    path: "bagger-family",
    element: <BaggerFamily/>
  },
  {
    path: "touring-family",
    element: <TouringFamily/>
  },
  {
    path: "dark-horse-family",
    element: <DarkHorseFamily/>
  },
  {
    path: "elite-family",
    element: <EliteFamily/>
  },
  {
    path: `scout-1250cc-family`,
    element: <Scout1250ccFamily/>
  },
  {
    path: `scout-bobber-1250cc`,
    element: <ScoutBobber1250cc/>
  },
  {
    path: "sport-scout-1250cc",
    element: <SportScout/>
  },
  {
    path: `super-scout-1250cc`,
    element: <SuperScout/>
  },
  {
    path: `101-scout-1250cc`,
    element: <Scout101/>
  },
  {
    path: `scout-classic-1250cc`,
    element: <ScoutClassic/>
  },
  {
    path: 'ftr-r-carbon',
    element: <FTRRCarbon/>
  },
  {
    path: 'ftr-rally',
    element: <FTRRally/>
  },
  {
    path: 'ftr-sport',
    element: <FTRSport/>
  },
  {
    path: 'ftr-x-rsd-super-hooligan',
    element: <FTRSuperHooligan/>
  },
  {
    path: 'ftr-x-100-r-carbon',
    element: <FTRXRCarbon/>
  },
  {
    path: 'ftr',
    element: <FTR/>
  },
  {
    path: 'scout',
    element: <Scout/>
  },
  {
    path: 'scout-bobber',
    element: <ScoutBobber/>
  },
  {
    path: 'scout-rogue',
    element: <ScoutRogue/>
  },
  {
    path: 'scout-bobber-twenty',
    element: <ScoutBobberTwenty/>
  },
  {
    path: 'chief-dark-horse',
    element: <ChiefDarkHorse/>
  },
  {
    path: 'chief-bobber-dark-horse',
    element: <ChiefBobberDarkHorse/>
  },
  {
    path: 'sport-chief',
    element: <SportChief/>
  },
  {
    path: 'super-chief-limited',
    element: <SuperChiefLimited/>
  },
  {
    path: 'indian-springfield',
    element: <IndianSpringfield/>
  },
  {
    path: 'indian-springfield-dark-horse',
    element: <IndianSpringfieldDarkHorse/>
  },
  {
    path: 'chieftain-limited',
    element: <ChieftainLimited/>
  },
  {
    path: 'chieftain-dark-horse',
    element: <ChieftainDarkHorse/>
  },
  {
    path: 'indian-challenger-limited',
    element: <IndianChallengerLimited/>
  },
  {
    path: 'indian-challenger-dark-horse',
    element: <IndianChallengerDarkHorse/>
  },
  {
    path: 'roadmaster',
    element: <Roadmaster/>
  },
  {
    path: 'roadmaster-limited',
    element: <RoadmasterLimited/>
  },
  {
    path: 'roadmaster-dark-horse',
    element: <RoadmasterDarkHorse/>
  },
  {
    path: 'indian-pursuit-limited',
    element: <IndianPursuitLimited/>
  },
  {
    path: 'indian-pursuit-dark-horse',
    element: <IndianPursuitDarkHorse/>
  },
  {
    path: 'indian-challenger-elite',
    element: <IndianChallengerElite/>
  },
  {
    path: 'indian-roadmaster-elite',
    element: <IndianRoadmasterElite/>
  },
  {
    path: 'test-ride',
    element: <TestRide/>
  },
  {
    path: 'imrg',
    element: <Imrg/>
  },
  {
    path: 'history',
    element: <History/>
  },
  {
    path: 'about-us',
    element: <AboutIndian/>
  },
  {
    path: 'flat-track-race/racing',
    element: <IndianRacing/>
  },
  {
    path: `becoming-legendary`,
    element: <BecomingLegendary/>
  },
  {
    path: `tumultuous-times`,
    element: <TumultuousTimes/>
  },
  {
    path: `2011-and-beyond`,
    element: <AndBeyond/>
  },
  {
    path: `ride-command`,
    element: <RideCommand/>
  },
  {
    path: `contact-us`,
    element: <ContactUs/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);
