import ChiefDarkHorse from './Dark Horse Family Images/chief-dh-black-smoke-1.webp';
import ChiefBobberDarkHorse from './Dark Horse Family Images/chief-bobber-dh-black-smoke-2.webp';
import IndianSpringfieldDarkHorse from './Dark Horse Family Images/springfield-dh-black-3.webp';
import ChieftainDarkHorse from './Dark Horse Family Images/chieftain-dh-black-smoke-4.webp';
import IndianChallengerDarkHorse from './Dark Horse Family Images/challengerdarkhorse-black-smoke-5.webp';
import RoadmasterDarkHorse from './Dark Horse Family Images/roadmaster-dh-black-6.webp';
import IndianPursuitDarkHorsewithPremiumPackage from './Dark Horse Family Images/pursuit-dh-premium-thunder-7.webp';

const DarkHorseMTFDetails = [
    {
        id: 1,
        image: ChiefDarkHorse,
        title: `Chief Dark Horse`,
        price: `19 390 €`,
        link: `/chief-dark-horse`,
    },
    {
        id: 2,
        image: ChiefBobberDarkHorse,
        title: `Chief Bobber Dark Horse`,
        price: `21 490 €`,
        link: `/chief-bobber-dark-horse`,
    },
    {
        id: 3,
        image: IndianSpringfieldDarkHorse,
        title: `Indian Springfield Dark Horse`,
        price: `30 490 €`,
        link: `/indian-springfield-dark-horse`,
    },
    {
        id: 4,
        image: ChieftainDarkHorse,
        title: `Chieftain Dark Horse`,
        price: `32 490 €`,
        link: `/chieftain-dark-horse`,
    },
    {
        id: 5,
        image: IndianChallengerDarkHorse,
        title: `Indian Challenger Dark Horse`,
        price: `33 090 €`,
        link: `/indian-challenger-dark-horse`,
    },
    {
        id: 6,
        image: RoadmasterDarkHorse,
        title: `Roadmaster Dark Horse`,
        price: `34 890 €`,
        link: `/roadmaster-dark-horse`,
    },
    {
        id: 7,
        image: IndianPursuitDarkHorsewithPremiumPackage,
        title: `Indian Pursuit Dark Horse`,
        price: `35 490 €`,
        link: `/indian-pursuit-dark-horse`,
    },
]

export default DarkHorseMTFDetails;