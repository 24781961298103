const TestRideForm = ({modelTitle}) => {


    return (
        <div className="test_ride_form">
            <div className="testride_form_container">
                {modelTitle ? <h3>2. Вашите податоци</h3> : <h3>1. Вашите податоци</h3>}
                <form action="https://formsubmit.co/info@indianmotorcycles.mk" method="POST">
                    <input type="hidden" name="_subject" value="Indian Motorcycle Macedonia"/>
                    <input type="hidden" name="_captcha" value="false"/>
                    <input type="hidden" name="_template" value="table"></input>
                    <input type="hidden" name="_next" value="https://www.indianmotorcycles.mk/"></input>
                    {modelTitle ? <input type="text" name="Модел" className="testride_input" value={modelTitle}></input> : ``}
                    <input className="testride_input" placeholder="ИМЕ*" required name="Име"></input>
                    <input className="testride_input" placeholder="ПРЕЗИМЕ*" required name="Презиме"></input>
                    <input className="testride_input" type='email' placeholder="Е-МАИЛ*" required name="Емаил"></input>
                    <input className="testride_input" type="phone" placeholder="ТЕЛЕФОН:*" required name="Телефон"></input>
                    <input className="testride_input" placeholder="ГРАД*" required name="Град"></input>
                    {   modelTitle ? <p className="form_note"><b>*Забелешка:</b> Тест возењата се достапни кога има промоции или контактирајте не на: +389 712 999 48 за повеќе информации.</p>
                    : `` }
                    <button type="submit" className="form_button">Испрати</button>
                </form>
            </div>
        </div>
    )
}

export default TestRideForm;