import Header from "../../../../Main Components/Header";
import HeroHeading from "../../components/HeroHeading";
import MeetTheFamily from "../../components/MeetTheFamily";
import Overview from "../../components/Overview";
import CruiserBackground from './CRUISER Family Images/cruiser-background.webp';
import CruiserMTFDetails from "./CruiserMTFDetails";
import CruiserOverviewDetails from "./CruiserOverviewDetails";
import './cruiserstyle.css';
import CruiserHistoryLogo from './CRUISER Family Images/cruiser-history-logo.webp';
import CruiserHistoryDouble from './CRUISER Family Images/cruiser-history-double.webp';
import CruiserHistoryBottom from './CRUISER Family Images/cruiser-bottom.webp';
import { useEffect, useState } from "react";
import bloglist from "../../../../Home Page/constants/bloglist";
import Parallax from "../../../../Main Components/Parallax";
import Footer from "../../../../Main Components/Footer";

const CruiserFamily = () => {

    const heroTitle = `CRUISER`;
    const heroDescription = `Направени за крстарење. Тие се стилски и забавни, со опуштена положба на возење, простливо управување и низок центар на гравитација за лесна контрола.`;

    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
       }, [])
    

    return (
       <>
        <Header/>
        <div className="family_background">
                <img src={CruiserBackground} alt=""></img>
        </div>
        <HeroHeading heroTitle={heroTitle} heroDescription={heroDescription}/>
        <Overview details={CruiserOverviewDetails}/>
        <MeetTheFamily mtfdetails={CruiserMTFDetails}/>
        <div className="cruiser_history">
                <div className="cruiser_history_container">
                    <div className="cruiser_history_logo">
                        <img src={CruiserHistoryLogo} alt=""></img>
                    </div>
                    <div className="cruiser_history_text">
                        <h3>Избегнете од бучата</h3>
                        <p>Indian Motorcycle го редефинира иконскиот американски Cruiser. Тие се совршен спој на "старо" и "ново" - безвременски дизајн дополнет со модерна технологија, перформанси и нула компромиси.</p>
                    </div>
                    <div className="cruiser_history_images">
                        <img src={CruiserHistoryDouble} alt=""/>
                    </div>
                    <div className="cruiser_history_bottom">
                        <div className="cruiser_history_bottom_inner">
                            <h3>Уметност</h3>
                            <p>Со својот автентичен дизајн, совршеноста на Cruiser се насочува кон минатото на Америка. Еден поглед и знаете кој детал е класичен за Indian Motorcycle.</p>
                        </div>
                        <img src={CruiserHistoryBottom} alt=""></img>
                    </div>
                </div>
            </div>
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
       </>
    )
}

export default CruiserFamily;