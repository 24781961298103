import Header from '../../Main Components/Header';
import historyBackground from './History Images/history-background.webp';
import './historystyle.css';
import historycaleras from './constants/historycaleras';
import experience from './History Images/experience.webp';
import Parallax from '../../Main Components/Parallax';
import Footer from '../../Main Components/Footer';
import bloglist from '../../Home Page/constants/bloglist';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const History = () => {

    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
    }, []);

    return (
        <>
        <Header/>
            <img alt='' src={historyBackground} className='history_background'></img>
            <div className='history_top'>
                <h1>Првата американска компанија за мотоцикли®</h1>
                <p>Индустриските уникатни перформанси се потпираат на наследството на раните лидери и пионери на Indian Motorcycle.</p>
            </div>
            <div className='historical_eras'>
                <h1 className='historical_title'>Историски епохи</h1>
                <p>Поглавја од историско наследство на брендот</p>
                <div className='historycal_eras_wrapper'>
                    {
                        historycaleras.map((item) => (
                            <Link to={item.link}>
                                  <div className='historycal_eras_inner'>
                                <img alt='' src={item.image}></img>
                                <div className='eras_hero'>
                                    <h2>{item.title}</h2>
                                    <p>{item.date}</p>
                                </div>

                            </div>
                            </Link>
                         
                        ))
                    }
                </div>
            </div>
            <div className='history_experience'>
                <h1 className='historical_title'>Лично искусете го наследството на Indian Motorcycle</h1>
                <div className='history_experience_container'>
                    <div className='history_experience_inner'>
                        <h1>Турнеја во фабриката</h1>
                        <p>За поимпресивен поглед на Indian Motorcycle, посетете го центарот за искуство со мотоцикли, прикачен на фабриката за  мотоцикли во Спирит Лејк, Ајова. Центарот ги прикажува актуелните и историските мотоцикли, прикажува стари артефакти и сувенири и им нуди на посетителите можност да купат ексклузивна фабричка роба. Посетителите исто така можат да видат кратка видео обиколка на производниот погон во време кога не се достапни тури со водич. Гостите се добредојдени да го посетат центарот за искуство со мотоцикли во секое време од понеделник до петок помеѓу часовите од 08:00 до 15:00 часот (CST), со исклучок на празници.</p>
                    </div>
                    <div className='history_experience_inner'>
                        <img alt='' src={experience}></img>
                    </div>
                </div>
            </div>
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default History;