import Header from "../../../../Main Components/Header";
import HeroHeading from "../../components/HeroHeading";
import MeetTheFamily from "../../components/MeetTheFamily";
import Overview from "../../components/Overview";
import ScoutBackground from './SCOUT Family Images/scout-background.webp';
import ScoutMTFDetails from "./ScoutMTFDetails";
import ScoutOverviewDetails from "./ScoutOverviewDetails";
import './scoutstyle.css';
import ScoutHistoryLogo from './SCOUT Family Images/scout-history-logo.webp';
import ScoutHistoryDouble from './SCOUT Family Images/scout-history-double.webp';
import ScoutHistoryBottom from './SCOUT Family Images/scout-history-bottom.webp';
import bloglist from "../../../../Home Page/constants/bloglist";
import { useEffect, useState } from "react";
import Parallax from "../../../../Main Components/Parallax";
import Footer from "../../../../Main Components/Footer";

const ScoutFamily = () => {

    const heroTitle = `SCOUT 1133CC`;
    const heroDescription = `Со иконски стил вкоренет во 120-годишната историја на индискиот мотоцикл, Indian Scout нуди модерни перформанси и технологија што ќе го пречекаат секој возач.`;
    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
       }, [])
    
    
    return (
        <>
            <Header/>
            <div className="family_background">
                <img src={ScoutBackground} alt=""></img>
            </div>
            <HeroHeading heroTitle={heroTitle} heroDescription={heroDescription}/>
            <Overview details={ScoutOverviewDetails}/>
            <MeetTheFamily mtfdetails={ScoutMTFDetails}/>
            <div className="scout_history">
                <div className="scout_history_container">
                    <div className="scout_history_logo">
                        <img src={ScoutHistoryLogo} alt=""></img>
                    </div>
                    <div className="scout_history_text">
                        <h3>Врвна икона</h3>
                        <p>Секое возење е комбинација на незауздана моќ, рафинирана со исклучителна управливост.</p>
                    </div>
                    <div className="scout_history_images">
                        <img src={ScoutHistoryDouble} alt=""/>
                    </div>
                    <div className="scout_history_bottom">
                        <div className="scout_history_bottom_inner">
                            <h3>Секој Scout ремек дело</h3>
                            <p>Секој Scout е составен од грижливи инженери како да е нивни. Ова внимание на деталите му дава на Scout комбинација на иконски дизајн и модерен стил.</p>
                        </div>
                        <img src={ScoutHistoryBottom} alt=""></img>
                    </div>
                </div>
            </div>
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default ScoutFamily;