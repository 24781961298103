import '../motorcyclestyle.css';
import Header from "../../../Main Components/Header";
import { useEffect, useState } from 'react';
import SpecificationsList from '../constants/SpecificationsList';
import bloglist from '../../../Home Page/constants/bloglist';
import Button from '../Components/Button';
import Widget from '../Components/Widget';
import Featured from '../Components/Featured';
import Specifications from '../Components/Specifications';
import Parallax from '../../../Main Components/Parallax';
import Footer from '../../../Main Components/Footer';
import { BuildYoursText, TestRideText, TestRide, innerInformationText, topWarningMessage, BuildYourOwnLinks, ContactUsLink, ContactUsText } from "../../../Constants/AllConstants";
import { useNavigate } from 'react-router';
import Scout1250ccMTFDetails from '../../Motorcycles Family/Single Motorcycle Family/SCOUT 1250CC Family/Scout1250ccMTFDetails';
import {  SuperScoutFeaturedItems } from './constants/Scout1250ccFeaturedItems';
import { ScoutSuperCarouselImages } from './constants/Scout1250ccCarouselImages';
import CarouselImages from '../Components/CarouselImages';


const SuperScout = () => {

    const navigate = useNavigate();

    const [motorcycleData, setMotorcycleData] = useState();
    const [sortedList, setSortedList] = useState();
    const [currentColor, setCurrentColor] = useState();
    const [specificationsData, setSpecificationsData] = useState();
    const [chosenImage, setChosenImage] = useState();
    const [priceMotorcycle, setPrice] = useState();
    const [allTypes, setAllTypes] = useState();
    const [buildYourOwnLink, setBuildYourOwnLink] = useState();
    const [motorcycleTypes, setMotorcycleTypes] = useState();


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        
        let foundData = SpecificationsList.filter((item) => item.family === 'Scout 1250cc');
        let readyData = foundData[0].members.filter((item) => item.title === 'Super Scout');
        setSpecificationsData(readyData);

    }, [])


    useEffect(() => {
        const getData = Scout1250ccMTFDetails.filter((item) => item.title === `Super Scout`);
        setMotorcycleData(getData);
        setPrice(getData[0].price);
        setMotorcycleTypes(getData[0].members);
        setAllTypes(getData[0].members[0].items);
        setChosenImage(getData[0].members[0].items[0].image);
        setCurrentColor(getData[0].members[0].items[0].color);

        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);

        const FindBYOLink = BuildYourOwnLinks.find((item) => item.name === `Scout`);
        setBuildYourOwnLink(FindBYOLink.link);

    }, []);

    if(!motorcycleData) {
        return null;
    }

    let { title } = motorcycleData[0];
    const headingText = title + ` `+ priceMotorcycle;

    const handleColorSwap = (color, image, newPrice) => {
        setCurrentColor(color);
        setChosenImage(image);
        setPrice(newPrice)
    }

    const handleTestRideNavigate = () => {
        const stateData = { 
            name: title,
            image: chosenImage
        };
        navigate(`${TestRide}`, { state: stateData });
    }

    const handleTypeChange = (id) => {
        let radioButtons = document.querySelectorAll(".motorcycle_radio_types");
        for(let radio of radioButtons) {
            radio.checked = false;
        }
        setAllTypes(motorcycleTypes.find((item) => item.id === id).items);
        setChosenImage(motorcycleTypes.find((item) => item.id === id).items[0].image);
        setCurrentColor(motorcycleTypes.find((item) => item.id === id).items[0].color);
        setPrice(motorcycleTypes.find((item) => item.id === id).items[0].price);
        document.getElementById(`radio${id}`).checked = true;
    }


    return (
        <>
            <Header/>
            <div className="top_content_container super_scout_1250_background">
            <div className="top_content">
                <div className="top_content_text">
                    <h1>{title}</h1>
                    <p>Веќе од <span className="motorcycle_price">{priceMotorcycle}</span><span className="motorcycle_hero_info">{innerInformationText}</span></p>       
                    <Button text={BuildYoursText} link={buildYourOwnLink}/>
                    <Button text={TestRideText} onClick={handleTestRideNavigate}/>
                    <Button text={ContactUsText} link={ContactUsLink}/>
                    <div className="top_content_hero">
                        <p>{topWarningMessage}</p>
                    </div>
                </div>
            </div>
        </div>
        <Widget headingText={headingText} innerText={innerInformationText}/>
        <div className="motorcycle_detail pt-5 pb-5">
            <div className="motorcycle_detail_container scout_detail_wrapper">
                <div className="motorcycle_detail_img">
                    <img alt="" src={chosenImage} ></img>
                </div>
                <div className="motorcycle_detail_wrapper">
                    <h1>{title}</h1>
                    <p>Веќе од <span className="bolded_price">{priceMotorcycle}</span> {innerInformationText}</p>
                    <div className="motorcycle_detail_colors">
                        {
                            motorcycleTypes.map((item) => (
                               <>
                                <div>
                                    <input type='radio' className='d-inline-block motorcycle_radio_types' id={`radio${item.id}`} onClick={() => handleTypeChange(item.id)}/>
                                    <p className='d-inline-block mx-2' onClick={() => handleTypeChange(item.id)}>{item.name}</p>
                                </div>
                               </>
                            ))
                        }
                         <p>Боја: <span className="current_color">{currentColor}</span></p>
                        {
                            allTypes.map((item) => (
                                <img className="color_swap_img" src={item.icon} alt="" onClick={() => handleColorSwap(item.color, item.image, item.price)}/>
                            ))
                        }
                    </div>
                    <Button text={BuildYoursText} link={buildYourOwnLink}/>
                    <Button text={TestRideText} onClick={handleTestRideNavigate}/>
                </div>
            </div>
        </div>
         {
            SuperScoutFeaturedItems.map((items) => (
                <Featured items={items} key={items.id}/>
            ))
        } 
        <Specifications specificationsData={specificationsData}/>
        <CarouselImages images={ScoutSuperCarouselImages}/>
        <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default SuperScout;