import roadmaster1 from '../Touring Carousel Images/roadmaster-1.webp';
import roadmaster2 from '../Touring Carousel Images/roadmaster-2.webp';
import roadmaster3 from '../Touring Carousel Images/roadmaster-3.webp';
import roadmaster4 from '../Touring Carousel Images/roadmaster-4.webp';
import roadmaster5 from '../Touring Carousel Images/roadmaster-5.webp';
import roadmaster6 from '../Touring Carousel Images/roadmaster-6.webp';

import rl1 from '../Touring Carousel Images/rl-1.webp';
import rl2 from '../Touring Carousel Images/rl-2.webp';
import rl3 from '../Touring Carousel Images/rl-3.webp';
import rl4 from '../Touring Carousel Images/rl-4.webp';
import rl5 from '../Touring Carousel Images/rl-5.webp';
import rl6 from '../Touring Carousel Images/rl-6.webp';

import rdh1 from '../Touring Carousel Images/rdh-1.webp';
import rdh2 from '../Touring Carousel Images/rdh-2.webp';
import rdh3 from '../Touring Carousel Images/rdh-3.webp';
import rdh4 from '../Touring Carousel Images/rdh-4.webp';
import rdh5 from '../Touring Carousel Images/rdh-5.webp';
import rdh6 from '../Touring Carousel Images/rdh-6.webp';

import ipl1 from '../Touring Carousel Images/ipl-1.webp';
import ipl2 from '../Touring Carousel Images/ipl-2.webp';
import ipl3 from '../Touring Carousel Images/ipl-3.webp';
import ipl4 from '../Touring Carousel Images/ipl-4.webp';
import ipl5 from '../Touring Carousel Images/ipl-5.webp';
import ipl6 from '../Touring Carousel Images/ipl-6.webp';

import ipdh1 from '../Touring Carousel Images/ipdh-1.webp';
import ipdh2 from '../Touring Carousel Images/ipdh-2.webp';
import ipdh3 from '../Touring Carousel Images/ipdh-3.webp';
import ipdh4 from '../Touring Carousel Images/ipdh-4.webp';
import ipdh5 from '../Touring Carousel Images/ipdh-5.webp';
import ipdh6 from '../Touring Carousel Images/ipdh-6.webp';

import ce1 from '../Touring Carousel Images/CE-item-1.jpg';
import ce2 from '../Touring Carousel Images/CE-item-2.jpg';
import ce3 from '../Touring Carousel Images/CE-item-3.jpg';
import ce4 from '../Touring Carousel Images/CE-item-4.jpg';
import ce5 from '../Touring Carousel Images/CE-item-5.jpg';
import ce6 from '../Touring Carousel Images/CE-item-6.jpg';


const RoadmasterCarouselImages = [
    {
        id: 1,
        image: roadmaster1
    },
    {
        id: 2,
        image: roadmaster2
    },
    {
        id: 3,
        image: roadmaster3
    },
    {
        id: 4,
        image: roadmaster4
    },
    {
        id: 5,
        image: roadmaster5
    },
    {
        id: 6,
        image: roadmaster6
    },
]

export { RoadmasterCarouselImages }

const RLCarouselImages = [
    {
        id: 1,
        image: rl1
    },
    {
        id: 2,
        image: rl2
    },
    {
        id: 3,
        image: rl3
    },
    {
        id: 4,
        image: rl4
    },
    {
        id: 5,
        image: rl5
    },
    {
        id: 6,
        image: rl6
    },
]

export { RLCarouselImages }



const RDHCarouselImages = [
    {
        id: 1,
        image: rdh1
    },
    {
        id: 2,
        image: rdh2
    },
    {
        id: 3,
        image: rdh3
    },
    {
        id: 4,
        image: rdh4
    },
    {
        id: 5,
        image: rdh5
    },
    {
        id: 6,
        image: rdh6
    },
]

export { RDHCarouselImages }

const IPLCarouselImages = [
    {
        id: 1,
        image: ipl1
    },
    {
        id: 2,
        image: ipl2
    },
    {
        id: 3,
        image: ipl3
    },
    {
        id: 4,
        image: ipl4
    },
    {
        id: 5,
        image: ipl5
    },
    {
        id: 6,
        image: ipl6
    },
]

export { IPLCarouselImages }

const IPDHCarouselImages = [
    {
        id: 1,
        image: ipdh1
    },
    {
        id: 2,
        image: ipdh2
    },
    {
        id: 3,
        image: ipdh3
    },
    {
        id: 4,
        image: ipdh4
    },
    {
        id: 5,
        image: ipdh5
    },
    {
        id: 6,
        image: ipdh6
    },
]

export { IPDHCarouselImages }


const CECarouselImages = [
    {
        id: 1,
        image: ce1
    },
    {
        id: 2,
        image: ce2
    },
    {
        id: 3,
        image: ce3
    },
    {
        id: 4,
        image: ce4
    },
    {
        id: 5,
        image: ce5
    },
    {
        id: 6,
        image: ce6
    },
]

export { CECarouselImages }