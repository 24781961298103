import Header from '../../Main Components/Header';
import './imrgstyle.css';
import backgroundImage from './IMRG Images/imrg-background.webp';
import ImrgHero from './components/ImrgHero';

import videoPoster from './IMRG Images/video-poster.webp';
import video from './IMRG Images/imrg-video.mp4';

import imrgPicture from './IMRG Images/imrg-picture.webp';
import { Link } from 'react-router-dom';

import imrgPicture2 from './IMRG Images/imrg-picture2.webp';
import Parallax from '../../Main Components/Parallax';
import Footer from '../../Main Components/Footer';
import { useEffect, useState } from 'react';
import bloglist from '../../Home Page/constants/bloglist';

const Imrg = () => {
    
    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
    }, []);


    const firstTitle = 'ПРИКЛУЧЕТЕ СЕ НА INDIAN MOTORCYCLE RIDERS';
    const firstDescription = 'Ние веруваме дека изборот што го правите ве дефинира. Не е потребен напор за да се придружите на толпата. Но, потребна е самодоверба за да тргнете по својот пат. Од 1901 година, ние сме изборот на возачи кои прават свои правила. Ние постоиме за да создаваме искуства кои ја слават нашата легендарна историја, за возачи кои сфаќаат дека сите ние сме дел од нешто поголемо од нас самите и се горди што ја споделуваат таа страст со други индивидуалисти истомисленици.';
    const firstButtonText=`РЕГИСТРИРАЈ СЕ`;
    const firstButtonLink = `https://imrgmember.eu/`;

    const secondTitle = `ДОБИЈ ПОВЕЌЕ ИСКУСТВО, ЗАПОЗНАЈ СЕ`;
    const secondDescription = `Да се биде дел од IMR значи да се биде дел од посветена заедница за возење мотоцикли што е поддржана од Indian Motorcycle и овластени Indian дилери за мотоцикли. Local Riders Group обезбедува планирани возења, настани, состаноци и други забавни активности каде што можете да се соберете со други возачи за да создадете искуство со мотоцикли. Indian Motorcycle исто така поддржува IMRG возења каде што повеќе Riders Group се обединуваат за забава и добротворни цели. Допрете ја нашата заедница од преку 100-тини групи на возачи на мотоцикли во Европа.`;
    const secondButtonText = `ПРОНАЈДИ`;

    return (
        <>
            <Header/>
            <div className='imrg_background'>
                <img alt='' src={backgroundImage}></img>
            </div>
            <div className='imrg_hero'>
                <ImrgHero title={firstTitle} description={firstDescription} buttonText={firstButtonText} link={firstButtonLink}/>
                <div className='imrg_videos_wrapper'>
                    <div className='imrg_videos_inner'>
                    <video autoplay controls controlslist="nodownload noremoteplayback" playsinline poster={videoPoster}>
                        <source src={video} type="video/mp4"/>
                    </video>
                    <Link to='https://www.youtube.com/watch?v=hq0oDJ5YJOg&ab_channel=IndianMotorcycle' className='imrg_links'>ЦЕЛО ВИДЕО</Link>
                    </div>
                    <div className='imrg_videos_inner'>
                        <img alt='' src={imrgPicture}></img>
                        <Link to='https://imrgmember.eu/' className='imrg_links'>РЕГИСТРАЦИЈА</Link>
                    </div>
                </div>
                <ImrgHero title={secondTitle} description={secondDescription} buttonText={secondButtonText}  link={firstButtonLink}/>
            </div>
            <img alt='' src={imrgPicture2} className='imrg_picture2'></img>
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default Imrg;