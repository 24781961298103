const TopSection = ({title, description}) => {
    return (
        <div className="history_sub_top">
            <h1>{title}</h1>
            {
                description.map((item) => (
                    <p>{item.description}</p>
                ))
            }
        </div>
    )
}

export default TopSection;