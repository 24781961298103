import is1 from '../Carousel Images/is-1.webp';
import is2 from '../Carousel Images/is-2.webp';
import is3 from '../Carousel Images/is-3.webp';
import is4 from '../Carousel Images/is-4.webp';
import is5 from '../Carousel Images/is-5.webp';
import is6 from '../Carousel Images/is-65.webp';

import isdh1 from '../Carousel Images/isdh-1.webp';
import isdh2 from '../Carousel Images/isdh-2.webp';
import isdh3 from '../Carousel Images/isdh-3.webp';
import isdh4 from '../Carousel Images/isdh-4.webp';
import isdh5 from '../Carousel Images/isdh-5.webp';
import isdh6 from '../Carousel Images/isdh-6.webp';

import cl1 from '../Carousel Images/cl-1.webp';
import cl2 from '../Carousel Images/cl-2.webp';
import cl3 from '../Carousel Images/cl-3.webp';
import cl4 from '../Carousel Images/cl-4.webp';
import cl5 from '../Carousel Images/cl-5.webp';
import cl6 from '../Carousel Images/cl-6.webp';

import cdh1 from '../Carousel Images/cdh-1.webp';
import cdh2 from '../Carousel Images/cdh-2.webp';
import cdh3 from '../Carousel Images/cdh-3.webp';
import cdh4 from '../Carousel Images/cdh-4.webp';
import cdh5 from '../Carousel Images/cdh-5.webp';
import cdh6 from '../Carousel Images/cdh-6.webp';

import icl1 from '../Carousel Images/icl-1.webp';
import icl2 from '../Carousel Images/icl-2.webp';
import icl3 from '../Carousel Images/icl-3.webp';
import icl4 from '../Carousel Images/icl-4.webp';
import icl5 from '../Carousel Images/icl-5.webp';
import icl6 from '../Carousel Images/icl-6.webp';

import icdh1 from '../Carousel Images/icdh-1.webp';
import icdh2 from '../Carousel Images/icdh-2.webp';
import icdh3 from '../Carousel Images/icdh-3.webp';
import icdh4 from '../Carousel Images/icdh-4.webp';
import icdh5 from '../Carousel Images/icdh-5.webp';
import icdh6 from '../Carousel Images/icdh-6.webp';


const IndianSpringfieldCarouselImages = [
    {
        id: 1,
        image: is1
    },
    {
        id: 2,
        image: is2
    },
    {
        id: 3,
        image: is3
    },
    {
        id: 4,
        image: is4
    },
    {
        id: 5,
        image: is5
    },
    {
        id: 6,
        image: is6
    },
]

export { IndianSpringfieldCarouselImages }

const ISDHCarouselImages = [
    {
        id: 1,
        image: isdh1
    },
    {
        id: 2,
        image: isdh2
    },
    {
        id: 3,
        image: isdh3
    },
    {
        id: 4,
        image: isdh4
    },
    {
        id: 5,
        image: isdh5
    },
    {
        id: 6,
        image: isdh6
    },
]

export { ISDHCarouselImages }


const ChieftainLimitedCarouselImages = [
    {
        id: 1,
        image: cl1
    },
    {
        id: 2,
        image: cl2
    },
    {
        id: 3,
        image: cl3
    },
    {
        id: 4,
        image: cl4
    },
    {
        id: 5,
        image: cl5
    },
    {
        id: 6,
        image: cl6
    },
]

export { ChieftainLimitedCarouselImages }

const ChieftainDarkHorseCarouselImages = [
    {
        id: 1,
        image: cdh1
    },
    {
        id: 2,
        image: cdh2
    },
    {
        id: 3,
        image: cdh3
    },
    {
        id: 4,
        image: cdh4
    },
    {
        id: 5,
        image: cdh5
    },
    {
        id: 6,
        image: cdh6
    },
]

export { ChieftainDarkHorseCarouselImages }

const ICLCarouselImages = [
    {
        id: 1,
        image: icl1
    },
    {
        id: 2,
        image: icl2
    },
    {
        id: 3,
        image: icl3
    },
    {
        id: 4,
        image: icl4
    },
    {
        id: 5,
        image: icl5
    },
    {
        id: 6,
        image: icl6
    },
]

export { ICLCarouselImages }

const ICDHCarouselImages = [
    {
        id: 1,
        image: icdh1
    },
    {
        id: 2,
        image: icdh2
    },
    {
        id: 3,
        image: icdh3
    },
    {
        id: 4,
        image: icdh4
    },
    {
        id: 5,
        image: icdh5
    },
    {
        id: 6,
        image: icdh6
    },
]

export { ICDHCarouselImages }