import Scout from './SCOUT Family Images/scout-black-metallic.webp';
import ScoutBobber from './SCOUT Family Images/scout-bobber-blacksmoke.webp';
import ScoutRogue from './SCOUT Family Images/scout-rogue-blacksmoke.webp';
import ScoutBobberTwenty from './SCOUT Family Images/scout-bobber-20-maroon-metallic.webp';
import ScoutBlackMetallicIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBlackMetallicIcon.png';
import ScoutBlackMetallic from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBlackMetallic.jpg';
import ScoutMaroon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutMaroon.webp';
import ScoutMaroonIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutMaroonIcon.png';
import ScoutSilver from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutSilver.webp';
import ScoutSilverIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutSilverIcon.png';
import ScoutSpiritBlueMetallic from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutSpiritBlueMetallic.jpg';
import ScoutSpiritBlueMetallicIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutSpiritBlueMetallicIcon.png';
import ScoutBobberBlackSmoke from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberBlackSmoke.webp';
import ScoutBobberSagebrush from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberSagebrush.webp';
import ScoutBobberSilver from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberSilver.webp';
import ScoutBobberBlackSmokeIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberBlackSmokeIcon.png';
import ScoutBobberSagebrushIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberSagebrushIcon.png';
import ScoutBobberSilverIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberSilverIcon.png';
import ScoutBobberBlackMetallic from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberBlackMetallic.jpg';
import ScoutBobberBlackMetallicIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberBlackMetallicIcon.png';

import ScoutBobberBlueMetallic from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberBlueMetallic.jpg';
import ScoutBobberBlueMetallicIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberBlueMetallicIcon.png';

import ScoutBobberRedSmoke from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberRedSmoke.jpg';
import ScoutBobberRedSmokeIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobberRedSmokeIcon.png';

import ScoutRogueBlackSmoke from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueBlackSmoke.webp';
import ScoutRogueBlackSmokeIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueBlackSmokeIcon.png';
import ScoutRogueSagebrush from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueSagebrush.webp';
import ScoutRogueSagebrushIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueSagebrushIcon.png';
import ScoutRogueBlue from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueBlue.webp';
import ScoutRogueBlueIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueBlueIcon.png';
import ScoutRogueStealthGrayIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueStealthGrayIcon.png';
import ScoutRogueStealthGray from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueStealthGray.webp';
import ScoutRogueBlackMetallic from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueBlackMetallic.jpg';
import ScoutRogueBlackMetallicIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutRogueBlackMetallicIcon.png';
import ScoutBobber20Maroon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobber20Maroon.webp';
import ScoutBobber20MaroonIcon from '../../../Single Motorcycle/Scout Motorcycles/Scout Motorcycles Images/ScoutBobber20MaroonIcon.png';


const ScoutMTFDetails = [
    {
        id: 1,
        image: Scout,
        title: `Scout`,
        price: `15 490 €`,
        link: `/scout`,
        members: [
            {
                color: `Black Metallic`,
                price: `15 490 €`,
                icon: ScoutBlackMetallicIcon,
                image: ScoutBlackMetallic
            },
            {
                color: `Maroon Metallic`,
                price: `15 790 €`,
                icon: ScoutMaroonIcon,
                image: ScoutMaroon
            },
            {
                color: `Silver Quartz Metallic`,
                price: `15 990 €`,
                icon: ScoutSilverIcon,
                image: ScoutSilver
            },
            {
                color: `Spirit Blue Metallic / Black Metallic`,
                price: `15 990 €`,
                icon: ScoutSpiritBlueMetallicIcon,
                image: ScoutSpiritBlueMetallic
            },
        ]
    },
    {
        id: 2,
        image: ScoutBobber,
        title: `Scout Bobber`,
        price: `15 890 €`,
        link: `/scout-bobber`,
        members: [
            {
                color: `Black Metallic`,
                price: `15 890 €`,
                icon:  ScoutBobberBlackMetallicIcon,
                image: ScoutBobberBlackMetallic
            },
            {
                color: `Black Smoke`,
                price: `15 890 €`,
                icon:  ScoutBobberBlackSmokeIcon,
                image: ScoutBobberBlackSmoke
            },
            {
                color: `Springfield Blue Metallic`,
                price: `16 190 €`,
                icon:  ScoutBobberBlueMetallicIcon,
                image: ScoutBobberBlueMetallic
            },
            {
                color: `Sunset Red Smoke`,
                price: `16 190 €`,
                icon:  ScoutBobberRedSmokeIcon,
                image: ScoutBobberRedSmoke
            },
            {
                color: `Silver Quartz Smoke`,
                price: `16 490 €`,
                icon:  ScoutBobberSilverIcon,
                image: ScoutBobberSilver
            },
            {
                color: `Sagebrush Smoke`,
                price: `16 490 €`,
                icon:  ScoutBobberSagebrushIcon,
                image: ScoutBobberSagebrush
            }
        ]
    },
    {
        id: 3,
        image: ScoutRogue,
        title: `Scout Rogue`,
        price: `16 190 €`,
        link: `/scout-rogue`,
        members: [
            {
                color: `Black Metallic`,
                price: `16 190 €`,
                icon:  ScoutRogueBlackMetallicIcon,
                image: ScoutRogueBlackMetallic
            },
            {
                color: `Black Smoke`,
                price: `16 190 €`,
                icon:  ScoutRogueBlackSmokeIcon,
                image: ScoutRogueBlackSmoke
            },
            {
                color: `Sagebrush Smoke`,
                price: `16 490 €`,
                icon:  ScoutRogueSagebrushIcon,
                image: ScoutRogueSagebrush
            },
            {
                color: `Storm Blue`,
                price: `16 490 €`,
                icon:  ScoutRogueBlueIcon,
                image: ScoutRogueBlue
            },
            {
                color: `Stealth Gray`,
                price: `16 790 €`,
                icon:  ScoutRogueStealthGrayIcon,
                image: ScoutRogueStealthGray
            },
        ]
    },
    {
        id: 4,
        image: ScoutBobberTwenty,
        title: `Scout Bobber Twenty`,
        price: `16 690 €`,
        link: `/scout-bobber-twenty`,
        members: [
            {
                color: `Maroon Metallic`,
                price: `16 690 €`,
                icon: ScoutBobber20MaroonIcon,
                image: ScoutBobber20Maroon
            },
        ]
    },
]

export default ScoutMTFDetails;