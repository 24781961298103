import footerlogo from "../Main Images/logo-footer.webp";
import footerscout from "../Main Images/scout-footer.webp";
import footerlogobottom from '../Main Images/indiamotorcycle-logo-bottom.webp';
import "../Main Style/footer.css";
import { AiFillFacebook, AiOutlineInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ApparelLink, InstagramLink } from "../Constants/AllConstants";

const Footer = () => {


  return (
    <footer className="footer">
      <div className="footer_container">
        <div className="footer_wrapper">
          <img src={footerlogo} alt="" />
          <img src={footerscout} alt="" />
        </div>
            <div className="footer_social">
                <AiFillFacebook/>
                <Link to={InstagramLink}><AiOutlineInstagram/></Link>
            </div>
            <div className="footer_copyright">
                <p>© 2023 Indian Motorcycle Macedonia - *сите права се задржани</p>
            </div>
      </div>
      <div className="footer_fixed_position">
            <div className="footer_fixed_container">
            <ul className="footer_normal_showed">
                   <li>
                    <div class="btn-group dropup">
                      <li type="button" class="dropdown-toggle-dropup" data-bs-toggle="dropdown" aria-expanded="false">
                        Модели
                      </li>
                      <ul class="dropdown-menu dropdown_footer">
                        <li><Link to="/scout-family">SCOUT 1133CC</Link></li>
                        <li><Link to="/ftr-family">FTR</Link></li>
                        <li><Link to="/cruiser-family">CRUISER</Link></li>
                        <li><Link to="/bagger-family">BAGGER</Link></li>
                        <li><Link to="/touring-family">TOURING</Link></li>
                        <li><Link to="/dark-horse-family">DARK HORSE</Link></li>
                        <li><Link to="/elite-family">ELITE</Link></li>
                      </ul>
                    </div>
                   </li>
                   <li>
                    <div class="btn-group dropup">
                      <li type="button" class="dropdown-toggle-dropup" data-bs-toggle="dropdown" aria-expanded="false">
                        Заедница
                      </li>
                      <ul class="dropdown-menu dropdown_footer">
                        <li><Link to='/flat-track-race/racing'>INDIAN MOTORCYCLE RACING</Link></li>
                        <li><Link to='/history'>Историја</Link></li>
                        <li><Link to='/find-a-dealer/map-view'>локации</Link></li>
                        <li><Link to='/about-us'>За нас</Link></li>
                        <li><Link to='/contact-us'>Контакт</Link></li>
                      </ul>
                    </div>
                   </li>
                   <li>
                    <div class="btn-group dropup">
                      <li type="button" class="dropdown-toggle-dropup" data-bs-toggle="dropdown" aria-expanded="false">
                        Продавници
                      </li>
                      <ul class="dropdown-menu dropdown_footer">
                        <li><Link to={ApparelLink}>Облека</Link></li>
                        <li><Link to="https://www.indianmotorcycle.com/en-001/build-model/">Додатоци</Link></li>
                        <li><Link to="https://instructions.indianmotorcycle.com/">Инструкции</Link></li>
                      </ul>
                    </div>
                   </li>
                   <li>
                    <div class="btn-group dropup">
                      <li type="button" class="dropdown-toggle-dropup" data-bs-toggle="dropdown" aria-expanded="false">
                        Медиуми
                      </li>
                      <ul class="dropdown-menu dropdown_footer">
                        <li><Link to="">FACEBOOK</Link></li>
                        <li><Link to={InstagramLink}>INSTAGRAM</Link></li>
                      </ul>
                    </div>
                   </li>
                </ul>

                

                <ul className="mobile_footer_hidden">
                      <ul>
                        <details>
                          <summary><li>Модели</li></summary>
                          <li  className="mobile_inner"><Link to="/scout-family">SCOUT</Link></li>
                          <li  className="mobile_inner"><Link to="/ftr-family">FTR</Link></li>
                          <li className="mobile_inner"><Link to="/cruiser-family">CRUISER</Link></li>
                          <li className="mobile_inner"><Link to="/bagger-family">BAGGER</Link></li>
                          <li className="mobile_inner"><Link to="/touring-family">TOURING</Link></li>
                          <li className="mobile_inner"><Link to="/dark-horse-family">DARK HORSE</Link></li>
                        </details>
                      </ul>

                      <ul>
                        <details>
                          <summary><li>Заедница</li></summary>
                          <li className="mobile_inner"><Link to='/flat-track-race/racing'>INDIAN MOTORCYCLE RACING</Link></li>
                          <li className="mobile_inner"><Link to='/history'>Историја</Link></li>
                          <li className="mobile_inner"><Link to='/find-a-dealer/map-view'>Локации</Link></li>
                          <li className="mobile_inner"><Link to='/about-us'>За Нас</Link></li>
                          <li className="mobile_inner"><Link to='/contact-us'>Контакт</Link></li>
                        </details>
                      </ul>

                      <ul>
                        <details>
                          <summary><li>Продавници</li></summary>
                          <li className="mobile_inner"><Link to="https://shop-indianmotorcycle.com/ie/apparel.html">Облека</Link></li>
                          <li className="mobile_inner"><Link to="https://www.indianmotorcycle.com/en-001/build-model/">Додатоци</Link></li>
                          <li className="mobile_inner"><Link to="https://instructions.indianmotorcycle.com/">Инструкции</Link></li>
                        </details>
                      </ul>

                      <ul>
                        <details>
                          <summary><li>Медиуми</li></summary>
                          <li className="mobile_inner"><Link to="https://www.facebook.com/IndianMotorcycleRomania/">FACEBOOK</Link></li>
                          <li className="mobile_inner"><Link to="https://www.instagram.com/indianmotorcycles.mk/">INSTAGRAM</Link></li>
                        </details>
                      </ul>
                </ul>
                <img src={footerlogobottom} alt="" className="logo_fixed_bottom"/>
            </div>
      </div>
    </footer>
  );
};

export default Footer;
