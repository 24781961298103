const SpecificationsList = [
    {
        id: 1,
        family: `FTR`,
        members: [
            {   
                id: 1.1,
                title: 'FTR R Carbon',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1203 cc",
                            "Димензии на клип": "102 мм x 73.6 мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6000 во минута",
                        "Максимален вртежен момент": "118 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Помошна и спроведна, мулти-плоча",
                        },
                        "Сооднос на менувачот": {
                            "1st": "12.270 : 1",
                            "2nd": "8.341 : 1",
                            "3rd": "6.646 : 1",
                            "4th": "5.639 : 1",
                            "5th": "4.985 : 1",
                            "6th": "4.278 : 1",
                            "Заден пренос": "2.647:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Ohlins Cartridge - Целосно прилагодлива инвертирана телескопска",
                              "Задна Суспензија": "Ohlins Piggyback IFP 120мм -  Целосно прилагодлива"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Brembo Dual 320мм t5 Rotor - со 4 клипа калипер",
                            "Задна Запирачка": "Brembo Single 260мм t5 Rotor - со 2 клипа калипер",
                            "Предни гуми": "Metzeler Sportec 120/70ZR17 58W",
                            "Задни гуми": "Metzeler Sportec 180/55ZR17 73W",
                            "Тркала": "Излеани црни Indian Motorcycle црвени шипки - 17 инчи x 3,5 инчи / 17 инчи х 5 инчи",
                            "Ауспух": "2-во-1, Катализатор во колектор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1525 мм",
                            "Висина на седиште": "780 мм",
                            "Растојание од земјата": "165 мм",
                            "Вкупна должина": "2223 мм",
                            "Вкупна ширина": "830 мм",
                            "Вкупна висина": "1295 мм",
                            "Наклонетост": "45°",
                            "Закосеност": "25.3°",
                            "Предница": "99.9 мм",
                            "Капацитет на гориво": "13.0Л",
                            "Вкупна тежина": "430 кг",
                            "Тежина (празен резервоар / полн резервоар)": "221 кг / 235 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Режими на возење (Rain, Standard, Sport), Lean Angle Sensitive, контрола на стабилност, контрола на тракција, контрола на тркалата со ублажување на задниот, USB приклучок за полнење, темпомат",
                            "Светла": "ЛЕД фарови, задни светла, трепкачи",
                        },
                        "Електрика": {
                            "Мерачи": "N/A",
                            "Друго": `4" екран на допир подржан од RIDE COMMAND`
                        }
                    }
                ]
            },
            {   
                id: 1.2,
                title: 'FTR Rally',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1203 cc",
                            "Димензии на клип": "102 мм x 73.6 мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6000 во минута",
                        "Максимален вртежен момент": "118 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Помошна и спроведна, мулти-плоча",
                        },
                        "Сооднос на менувачот": {
                            "1st": "13.361:1",
                            "2nd": "9.082:1",
                            "3rd": "7.237:1",
                            "4th": "6.141:1",
                            "5th": "5.428:1",
                            "6th": "4.658:1",
                            "Заден пренос": "2.882:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Cartridge - инвертирана телескопска вилушка",
                              "Задна Суспензија": "ZF Sach Piggyback IFP - целосно прилагодлива"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Brembo Dual 320мм t5 Rotor - со 4 клипа калипер",
                            "Задна Запирачка": "Brembo Single 260мм t5 Rotor - со 2 клипа калипер",
                            "Предни гуми": "Pirelli Scorpion Rally STR 120/70R19 M/C 60V M+S",
                            "Задни гуми": "Pirelli Scorpion Rally STR 150/70R18 M/C 70V M+S",
                            "Тркала": "Жичани (19 инчи x 3 инчи / 18 инчи x 4,25 инчи)",
                            "Ауспух": "2-во-1, Катализатор во колектор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1524 мм",
                            "Висина на седиште": "815 мм",
                            "Растојание од земјата": "183 мм",
                            "Вкупна должина": "2286мм",
                            "Вкупна ширина": "867 мм",
                            "Вкупна висина": "1369 мм",
                            "Наклонетост": "43°",
                            "Закосеност": "26.3°",
                            "Предница": "130мм",
                            "Капацитет на гориво": "12.9 Л",
                            "Вкупна тежина": "430кг",
                            "Тежина (празен резервоар / полн резервоар)": "228 кг / 242 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "ABS, темпомат, жични тркала",
                            "Светла": "ЛЕД фарови, задни светла, трепкачи",
                        },
                        "Електрика": {
                            "Мерачи": "N/A",
                            "Друго": "4 екран на допир подржан од RIDE COMMAND"
                        }
                    }
                ]
            },
            {   
                id: 1.3,
                title: 'FTR Sport',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1203 cc",
                            "Димензии на клип": "102 мм x 73.6 мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6000 во минута",
                        "Максимален вртежен момент": "118 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Помошна и спроведна, мулти-плоча",
                        },
                        "Сооднос на менувачот": {
                            "1st": "12.270:1",
                            "2nd": "8.341 : 1",
                            "3rd": "6.646 : 1",
                            "4th": "5.639 : 1",
                            "5th": "4.985 : 1",
                            "6th": "4.278 : 1",
                            "Заден пренос": "2.647:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "ZF Sach - целосно прилагодлива инвертирана телескопска вилушка",
                              "Задна Суспензија": "ZF Sach Piggyback IFP - целосно прилагодлива / 120мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Brembo Dual 320мм t5 Rotor - со 4 клипа калипер",
                            "Задна Запирачка": "Brembo Single 260мм t5 Rotor - со 2 клипа калипер",
                            "Предни гуми": "Metzeler Sportec 120/70ZR17 58W",
                            "Задни гуми": "Metzeler Sportec 180/55ZR17 73W",
                            "Тркала": "Црни со инди-црвена шипка (17 инчи x 3,5 инчи / 17 инчи x 5 инчи)",
                            "Ауспух": "2-во-1, Катализатор во колектор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1525 мм",
                            "Висина на седиште": "780 мм",
                            "Растојание од земјата": "165 мм",
                            "Вкупна должина": "2223 мм",
                            "Вкупна ширина": "830 мм",
                            "Вкупна висина": "1295 мм",
                            "Наклонетост": "45°",
                            "Закосеност": "25,3°",
                            "Предница": "99.9 мм",
                            "Капацитет на гориво": "13.0Л",
                            "Вкупна тежина": "430кг",
                            "Тежина (празен резервоар / полн резервоар)": "223 кг / 237 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Режими на возење (Rain, Standard, Sport),ABS, контрола на стабилност, контрола на тракција, ублажување на кревање на тркала, USB приклучок за полнење, темпомат",
                            "Светла": "ЛЕД фарови, задни светла, трепкачи",
                        },
                        "Електрика": {
                            "Мерачи": "N/A",
                            "Друго": `4" екран на допир подржан од RIDE COMMAND`
                        }
                    }
                ]
            },
            {   
                id: 1.4,
                title: 'FTR',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1203 cc",
                            "Димензии на клип": "102 мм x 73.6 мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6000 во минута",
                        "Максимален вртежен момент": "118 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Помошна и спроведна, мулти-плоча",
                        },
                        "Сооднос на менувачот": {
                            "1st": "12.270:1",
                            "2nd": "8.341 : 1",
                            "3rd": "6.646 : 1",
                            "4th": "5.639 : 1",
                            "5th": "4.985 : 1",
                            "6th": "4.278 : 1",
                            "Заден пренос": "2.647:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "ZF Sach - целосно прилагодлива инвертирана телескопска вилушка",
                              "Задна Суспензија": "ZF Sach Piggyback IFP - целосно прилагодлива / 120мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Brembo Dual 320мм t5 Rotor - со 4 клипа калипер",
                            "Задна Запирачка": "Brembo Single 260мм t5 Rotor - со 2 клипа калипер",
                            "Предни гуми": "Metzeler Sportec 120/70ZR17 58W",
                            "Задни гуми": "Metzeler Sportec 180/55ZR17 73W",
                            "Тркала": "Црни со инди-црвена шипка (17 инчи x 3,5 инчи / 17 инчи x 5 инчи)",
                            "Ауспух": "2-во-1, Катализатор во колектор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1525 мм",
                            "Висина на седиште": "780 мм",
                            "Растојание од земјата": "165 мм",
                            "Вкупна должина": "2223 мм",
                            "Вкупна ширина": "830 мм",
                            "Вкупна висина": "1295 мм",
                            "Наклонетост": "45°",
                            "Закосеност": "25,3°",
                            "Предница": "99.9 мм",
                            "Капацитет на гориво": "13.0Л",
                            "Вкупна тежина": "430кг",
                            "Тежина (празен резервоар / полн резервоар)": "219 кг / 233 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "ABS, Темпомат",
                            "Светла": "ЛЕД фарови, задни светла, трепкачи",
                        },
                        "Електрика": {
                            "Мерачи": `4" аналоген, дигитален тахометар, километража, мерач на патување, температура на моторот, светилка за ниско ниво на гориво`,
                        }
                    }
                ]
            },
            {   
                id: 1.5,
                title: 'FTR X RSD Super Hooligan',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1203 cc",
                            "Димензии на клип": "102 мм x 73.6 мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6000 во минута",
                        "Максимален вртежен момент": "120 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Помошна и спроведна, мулти-плоча",
                        },
                        "Сооднос на менувачот": {
                            "1st": "12.270:1",
                            "2nd": "8.341 : 1",
                            "3rd": "6.646 : 1",
                            "4th": "5.639 : 1",
                            "5th": "4.985 : 1",
                            "6th": "4.278 : 1",
                            "Заден пренос": "2.647:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Black Ohlins - целосно прилагодлива инвертирана телескопска вилушка / 120мм",
                              "Задна Суспензија": "Black Ohlins Piggyback IFP - целосно прилагодлива / 120мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Brembo Dual 320мм t5 Rotor - со 4 клипа калипер",
                            "Задна Запирачка": "Brembo Single 260мм t5 Rotor - со 2 клипа калипер",
                            "Предни гуми": "Metzeler Sportec 120/70ZR17 58W",
                            "Задни гуми": "Metzeler Sportec 180/55ZR17 73W",
                            "Тркала": "Црни со инди-црвена шипка (17 инчи x 3,5 инчи / 17 инчи x 5 инчи)",
                            "Ауспух": "2-во-1, Black Akrapovič"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1524 мм",
                            "Висина на седиште": "805 мм",
                            "Растојание од земјата": "165 мм",
                            "Вкупна должина": "2223 мм",
                            "Вкупна ширина": "830 мм",
                            "Вкупна висина": "1295 мм",
                            "Наклонетост": "43°",
                            "Закосеност": "25,3°",
                            "Предница": "99.9 мм",
                            "Капацитет на гориво": "12.9Л",
                            "Вкупна тежина": "430кг",
                            "Тежина (празен резервоар / полн резервоар)": "221 кг / 235 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "ABS, Темпомат, Контрола за стабилност, Систем против пролизгување",
                            "Светла": "ЛЕД фарови, задни светла, трепкачи",
                        },
                        "Електрика": {
                            "Мерачи": `4" аналоген, дигитален тахометар, километража, мерач на патување, температура на моторот, светилка за ниско ниво на гориво`,
                        }
                    }
                ]
            },
            {   
                id: 1.6,
                title: 'FTR X 100% R CARBON',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1203 cc",
                            "Димензии на клип": "102 мм x 73.6 мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6000 во минута",
                        "Максимален вртежен момент": "120 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Помошна и спроведна, мулти-плоча",
                        },
                        "Сооднос на менувачот": {
                            "1st": "12.270:1",
                            "2nd": "8.341 : 1",
                            "3rd": "6.646 : 1",
                            "4th": "5.639 : 1",
                            "5th": "4.985 : 1",
                            "6th": "4.278 : 1",
                            "Заден пренос": "2.647:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Black Ohlins - целосно прилагодлива инвертирана телескопска вилушка / 120мм",
                              "Задна Суспензија": "Black Ohlins Piggyback IFP - целосно прилагодлива / 120мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Brembo Dual 320мм t5 Rotor - со 4 клипа калипер",
                            "Задна Запирачка": "Brembo Single 260мм t5 Rotor - со 2 клипа калипер",
                            "Предни гуми": "Metzeler Sportec 120/70ZR17 58W",
                            "Задни гуми": "Metzeler Sportec 180/55ZR17 73W",
                            "Тркала": "Црни со инди-црвена шипка (17 инчи x 3,5 инчи / 17 инчи x 5 инчи)",
                            "Ауспух": "2-во-1, Black Akrapovič"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1524 мм",
                            "Висина на седиште": "805 мм",
                            "Растојание од земјата": "165 мм",
                            "Вкупна должина": "2223 мм",
                            "Вкупна ширина": "830 мм",
                            "Вкупна висина": "1295 мм",
                            "Наклонетост": "43°",
                            "Закосеност": "25,3°",
                            "Предница": "99.9 мм",
                            "Капацитет на гориво": "12.9Л",
                            "Вкупна тежина": "430кг",
                            "Тежина (празен резервоар / полн резервоар)": "221 кг / 235 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "ABS, Темпомат, Контрола за стабилност, Систем против пролизгување",
                            "Светла": "ЛЕД фарови, задни светла, трепкачи",
                        },
                        "Електрика": {
                            "Мерачи": `4" аналоген, дигитален тахометар, километража, мерач на патување, температура на моторот, светилка за ниско ниво на гориво`,
                        }
                    }
                ]
            },
        ]
    },
    {
        id: 2,
        family: `Scout`,
        members: [
            {   
                id: 2.1,
                title: 'Scout Bobber',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1133 cc",
                            "Димензии на клип": "99мм x 73.6мм",
                            "Сооднос на компресија": "10.7:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "5600 во минута",
                        "Максимален вртежен момент": "97 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.926:1",
                            "2nd": "7.427:1",
                            "3rd": "5.918:1",
                            "4th": "5.022:1",
                            "5th": "4.439:1",
                            "6th": "3.810:1",
                            "Заден пренос": "2.357:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери / 51 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Pirelli MT60RS 130/90B16 67H",
                            "Задни гуми": "Pirelli MT60RS 150/80B16 77H",
                            "Тркала": `Излеани црни 16" x 3.5" / 16" x 3.5"`,
                            "Ауспух": "Поделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1576 мм",
                            "Висина на седиште": "649 мм",
                            "Растојание од земјата": "129 мм",
                            "Вкупна должина": "2274 мм",
                            "Вкупна ширина": "926 мм",
                            "Вкупна висина": "1053 мм",
                            "Наклонетост": "29°",
                            "Закосеност": "29°",
                            "Предница": "120 мм",
                            "Капацитет на гориво": "12.5 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "243кг / 252кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "V-Twin мотор со течно ладење од 1133cc, затемнет стил, соло седиште, предна суспензија со вилушка со патрон, USB приклучок за полнење, електронско вбризгување гориво",
                        },
                        "Електрика": {
                            "Мерачи": "Дигитален брзомерач со дигитален тахометар, одометар, мерач на патување, температура на моторот, лампа за низок капацитет на гориво",
                        }
                    }
                ]
            },
            {   
                id: 2.2,
                title: 'Scout',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1133 cc",
                            "Димензии на клип": "99мм x 73.6мм",
                            "Сооднос на компресија": "10.7:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "5600 во минута",
                        "Максимален вртежен момент": "97 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.926:1",
                            "2nd": "7.427:1",
                            "3rd": "5.918:1",
                            "4th": "5.022:1",
                            "5th": "4.439:1",
                            "6th": "3.810:1",
                            "Заден пренос": "2.357:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери/76 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Pirelli Night Dragon 130/90B16 67H",
                            "Задни гуми": "Pirelli Night Dragon 150/80B16 77H",
                            "Тркала": `Контрастен рез 16" x 3.5" / 16" x 3.5"`,
                            "Ауспух": "Поделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1575 мм",
                            "Висина на седиште": "649мм",
                            "Растојание од земјата": "146 мм",
                            "Вкупна должина": "2324 мм",
                            "Вкупна ширина": "916 мм",
                            "Вкупна висина": "1068 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "29°",
                            "Предница": "120 мм",
                            "Капацитет на гориво": "12.5 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "247кг / 256кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "V-Twin мотор со течно ладење од 1133сс, хромирани завршници, спортско седиште за двајца патници, USB приклучок за полнење, електронско вбризгување гориво",
                        },
                        "Електрика": {
                            "Мерачи": "Дигитален брзомерач со дигитален тахометар, одометар, мерач на патување, температура на моторот, лампа за низок капацитет на гориво",
                        }
                    }
                ]
            },
            {   
                id: 2.3,
                title: 'Scout Rogue',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење (60 степени)",
                            "Запремнина на мотор": "1133 cc",
                            "Димензии на клип": "99мм x 73.6мм",
                            "Сооднос на компресија": "10.7:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "5600 во минута",
                        "Максимален вртежен момент": "97 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.926:1",
                            "2nd": "7.427:1",
                            "3rd": "5.918:1",
                            "4th": "5.022:1",
                            "5th": "4.439:1",
                            "6th": "3.810:1",
                            "Заден пренос": "2.357:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери/76 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 61H",
                            "Задни гуми": "Metzeler Cruisetec 150/80B16 77H",
                            "Тркала": `Излеани Црни 19 инчи x 3.5 инчи - Излеани Црни 16 инчи x 3.5 инчи.`,
                            "Ауспух": "Поделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1576 мм",
                            "Висина на седиште": "649мм",
                            "Растојание од земјата": "129 мм",
                            "Вкупна должина": "2274 мм",
                            "Вкупна ширина": "995 мм",
                            "Вкупна висина": "1181 мм",
                            "Наклонетост": "29°",
                            "Закосеност": "29°",
                            "Предница": "120 мм",
                            "Капацитет на гориво": "12.5 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "241 кг / 250 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "N/A",
                            "Светла": "Предни светла, задни/сопирачки светла, трепкачи, светла за регистарски таблички, брзинометар и индикаторски светла"
                        },
                        "Електрика": {
                            "Мерачи": "Дигитален тахометар, километража, мерач на патување, температура на моторот, светилка за низок капацитет на гориво",
                        }
                    }
                ]
            },
            {   
                id: 2.4,
                title: 'Scout Bobber Twenty',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1133 cc",
                            "Димензии на клип": "99мм x 73.6мм",
                            "Сооднос на компресија": "10.7:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 60 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "5600 во минута",
                        "Максимален вртежен момент": "97 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.926:1",
                            "2nd": "7.427:1",
                            "3rd": "5.918:1",
                            "4th": "5.022:1",
                            "5th": "4.439:1",
                            "6th": "3.810:1",
                            "Заден пренос": "2.357:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери / 51 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Pirelli MT60RS 130/90B16 67H",
                            "Задни гуми": "Pirelli MT60RS 150/80B16 77H",
                            "Тркала": `Црни жичани 16" x 3.5" / црни жичани 16" x 3.5"`,
                            "Ауспух": "Разделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1576 мм",
                            "Висина на седиште": "695мм",
                            "Растојание од земјата": "129 мм",
                            "Вкупна должина": "2274 мм",
                            "Вкупна ширина": "995 мм",
                            "Вкупна висина": "1181 мм",
                            "Наклонетост": "29°",
                            "Закосеност": "29°",
                            "Предница": "120 мм",
                            "Капацитет на гориво": "12.5 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "251 кг / 260 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "V-twin мотор со течно ладење од 1133сс, стилска опрема со хром, предна суспензија со вилушка на касети, USB приклучок за полнење, електронско вбризгување гориво",
                        },
                        "Електрика": {
                            "Мерачи": "Дигитален брзомерач со дигитален тахометар, одометар, мерач на патување, температура на моторот, лампа за низок капацитет на гориво",
                        }
                    }
                ]
            },
        ]
    },
    {
        id: 3,
        family: `Cruiser`,
        members: [
            {   
                id: 3.1,
                title: 'Chief Dark Horse',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3200 во минута",
                        "Максимален вртежен момент": "162 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 132 мм",
                              "Задна Суспензија": "Двојни амортизери со прилагодлив предоптовар / 75 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 300мм Полулебдечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300мм Полулебдечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Pirelli Night Dragon 130/60B19 61H",
                            "Задни гуми": "Pirelli Night Dragon 180/65 B16 81H",
                            "Тркала": ` Истакнати излеани црни 19" x 3.5" / 16" x 5"`,
                            "Ауспух": "Двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1626 мм",
                            "Висина на седиште": "662 мм",
                            "Растојание од земјата": "125 мм",
                            "Вкупна должина": "2286 мм",
                            "Вкупна ширина": "922 мм",
                            "Вкупна висина": "1253 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "29°",
                            "Предница": "131.5 мм",
                            "Капацитет на гориво": "15.1 Л",
                            "Вкупна тежина": "522 кг",
                            "Тежина (празен резервоар / полн резервоар)": "294 кг / 304 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "ABS, режими на возење, палење без клуч, USB порта за полнење, 12V порта за полнењe, исклучување на задниот цилиндар",
                            "Светла": "ЛЕД предни светла, ЛЕД задни/сопирачки/трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Брзомерач, тахометар, одометар, мерач на гориво, компас, амбиентална температура, индикатор за менувачот",
                            "Друго": `4" екран на допир подржан од RIDE COMMAND. Екранот вклучува приказ на; амбиентална температура на воздухот; приказ на аудио информации; мапа/навигација; Bluetooth статус за телефон и слушалки; статус на возилото (напон, часови на работа на моторот, замена на масло); информации за возилото (брзина, растојание на возилото, RPM, позиција на менувачот); тековни податоци за возењето (растојание, време на движење, време на застанување, височина, промена на височина); мерачи на патувањето; избор на режими на возење; осветленост на екранот; и читање на грешки на возилото.`
                        }
                    }
                ]
            },
            {   
                id: 3.2,
                title: 'Chief Bobber Dark Horse',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3200 во минута",
                        "Максимален вртежен момент": "162 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 132 мм",
                              "Задна Суспензија": "Двојни амортизери со прилагодлив предоптовар / 75 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 300мм Полулебдечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300мм Полулебдечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Pirelli Night Dragon 130/90B16 67H",
                            "Задни гуми": "Pirelli Night Dragon 180/65 B16 81H",
                            "Тркала": `Споени жици 16" x 3 / 16" x 5"`,
                            "Ауспух": "Двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1626 мм",
                            "Висина на седиште": "662 мм",
                            "Растојание од земјата": "125 мм",
                            "Вкупна должина": "2286 мм",
                            "Вкупна ширина": "915 мм",
                            "Вкупна висина": "1349 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "29°",
                            "Предница": "131.5 мм",
                            "Капацитет на гориво": "15.1 Л",
                            "Вкупна тежина": "522 кг",
                            "Тежина (празен резервоар / полн резервоар)": "304 кг / 315 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "ABS, режими на возење, палење без клуч, USB порта за полнење, 12V порта за полнењe, исклучување на задниот цилиндар",
                            "Светла": "ЛЕД предни светла, ЛЕД задни/сопирачки/трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Брзомерач, тахометар, одометар, мерач на гориво, компас, амбиентална температура, индикатор за менувачот",
                            "Друго": `4" екран на допир подржан од RIDE COMMAND. Екранот вклучува приказ на; амбиентална температура на воздухот; приказ на аудио информации; мапа/навигација; Bluetooth статус за телефон и слушалки; статус на возилото (напон, часови на работа на моторот, замена на масло); информации за возилото (брзина, растојание на возилото, RPM, позиција на менувачот); тековни податоци за возењето (растојание, време на движење, време на застанување, височина, промена на височина); мерачи на патувањето; избор на режими на возење; осветленост на екранот; и читање на грешки на возилото.`
                        }
                    }
                ]
            },
            {   
                id: 3.3,
                title: 'Sport Chief',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116 - со воздушно ладење",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3200 во минута",
                        "Максимален вртежен момент": "162 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "KYB® Превртена телескопска вилушка - 130 мм",
                              "Задна Суспензија": "Двојни Fox® Piggyback амортизери - 100 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двојни Brembo® / 320ммполулебдечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300мм Полулебдечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Pirelli® Night Dragon 130/60B19 61H",
                            "Задни гуми": "Pirelli® Night Dragon 180/65 B16 81H",
                            "Тркала": `Спортски излеани црни 19" x 3.5" - Спортски излеани црни 16" x 5"`,
                            "Ауспух": "Двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1640 мм",
                            "Висина на седиште": "686 мм",
                            "Растојание од земјата": "149 мм",
                            "Вкупна должина": "2301 мм",
                            "Вкупна ширина": "842 мм",
                            "Вкупна висина": "1270 мм",
                            "Наклонетост": "29.5°",
                            "Закосеност": "28°",
                            "Предница": "111 мм",
                            "Капацитет на гориво": "15.1 Л",
                            "Вкупна тежина": "526 кг",
                            "Тежина (празен резервоар / полн резервоар)": "302 кг / 311 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "ABS, режими на возење, палење без клуч, USB порта за полнење, 12V порта за полнењe, исклучување на задниот цилиндар",
                            "Светла": "ЛЕД предни светла, ЛЕД задни/сопирачки/трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Брзомерач, тахометар, одометар, мерач на гориво, компас, амбиентална температура, индикатор за менувачот",
                            "Друго": `4-инчен екран на допир подржан од RIDE COMMAND. Екранот на допир вклучува часовник; температура на амбиентниот воздух; приказ на аудио информации; мапа/навигација; Bluetooth статус за телефон и слушалки; статус на возилото (напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција на менувачот); тековни податоци за возење (растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; и отчитување на кодот за проблеми со возилото.`
                        }
                    }
                ]
            },
            {   
                id: 3.4,
                title: 'Super Chief Limited',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3200 во минута",
                        "Максимален вртежен момент": "162 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 132 мм",
                              "Задна Суспензија": "Двојни амортизери со прилагодлив предоптовар / 75 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 300мм Полулебдечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300мм Полулебдечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Pirelli Night Dragon 130/90B16 67H",
                            "Задни гуми": "Pirelli Night Dragon 180/65 B16 81H",
                            "Тркала": `Споени жици 16" x 3 / 16" x 5"`,
                            "Ауспух": "Двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1626 мм",
                            "Висина на седиште": "665 мм",
                            "Растојание од земјата": "125 мм",
                            "Вкупна должина": "2286 мм",
                            "Вкупна ширина": "1079 мм",
                            "Вкупна висина": "1203 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "29°",
                            "Предница": "131.5 мм",
                            "Капацитет на гориво": "15.1 Л",
                            "Вкупна тежина": "522 кг",
                            "Тежина (празен резервоар / полн резервоар)": "324 кг / 335 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "ABS, режими на возење, палење без клуч, USB порта за полнење, 12V порта за полнењe, исклучување на задниот цилиндар",
                            "Светла": "ЛЕД предни светла, ЛЕД задни/сопирачки/трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Брзомерач, тахометар, одометар, мерач на гориво, компас, амбиентална температура, индикатор за менувачот",
                            "Друго": `4" екран на допир подржан од RIDE COMMAND. Екранот вклучува приказ на; амбиентална температура на воздухот; приказ на аудио информации; мапа/навигација; Bluetooth статус за телефон и слушалки; статус на возилото (напон, часови на работа на моторот, замена на масло); информации за возилото (брзина, растојание на возилото, RPM, позиција на менувачот); тековни податоци за возењето (растојание, време на движење, време на застанување, височина, промена на височина); мерачи на патувањето; избор на режими на возење; осветленост на екранот; и читање на грешки на возилото.`
                        }
                    }
                ]
            },
        ]
    },
    {
        id: 4,
        family: `Bagger`,
        members: [
            {   
                id: 4.1,
                title: 'Indian Springfield',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3200 во минута",
                        "Максимален вртежен момент": "171 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 119 мм",
                              "Задна Суспензија": "Единечен амортизер со прилагодување на воздух / 114мм / 45.7мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двојни лебдечки ротори од 300 мм со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300 мм Пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/80B17 65H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `Истакнати Турер 17" x 3.5" / 16" x 5"`,
                            "Ауспух": "Разделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1701 мм",
                            "Висина на седиште": "660 мм",
                            "Растојание од земјата": "142 мм",
                            "Вкупна должина": "2583 мм",
                            "Вкупна ширина": "1080 мм",
                            "Вкупна висина": "1439 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "133 мм",
                            "Капацитет на гориво": "20.8 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "362 кг / 376 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Брзо отстранувачкo ветробранско стакло; Седиште од вистинска кожа; Цврсти багажници со далечинско заклучување, Предни заштитни шипки; Мониторинг на притисокот во гумите; Светлосна лента; Режими на возење, ABS; темпомат; Палење без клуч; Значка Indian®; Порта за полнење 12v",
                            "Светла": "ЛЕД предни светла, ЛЕД задни/сопирачки/трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "	Електронски брзомерач монтиран на резервоарот со одометар; двојни трипметри; дигитален тахометар; температура на амбиентниот воздух; опсег на гориво; просечна економичност на горивото; напон на батеријата; приказ на положбата на менувачот; часовник; читање на грешки на возилото; ниво на грејни ракавици (ако се инсталирани); низок притисок на моторното масло; и 9 ЛЕД индикатори: темпомат, долги светла, трепкачи, ABS, проверка на моторот и ознака на единицата MPH или km/h; електронски мерач на горивото монтиран на резервоарот со ЛЕД индикатор за ниското гориво",
                        }
                    }
                ]
            },
            {   
                id: 4.2,
                title: 'Indian Springfield Dark Horse',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3000 во минута",
                        "Максимален вртежен момент": "171 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 119 мм",
                              "Задна Суспензија": "Единечен амортизер со прилагодување на воздух / 114 мм / 63 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двојни / 300 мм пловечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300 мм Пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/80B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `10 крака со контрастен рез 19" x 3.5" / 16" x 5"`,
                            "Ауспух": "Разделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1701 мм",
                            "Висина на седиште": "650 мм",
                            "Растојание од земјата": "130 мм",
                            "Вкупна должина": "2522 мм",
                            "Вкупна ширина": "972 мм",
                            "Вкупна висина": "1261 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "133 мм",
                            "Капацитет на гориво": "20.8 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "344 кг / 358 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Далечинско заклучување на тврдите странични чанти; Седиште со винил Rogue; Надзор на притисокот на гумите; Светло бар; ABS, Изборни режими на возење; Контрола на круз; Палење без клуч",
                            "Светла": "ЛЕД предни светла, ЛЕД задни/сопирачки/трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Електронски брзомерач монтиран на резервоарот со одометар; двојни трипметри; дигитален тахометар; температура на амбиентниот воздух; опсег на гориво; просечна економичност на горивото; напон на батеријата; приказ на положбата на менувачот; часовник; читање на грешки на возилото; ниво на грејни ракавици (ако се инсталирани); низок притисок на моторното масло; и 9 ЛЕД индикатори: темпомат, долги светла, трепкачи, ABS, проверка на моторот и ознака на единицата MPH или km/h; електронски мерач на горивото монтиран на резервоарот со ЛЕД индикатор за ниското гориво",
                        }
                    }
                ]
            },
            {   
                id: 4.3,
                title: 'Chieftain Limited',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3000 во минута",
                        "Максимален вртежен момент": "171 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 119 мм",
                              "Задна Суспензија": "Единечен амортизер со прилагодување на воздух / 114 мм / 63 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двојни / 300 мм пловечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300 мм Пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `10 крака со контрастен рез 19" x 3.5" / 16" x 5"`,
                            "Ауспух": "Разделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "650 мм",
                            "Растојание од земјата": "130 мм",
                            "Вкупна должина": "2506 мм",
                            "Вкупна ширина": "1023 мм",
                            "Вкупна висина": "1372 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "150 мм",
                            "Капацитет на гориво": "20.8 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "359 кг / 373 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `7" екран на допир подржан од RIDE COMMAND со интеграција на Apple CarPlay; електрична шофершајбна; заштитни шипки; Седиште со винил Rogue; надзор на притисокот во гумите; Далечинско заклучување на тврдите странични чанти; режими на возење што може да се изберат; Исклучување на задниот цилиндар; ABS; темпомат, Аудио систем од 100W со AM/FM, Bluetooth, USB со приклучов за телефони; 12V порта за полнење.`,
                            "Светла": "ЛЕД фарови/задни/сопирачки/трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "	Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос",
                            "Друго": `7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник, температура на амбиентниот воздух; приказ на аудио информации; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција на степенот на пренос); тековни податоци во возење(растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`,
                        }
                    }
                ]
            },
            {   
                id: 4.4,
                title: 'Chieftain Dark Horse',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3000 во минута",
                        "Максимален вртежен момент": "171 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 119 мм",
                              "Задна Суспензија": "Единечен амортизер со прилагодување на воздух / 114 мм / 63 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двојни / 300 мм пловечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300 мм Пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `10 крака со контрастен рез 19" x 3.5" / 16" x 5"`,
                            "Ауспух": "Разделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "650 мм",
                            "Растојание од земјата": "130 мм",
                            "Вкупна должина": "2506 мм",
                            "Вкупна ширина": "1023 мм",
                            "Вкупна висина": "1372 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "150 мм",
                            "Капацитет на гориво": "20.8 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "359 кг / 373 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `7" екран на допир подржан од RIDE COMMAND со интеграција на Apple CarPlay; електрична шофершајбна; заштитни шипки; Седиште со винил Rogue; надзор на притисокот во гумите; Далечинско заклучување на тврдите странични чанти; режими на возење што може да се изберат; Исклучување на задниот цилиндар; ABS; темпомат, Аудио систем од 100W со AM/FM, Bluetooth, USB со приклучов за телефони; 12V порта за полнење.`,
                            "Светла": "ЛЕД фарови/задни/сопирачки/трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос",
                            "Друго": `	7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник, температура на амбиентниот воздух; приказ на аудио информации; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција за степенот на пренос); тековни податоци при возење (растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`,
                        }
                    }
                ]
            },
            {   
                id: 4.5,
                title: 'Indian Challenger Limited',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "PowerPlus",
                            "Запремнина на мотор": "1768 cc",
                            "Димензии на клип": "108мм x 96.5мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување гориво со затворена јамка / 52мм двоен отвор"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3800 во минута",
                        "Максимален вртежен момент": "178 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.169:1",
                            "2nd": "6.933:1",
                            "3rd": "5.151:1",
                            "4th": "4.105:1",
                            "5th": "3.508:1",
                            "6th": "3.017:1",
                            "Заден пренос": "2.379:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Превртена телескопска вилушка/ 130мм",
                              "Задна Суспензија": "Fox единечен амортизер со хидраулично прилагодување / 114мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двоен / 320мм полулебдечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 298мм пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `Спортски контрастен рез со црвена линија 19" x 3.5" / 16" x 5".`,
                            "Ауспух": "Раздвоен двоен ауспух со резонатор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "672 мм",
                            "Растојание од земјата": "137 мм",
                            "Вкупна должина": "2501 мм",
                            "Вкупна ширина": "990 мм",
                            "Вкупна висина": "1346 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "150 мм",
                            "Капацитет на гориво": "22.7 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "365 кг / 381 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `7" екран на допир подржан од RIDE COMMAND со навигација и интеграција на Apple CarPlay; Технологија Smart Lean; Режими на возење што може да се изберат: Заклучување на тврдите багажници: Brembo сопирачки: 81+ литри простор за складирање: ABS: Палење без клуч: Прилагодливи вентилациони отвори на фарингот; Електрично ветрило: Прилагодливи воздушни отвори; 12V порта за полнење: ЛЕД осветлување вклучувајќи го Pathfinder ЛЕД фарот.`,
                            "Светла": "ЛЕД осветлување, ЛЕД светилка на Pathfinder"
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос, 15 LED индикатори; овозможен темпомат, комплет сет за контрола на патувањето, долго светло, трепкачи, ABS, проверка на моторот, сензор за низок притисок во гумите, батерија, сензор за низско ниво на гориво, безбедносен систем, низок притисок на моторното масло и ознака на единицата MPH или km/h.",
                            "Друго": `7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник; температура на амбиентниот воздух; приказ на аудио информации; мапа/навигација; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на работа на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција на степенот за пренос); тековни податоци за возење (растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`,
                        }
                    }
                ]
            },
            {   
                id: 4.6,
                title: 'Indian Challenger Dark Horse',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "PowerPlus",
                            "Запремнина на мотор": "1768 cc",
                            "Димензии на клип": "108мм x 96.5мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување гориво со затворена јамка / 52мм двоен отвор"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3800 во минута",
                        "Максимален вртежен момент": "178 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.169:1",
                            "2nd": "6.933:1",
                            "3rd": "5.151:1",
                            "4th": "4.105:1",
                            "5th": "3.508:1",
                            "6th": "3.017:1",
                            "Заден пренос": "2.379:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Превртена телескопска вилушка/ 130мм",
                              "Задна Суспензија": "Fox единечен амортизер со хидраулично прилагодување / 114мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двоен / 320мм полулебдечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 298мм пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `Спортски контрастен рез со црвена линија 19" x 3.5" / 16" x 5".`,
                            "Ауспух": "Раздвоен двоен ауспух со резонатор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "672 мм",
                            "Растојание од земјата": "137 мм",
                            "Вкупна должина": "2501 мм",
                            "Вкупна ширина": "990 мм",
                            "Вкупна висина": "1346 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "150 мм",
                            "Капацитет на гориво": "22.7 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "361 кг / 377 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `7" екран на допир подржан од RIDE COMMAND со навигација и интеграција на Apple CarPlay; Smart Lean технологија; Режими на возење што може да се изберат: Заклучување на тврдите багажници: Brembo сопирачки: 81+ литри простор за складирање: ABS: палење без клуч: осветленост на шасијата: Прилагодливи вентилациони отвори; Електрична шофершајбна; 12V порта за полнење.`,
                            "Светла": "ЛЕД осветлување, ЛЕД светилка на Pathfinder"
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос, 15 LED индикатори; овозможен темпомат, комплет сет за контрола на патувањето, долго светло, трепкачи, ABS, проверка на моторот, сензор за низок притисок во гумите, батерија, сензор за низско ниво на гориво, безбедносен систем, низок притисок на моторното масло и ознака на единицата MPH или km/h.",
                            "Друго": `	7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник; температура на амбиентниот воздух; приказ на аудио информации; мапа/навигација; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција на степенот за пренос); тековни податоци за возење (растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`,
                        }
                    }
                ]
            },
        ]
    },
    {
        id: 5,
        family: `Touring`,
        members: [
            {   
                id: 5.1,
                title: 'Roadmaster',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3000 во минута",
                        "Максимален вртежен момент": "171 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 119 мм",
                              "Задна Суспензија": "Единечен амортизер со прилагодување на воздух / 114мм / 45.7мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двојни / 300 мм пловечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300 мм Пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/80B17 65H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `Истакнати Турер 17" x 3.5" / 16" x 5"`,
                            "Ауспух": "Разделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "673 мм",
                            "Растојание од земјата": "140 мм",
                            "Вкупна должина": "2656 мм",
                            "Вкупна ширина": "1022 мм",
                            "Вкупна висина": "1490 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "150 мм",
                            "Капацитет на гориво": "20.8 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "398 кг / 412 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `Екран од 7инчи подржан од RIDE COMMAND со интеграција на Apple CarPlay; електрична шофершајбна; светла за возење; Шипки за пат; винил седишта (со посебни контроли за греење на возачот/патникот); Греење на рачките; Прилагодливи подни даски за патници; 164 литри простор за складирање; надзор на притисокот на гумите; Далечинско заклучување на тврдите странични чанти и багажници; Избор на режими на возење; Исклучување на задниот цилиндар; ABS; Темпомат; палење без клуч; Аудио систем од 200W со AM/FM, Bluetooth, USB влез за телефони и Weatherband; 12V порта за полнење.`,
                            "Светла": "ЛЕД фарови / запирачки / трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос, 15 LED индикатори; овозможен темпомат, комплет сет за контрола на патувањето, долго светло, трепкачи, ABS, проверка на моторот, сензор за низок притисок во гумите, батерија, сензор за низско ниво на гориво, безбедносен систем, низок притисок на моторното масло и ознака на единицата MPH или km/h.",
                            "Друго" : `7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник, температура на амбиентниот воздух; приказ на аудио информации; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција на степенот на пренос); тековни податоци во возење(растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`
                        }
                    }
                ]
            },
            {   
                id: 5.2,
                title: 'Roadmaster Limited',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3000 во минута",
                        "Максимален вртежен момент": "171 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 119 мм",
                              "Задна Суспензија": "Единечен амортизер со прилагодување на воздух / 114мм / 45.7мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двојни / 300 мм пловечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300 мм Пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `10-Spoke Contrast Cut 19" x 3.5" / 16" x 5"`,
                            "Ауспух": "Разделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "673 мм",
                            "Растојание од земјата": "140 мм",
                            "Вкупна должина": "2593 мм",
                            "Вкупна ширина": "1022 мм",
                            "Вкупна висина": "1490 мм",
                            "Закосеност": "25°",
                            "Предница": "150 мм",
                            "Капацитет на гориво": "20.8 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "389 кг / 403 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `7" touchscreen powered by RIDE COMMAND with Apple CarPlay® integration; Power Windshield; Highway Bars; Vinyl Rogue Seats; Heated Grips; Adjustable Passenger Floorboards; 164 Litres of Storage; Tire Pressure Monitoring; Remote-locking Hard Saddlebags & Trunk; Selectable Ride Modes; Rear Cylinder Deactivation; ABS; Cruise Control; Keyless Ignition; 200 Watt Audio System with AM/FM, Bluetooth, USB, Smartphone Compatible Input; 12V Charging Port`,
                            "Светла": "ЛЕД фарови / запирачки / трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос, 15 LED индикатори; овозможен темпомат, комплет сет за контрола на патувањето, долго светло, трепкачи, ABS, проверка на моторот, сензор за низок притисок во гумите, батерија, сензор за низско ниво на гориво, безбедносен систем, низок притисок на моторното масло и ознака на единицата MPH или km/h. Tank mounted auxiliary analog fuel and voltage gauges.",
                            "Друго" : `7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник, температура на амбиентниот воздух; приказ на аудио информации; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција на степенот на пренос); тековни податоци во возење(растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`
                        }
                    }
                ]
            },
            {   
                id: 5.3,
                title: 'Roadmaster Dark Horse',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "Thunderstroke 116",
                            "Запремнина на мотор": "1890 cc",
                            "Димензии на клип": "103.2мм x 113мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување на гориво со затворена јамка / отвор од 54 mm"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3000 во минута",
                        "Максимален вртежен момент": "171 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "9.403 : 1",
                            "2nd": "6.411 : 1",
                            "3rd": "4.763 : 1",
                            "4th": "3.796 : 1",
                            "5th": "3.243 : 1",
                            "6th": "2.789 : 1",
                            "Заден пренос": "2.2:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка / 119 мм",
                              "Задна Суспензија": "Единечен амортизер со прилагодување на воздух / 114мм / 45.7мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двојни / 300 мм пловечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 300 мм Пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/80B17 65H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `Истакнати Турер 17" x 3.5" / 16" x 5"`,
                            "Ауспух": "Разделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "673 мм",
                            "Растојание од земјата": "140 мм",
                            "Вкупна должина": "2656 мм",
                            "Вкупна ширина": "1022 мм",
                            "Вкупна висина": "1490 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "150 мм",
                            "Капацитет на гориво": "20.8 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "398 кг / 412 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `Екран од 7инчи подржан од RIDE COMMAND со интеграција на Apple CarPlay; електрична шофершајбна; светла за возење; Шипки за пат; винил седишта (со посебни контроли за греење на возачот/патникот); Греење на рачките; Прилагодливи подни даски за патници; 164 литри простор за складирање; надзор на притисокот на гумите; Далечинско заклучување на тврдите странични чанти и багажници; Избор на режими на возење; Исклучување на задниот цилиндар; ABS; Темпомат; палење без клуч; Аудио систем од 200W со AM/FM, Bluetooth, USB влез за телефони и Weatherband; 12V порта за полнење.`,
                            "Светла": "ЛЕД фарови / запирачки / трепкачи"
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос, 15 LED индикатори; овозможен темпомат, комплет сет за контрола на патувањето, долго светло, трепкачи, ABS, проверка на моторот, сензор за низок притисок во гумите, батерија, сензор за низско ниво на гориво, безбедносен систем, низок притисок на моторното масло и ознака на единицата MPH или km/h.",
                            "Друго" : `7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник, температура на амбиентниот воздух; приказ на аудио информации; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција на степенот на пренос); тековни податоци во возење(растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`
                        }
                    }
                ]
            },
            {   
                id: 5.4,
                title: 'Indian Pursuit Limited',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "PowerPlus со течно ладење",
                            "Запремнина на мотор": "1768 cc",
                            "Димензии на клип": "108 мм x 96.5 мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување гориво со затворена јамка / 52мм двоен отвор"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3800 во минута",
                        "Максимален вртежен момент": "178 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.169 : 1",
                            "2nd": "6.933:1",
                            "3rd": "5.151:1",
                            "4th": "4.105:1",
                            "5th": "3.508:1",
                            "6th": "3.017:1",
                            "Заден пренос": "2.379:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Превртена телескопска вилушка / 130 мм",
                              "Задна Суспензија": "Fox единечен амортизер со хидраулично прилагодување со електронска контрола / 114мм."
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двоен / 320мм полулебдечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 298мм пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `Спортска контрастна резка 19 инчи x 3.5 инчи - Спортска контрастна резка 16 инчи x 5 инчи`,
                            "Ауспух": "Раздвоен двоен ауспух со резонатор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "672 мм",
                            "Растојание од земјата": "137 мм",
                            "Вкупна должина": "2609 мм",
                            "Вкупна ширина": "990 мм",
                            "Вкупна висина": "1444 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "50 мм",
                            "Капацитет на гориво": "22.7 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "400 кг / 416 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `Smart Lean технологија; Електронски прилагодливо предоптоварување на задната суспензија; Седиште со греење; Интегрирани светла за возење во долниот дел на фарингот; Простор за складирање во долниот дел на фарингот; 7" екран на допир подржан од Ride Command со интеграција на Apple CarPlay; Избори на режими на возење; Деактивирање на задниот цилиндар; Темпомат; Надзор на притисокот во гумите; Тврди седла и багажник со далечинско заклучување; 164 литри простор за складирање; приспособливи табли за патници; Енергетска шофершајбна; Прилагодливи отвори за проток на воздух; Загреани рачки; Аудио систем од 200W со AM/FM, Bluetooth, USB, влез компатибилен со паметен телефон, порта за полнење 12V`,
                            "Светла": "ЛЕД осветлување, ЛЕД светилки на Pathfinder, Интегрирани долни светла"
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос, 15 LED индикатори; овозможен темпомат, комплет сет за контрола на патувањето, долго светло, трепкачи, ABS, проверка на моторот, сензор за низок притисок во гумите, батерија, сензор за низско ниво на гориво, безбедносен систем, низок притисок на моторното масло и ознака на единицата MPH или km/h.",
                            "Друго" : `7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник, температура на амбиентниот воздух; приказ на аудио информации; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција за степенот на пренос); тековни податоци при возење (растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`
                        }
                    }
                ]
            },
            {   
                id: 5.5,
                title: 'Indian Pursuit Dark Horse',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "PowerPlus со течно ладење",
                            "Запремнина на мотор": "1768 cc",
                            "Димензии на клип": "108 мм x 96.5 мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување гориво со затворена јамка / 52мм двоен отвор"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3800 во минута",
                        "Максимален вртежен момент": "178 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.169 : 1",
                            "2nd": "6.933:1",
                            "3rd": "5.151:1",
                            "4th": "4.105:1",
                            "5th": "3.508:1",
                            "6th": "3.017:1",
                            "Заден пренос": "2.379:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Превртена телескопска вилушка / 130 мм",
                              "Задна Суспензија": "Fox единечен амортизер со хидраулично прилагодување со електронска контрола / 114мм."
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двоен / 320мм полулебдечки ротор / со 4 клипа калипер",
                            "Задна Запирачка": "Единечен / 298мм пловечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `Спортска контрастна резка 19 инчи x 3.5 инчи - Спортска контрастна резка 16 инчи x 5 инчи`,
                            "Ауспух": "Раздвоен двоен ауспух со резонатор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "672 мм",
                            "Растојание од земјата": "137 мм",
                            "Вкупна должина": "2609 мм",
                            "Вкупна ширина": "990 мм",
                            "Вкупна висина": "1444 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "50 мм",
                            "Капацитет на гориво": "22.7 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "400 кг / 416 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `Smart Lean технологија; Електронски прилагодливо предоптоварување на задната суспензија; Седиште со греење; Интегрирани возачки светла во долниот фрејинг; Складирање во долниот дел; 7" екран екран на допир подржан од Ride Command со интеграција на Apple CarPlay; Изборни режими на возење; Деактивирање на задниот цилиндар; Темпомат; Надзор на притисокот во гумите; Тврди седла и багажник со далечинско заклучување; 164 литри простор за складирање; шипки за автопат; приспособливи табли за патници; Електрична шофершајбна; Прилагодливи отвори за проток на воздух; Загреани рачки; Аудио систем од 200W со AM/FM, Bluetooth, USB, влез компатибилен со паметен телефон; порта за полнење 12V`,
                            "Светла": "ЛЕД осветлување, ЛЕД светилки на Pathfinder, Интегрирани долни светла"
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос, 15 LED индикатори; овозможен темпомат, комплет сет за контрола на патувањето, долго светло, трепкачи, ABS, проверка на моторот, сензор за низок притисок во гумите, батерија, сензор за низско ниво на гориво, безбедносен систем, низок притисок на моторното масло и ознака на единицата MPH или km/h.",
                            "Друго" : `7" екран на допир подржан од RIDE COMMAND: 7" екран на допир со часовник, температура на амбиентниот воздух; приказ на аудио информации; Bluetooth статус за телефон и слушалки; статус на возилото (притисок во гумите, напон, часови на моторот, промена на маслото); информации за возилото (брзина, опсег на гориво, вртежи во минута, позиција на степенот на пренос); тековни податоци во возење(растојание, време на движење, време на застанување, надморска височина, промена на височината); патни метри; избор на режим на возење; осветленост на екранот; отчитување на кодот за проблеми на возилото; Интеграција на Apple CarPlay.`
                        }
                    }
                ]
            },
            {   
                id: 5.6,
                title: 'Challenger Elite',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "PowerPlus со течно ладење",
                            "Запремнина на мотор": "1768 cc",
                            "Димензии на клип": "108 мм x 96.5 мм",
                            "Сооднос на компресија": "11.0:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување гориво со затворена јамка / 52мм двоен отвор"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "3800 во минута",
                        "Максимален вртежен момент": "178 Nm"
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна, помошна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.169 : 1",
                            "2nd": "6.933:1",
                            "3rd": "5.151:1",
                            "4th": "4.105:1",
                            "5th": "3.508:1",
                            "6th": "3.017:1",
                            "Заден пренос": "2.379:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Превртена телескопска вилушка",
                              "Задна Суспензија": `Fox единечен амортизер со електронски контролирано хидраулично прилагодување / 4.5"`
                        },
                        "Шасија": {
                            "Предна Запирачка": "Двоен / 320 мм полулебдечки ротор / со 4 клипа радијален калипер",
                            "Задна Запирачка": "Единечен / 298мм лебдечки ротор / со 2 клипа калипер",
                            "Предни гуми": "Metzeler Cruisetec 130/60B19 66H",
                            "Задни гуми": "Metzeler Cruisetec 180/60R16 80H",
                            "Тркала": `5 крака со прецизен рез 19 инчи x 3.5 инчи.`,
                            "Ауспух": "Раздвоен двоен ауспух со резонатор"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1668 мм",
                            "Висина на седиште": "672 мм",
                            "Растојание од земјата": "137 мм",
                            "Вкупна должина": "2501 мм",
                            "Вкупна ширина": "990 мм",
                            "Вкупна висина": "1429 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "25°",
                            "Предница": "150 мм",
                            "Капацитет на гориво": "22.7 Л",
                            "Вкупна тежина": "628 кг",
                            "Тежина (празен резервоар / полн резервоар)": "365 кг / 381 кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": `Smart Lean технологија; Електронски прилагодливо предоптоварување на задната суспензија; Дневни светла Pathfinder S; Прилагодливи подложки за возач; Приспособливо ветробранско стакло на копче; Рачки со наместено осветлување; Избор на режими на возење; Исклучување на задниот цилиндар; Темпомат; Надзор на притисокот на гумите; Далечинско заклучување на тврдите странични багажници; Простор од 18.6 галони за складирање; Прилагодливи подложки за патници; 5-крако предно тркало; Brembo кочници; ABS; Палење без клуч; Фаринг монтиран на шасијата; Прилагодливи вентилациони отвори на фарингот; Грејни ракавици; Аудио систем од 400W со AM/FM, Bluetooth, USB, влез компатибилен со смартфони и Weatherband; 12V порта за полнење.`,
                            "Светла": "ЛЕД осветлување вклучувајќи го адаптивните ЛЕД предни фарови, Pathfinder S светлата за возење."
                        },
                        "Електрика": {
                            "Мерачи": "Табла со инструменти монтирана со аналоген брзинометар и тахометар со мерач на гориво, опсег, километража и моментален степен на пренос 15 LED telltale indicators; cruise control enabled, cruise control set, neutral, high beam, turn signal, ABS, check engine, low tire pressure, battery, low fuel, security system, low engine oil pressure and MPH or km/h unit designation.",
                            "Друго" : `7 инчен екран на допир подржан од RIDE COMMAND: Моделите од 2022 година вклучуваат бесплатен пробен период од 1 година за RIDE COMMAND+ (по истек на кој се применуваат такси), кој вклучува функции како во живо сообраќај/времето, интуитивно пребарување на дестинации, состојба на возилото и локатор на возилото. 7-инчен допирен екран со приказ на реално време; температура на амбиентот; насока; приказ на аудио информации; карта/навигација; Bluetooth статус за телефон и слушалки; статус на возилото (притисок на гумите, напон, часови на работа на моторот, замена на масло); информации за возилото (брзина, опсег на горивото, RPM, позицијата на степенот за пренос); тековни податоци за возењето (растојание, време на движење, време на застанување, височина, промена на височина); избор на режими на возење; јачина на екранот; читање на грешки на возилото; интеграција на Apple CarPlay.`
                        }
                    }
                ]
            },
        ]
    },
    {
        id: 5,
        family: `Scout 1250cc`,
        members: [
            {   
                id: 2.1,
                title: 'Scout Classic',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1250 cc",
                            "Димензии на клип": "104мм x 73.6мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 63 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6300 во минута",
                        "Максимален вртежен момент": "108 Nm",
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.549:1",
                            "2nd": "7.170:1",
                            "3rd": "5.715:1",
                            "4th": "4.850:1",
                            "5th": "4.286:1",
                            "6th": "3.680:1",
                            "Заден пренос": "2.276:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери / 76 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Pirelli Night Dragon 130/90B16 67H",
                            "Задни гуми": "Pirelli Night Dragon 150/80B16 77H",
                            "Тркала": `Излеани црни 16" x 3.5" / 16" x 3.5"`,
                            "Ауспух": "Поделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1562 мм",
                            "Висина на седиште": "680 мм",
                            "Растојание од земјата": "113 мм",
                            "Вкупна должина": "2318 мм",
                            "Вкупна ширина": "916 мм",
                            "Вкупна висина": "1096 мм",
                            "Наклонетост": "33°",
                            "Закосеност": "29°",
                            "Предница": "122.2 мм",
                            "Капацитет на гориво": "13.0 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "243кг / 252кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Standard: ABS; Limited: ABS, режими на возење, USB порта, темпомат, контрола за тракција; Limited + Tech: ABS, режими на возење, USB порта, темпомат, контрола за тракција, палење без клуч.",
                        },
                        "Електрика": {
                            "Мерачи": "Standard и Limited: Аналоген брзиномерач, Дигитален тахометар, одометар, трип метар, температура на моторот, светилка за ниско ниво на гориво; Limited + Tech: Дигитален брзиномерач, Дигитален тахометар, одометар, компас, индикатор на брзина.",
                        }
                    }
                ]
            },
            {   
                id: 2.2,
                title: 'Scout Bobber',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1250 cc",
                            "Димензии на клип": "104мм x 73.6мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 63 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6300 во минута",
                        "Максимален вртежен момент": "108 Nm",
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.549:1",
                            "2nd": "7.170:1",
                            "3rd": "5.715:1",
                            "4th": "4.850:1",
                            "5th": "4.286:1",
                            "6th": "3.680:1",
                            "Заден пренос": "2.276:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери / 51 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Pirelli MT60RS 130/90B16 67H",
                            "Задни гуми": "Pirelli MT60RS 150/80B16 77H",
                            "Тркала": `Излеани црни 16" x 3.5" / 16" x 3.5"`,
                            "Ауспух": "Поделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1562 мм",
                            "Висина на седиште": "665 мм",
                            "Растојание од земјата": "109 мм",
                            "Вкупна должина": "2206 мм",
                            "Вкупна ширина": "930 мм",
                            "Вкупна висина": "1071 мм",
                            "Наклонетост": "31°",
                            "Закосеност": "29.4°",
                            "Предница": "125.2 мм",
                            "Капацитет на гориво": "13.0 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "237кг / 246кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Standard: ABS; Limited: ABS, режими на возење, USB порта, темпомат, контрола за тракција; Limited + Tech: ABS, режими на возење, USB порта, темпомат, контрола за тракција, палење без клуч.",
                        },
                        "Електрика": {
                            "Мерачи": "Standard и Limited: Аналоген брзиномерач, Дигитален тахометар, одометар, трип метар, температура на моторот, светилка за ниско ниво на гориво; Limited + Tech: Дигитален брзиномерач, Дигитален тахометар, одометар, компас, индикатор на брзина.",
                        }
                    }
                ]
            },
            {   
                id: 2.3,
                title: 'Sport Scout',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1250 cc",
                            "Димензии на клип": "104мм x 73.6мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 63 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6300 во минута",
                        "Максимален вртежен момент": "108 Nm",
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.549:1",
                            "2nd": "7.170:1",
                            "3rd": "5.715:1",
                            "4th": "4.850:1",
                            "5th": "4.286:1",
                            "6th": "3.680:1",
                            "Заден пренос": "2.276:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери / 76 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Metzeler Cruistec 130/60B19 61H",
                            "Задни гуми": "Metzeler Cruistec 150/80B16 72H",
                            "Тркала": `Излеани црни 19" x 3.5" / 16" x 3.5"`,
                            "Ауспух": "Поделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1562 мм",
                            "Висина на седиште": "680 мм",
                            "Растојание од земјата": "113 мм",
                            "Вкупна должина": "2206 мм",
                            "Вкупна ширина": "956 мм",
                            "Вкупна висина": "1155 мм",
                            "Наклонетост": "33°",
                            "Закосеност": "29°",
                            "Предница": "122.9 мм",
                            "Капацитет на гориво": "13.0 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "239кг / 248кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Standard: ABS; Limited: ABS, режими на возење, USB порта, темпомат, контрола за тракција; Limited + Tech: ABS, режими на возење, USB порта, темпомат, контрола за тракција, палење без клуч.",
                        },
                        "Електрика": {
                            "Мерачи": "Standard и Limited: Аналоген брзиномерач, Дигитален тахометар, одометар, трип метар, температура на моторот, светилка за ниско ниво на гориво; Limited + Tech: Дигитален брзиномерач, Дигитален тахометар, одометар, компас, индикатор на брзина.",
                        }
                    }
                ]
            },
            {   
                id: 2.4,
                title: 'Super Scout',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1250 cc",
                            "Димензии на клип": "104мм x 73.6мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 63 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6300 во минута",
                        "Максимален вртежен момент": "108 Nm",
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.549:1",
                            "2nd": "7.170:1",
                            "3rd": "5.715:1",
                            "4th": "4.850:1",
                            "5th": "4.286:1",
                            "6th": "3.680:1",
                            "Заден пренос": "2.276:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери / 76 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Pirelli MT60RS 130/90B16 67H",
                            "Задни гуми": "Pirelli MT60RS 130/90B16 67H",
                            "Тркала": `Излеани црни 16" x 3.5" / 16" x 3.5"`,
                            "Ауспух": "Поделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1562 мм",
                            "Висина на седиште": "680 мм",
                            "Растојание од земјата": "113 мм",
                            "Вкупна должина": "2260 мм",
                            "Вкупна ширина": "916 мм",
                            "Вкупна висина": "1226 мм",
                            "Наклонетост": "33°",
                            "Закосеност": "29°",
                            "Предница": "122 мм",
                            "Капацитет на гориво": "13.0 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "258кг / 268кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Standard: ABS; Limited: ABS, режими на возење, USB порта, темпомат, контрола за тракција; Limited + Tech: ABS, режими на возење, USB порта, темпомат, контрола за тракција, палење без клуч.",
                        },
                        "Електрика": {
                            "Мерачи": "Standard и Limited: Аналоген брзиномерач, Дигитален тахометар, одометар, трип метар, температура на моторот, светилка за ниско ниво на гориво; Limited + Tech: Дигитален брзиномерач, Дигитален тахометар, одометар, компас, индикатор на брзина.",
                        }
                    }
                ]
            },
            {   
                id: 2.5,
                title: 'Scout 101',
                engine: [
                    {
                        "Мотор": {
                            "Тип на мотор": "V-Twin со течно ладење",
                            "Запремнина на мотор": "1250 cc",
                            "Димензии на клип": "104мм x 73.6мм",
                            "Сооднос на компресија": "12.5:1",
                            "Електронски систем за вбризгување гориво": "Вбризгување со затворена јамка / отвор од 63 мм"
                          },
                    },
                   {
                    "Перфоманси": {
                        "Максимални вртежи": "6300 во минута",
                        "Максимален вртежен момент": "109 Nm",
                      }
                   }
                ],
                drivechain: [
                    {
                        "Погон": {
                            "Примарен погон": "Зупчаниски механизам со мокра спојка",
                            "Спојка": "Мокра, многукратна",
                        },
                        "Сооднос на менувачот": {
                            "1st": "10.549:1",
                            "2nd": "7.170:1",
                            "3rd": "5.715:1",
                            "4th": "4.850:1",
                            "5th": "4.286:1",
                            "6th": "3.680:1",
                            "Заден пренос": "2.276:1"
                          },
                    },
                ],
                chasis: [
                    {
                        "Суспензија": {
                            "Предна Суспензија ": "Телескопска вилушка/120 мм",
                              "Задна Суспензија": "Двојни амортизери / 76 мм"
                        },
                        "Шасија": {
                            "Предна Запирачка": "Единечен / 298 мм Ротор / со 2 клипа калипер",
                            "Задна Запирачка": "Единечен /298 мм Ротор / со 1 клип калипер",
                            "Предни гуми": "Metzeler Cruistec 130/60B19 61H",
                            "Задни гуми": "Metzeler Cruistec 150/80R16 77H",
                            "Тркала": `Излеани црни 19" x 3.5" / 16" x 3.5"`,
                            "Ауспух": "Поделен двоен ауспух со вкрстување"
                          },
                    }
                ],
                dimensions: [
                    {
                        "Димензии": {
                            "Меѓуоскино растојание": "1562 мм",
                            "Висина на седиште": "680 мм",
                            "Растојание од земјата": "113 мм",
                            "Вкупна должина": "2206 мм",
                            "Вкупна ширина": "956 мм",
                            "Вкупна висина": "1155 мм",
                            "Наклонетост": "33°",
                            "Закосеност": "29°",
                            "Предница": "122.9 мм",
                            "Капацитет на гориво": "13.0 Л",
                            "Вкупна тежина": "449 кг",
                            "Тежина (празен резервоар / полн резервоар)": "240кг / 249кг"
                        }
                    }
                ],
                standard: [
                    {
                        "Карактеристики": {
                            "Гаранција": "2 години",
                        },
                        "Опрема": {
                            "Стандардна опрема": "Standard: ABS; Limited: ABS, режими на возење, USB порта, темпомат, контрола за тракција; Limited + Tech: ABS, режими на возење, USB порта, темпомат, контрола за тракција, палење без клуч.",
                        },
                        "Електрика": {
                            "Мерачи": "Standard и Limited: Аналоген брзиномерач, Дигитален тахометар, одометар, трип метар, температура на моторот, светилка за ниско ниво на гориво; Limited + Tech: Дигитален брзиномерач, Дигитален тахометар, одометар, компас, индикатор на брзина.",
                        }
                    }
                ]
            },
        ]
    }
    
]

export default SpecificationsList;