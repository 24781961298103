import { useEffect, useState } from "react";
import Header from "../../Main Components/Header";
import './aboutusstyle.css';
import bloglist from "../../Home Page/constants/bloglist";
import Parallax from "../../Main Components/Parallax";
import Footer from "../../Main Components/Footer";

const AboutIndian = () => {

    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
    }, []);

    return (
        <>
            <Header/>
            <div className="about_us_top">
                <h1>СИЛА, ИНОВАЦИЈА И ФОКУС НА КВАЛИТЕТ</h1>
                <p>Polaris® е финансиски стабилна, брзо растечка американска компанија со годишна продажба над 3 милијарди долари. Ние сме глобален лидер во моќните спортови, компанија на која и веруваат дистрибутерите и возачите на Polaris® во над 130 земји.</p>
                <p>Во Polaris®, иновативноста е нашата мотивација. Ние инженерираме премиум возила користејќи врвна технологија и најсовремена продукција. Polaris® произведува моторни санки, теренски возила (ATV и side-x-sides) и мотоцикли, вклучувајќи го и Indian Motorcycle®.</p>
                <p>Polaris® е горд снабдувач на вооружените сили на САД, обезбедувајќи специјално опремени теренски возила за употреба во воени операции ширум светот. Indian Motorcycle® има богата историја како легендарен воен снабдувач, почнувајќи од Првата светска војна, кога компанијата обезбеди 60% од мотоциклите што ги користеа американските и сојузничките сили. За Втората светска војна, Indian Motorcycle® произведе иновативни нови модели, многу опремени со приколки, кои беа клучни за успехот на американските сили и нивните сојузници.</p>
            </div>
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default AboutIndian;