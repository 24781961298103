import Scout1 from '../Scout Motorcycles Images/scout-item-1.webp';
import Scout2 from '../Scout Motorcycles Images/scout-item-2.webp';
import Scout3 from '../Scout Motorcycles Images/scout-item-3.webp';
import Scout4 from '../Scout Motorcycles Images/scout-item-4.webp';
import Scout5 from '../Scout Motorcycles Images/scout-item-5.webp';
import Scout6 from '../Scout Motorcycles Images/scout-item-6.webp';


import ScoutBobber1 from '../Scout Motorcycles Images/scoutbobber-item-1.webp';
import ScoutBobber2 from '../Scout Motorcycles Images/scoutbobber-item-2.webp';
import ScoutBobber3 from '../Scout Motorcycles Images/scoutbobber-item-3.webp';
import ScoutBobber4 from '../Scout Motorcycles Images/scoutbobber-item-4.webp';
import ScoutBobber5 from '../Scout Motorcycles Images/scoutbobber-item-5.webp';
import ScoutBobber6 from '../Scout Motorcycles Images/scoutbobber-item-6.webp';


import ScoutRogue1 from '../Scout Motorcycles Images/scoutrogue-item-1.webp';
import ScoutRogue2 from '../Scout Motorcycles Images/scoutrogue-item-2.webp';
import ScoutRogue3 from '../Scout Motorcycles Images/scoutrogue-item-3.webp';
import ScoutRogue4 from '../Scout Motorcycles Images/scoutrogue-item-4.webp';
import ScoutRogue5 from '../Scout Motorcycles Images/scoutrogue-item-5.webp';
import ScoutRogue6 from '../Scout Motorcycles Images/scoutrogue-item-6.webp';
import ScoutRogue7 from '../Scout Motorcycles Images/scoutrogue-item-7.webp';
import ScoutRogue8 from '../Scout Motorcycles Images/scoutrogue-item-8.webp';
import ScoutRogue9 from '../Scout Motorcycles Images/scoutrogue-item-9.webp';
import ScoutRogueVideo1 from '../Scout Motorcycles Images/rogue-video-1.mp4';
import ScoutRogueVideo2 from '../Scout Motorcycles Images/rogue-video-2.mp4';
import ScoutRogueVideo3 from '../Scout Motorcycles Images/rogue-video-3.mp4';
import ScoutRogueVideo4 from '../Scout Motorcycles Images/rogue-video-4.mp4';
import ScoutRogueVideo5 from '../Scout Motorcycles Images/rogue-video-5.mp4';


import ScoutBobber201 from '../Scout Motorcycles Images/scoutbobber20-item-1.webp';
import ScoutBobber202 from '../Scout Motorcycles Images/scoutbobber20-item-2.webp';
import ScoutBobber203 from '../Scout Motorcycles Images/scoutbobber20-item-3.webp';
import ScoutBobber204 from '../Scout Motorcycles Images/scoutbobber20-item-4.webp';
import ScoutBobber205 from '../Scout Motorcycles Images/scoutbobber20-item-5.webp';
import ScoutBobber206 from '../Scout Motorcycles Images/scoutbobber20-item-6.webp';
import ScoutBobber207 from '../Scout Motorcycles Images/scoutbobber20-item-7.webp';



const ScoutFeaturedItems = [
    {
      id: 1,
      mainTitle: `ОРИГИНАЛНОСТА Е СТАНДАРД`,
      mainDescription: `Име кое датира еден век наназад. Искуство кое го исполнува наследството. Scout е местото каде што дизајнот инспириран од наследството ги исполнува модерните перформанси.`,
      members: [
        {
          id: 1.1,
          title: `95кс.`,
          description: `V-twin со течно ладење, 1133сс и 95 коњски сили обезбедува брзо забрзување и моќ за секого, секаде. Електронското вбризгување на гориво испорачува врвни коњски сили и забрзување, додека респонзивниот менувач со 6 брзини овозможува спортско, агресивно возење.`,
          image: Scout1,
        },
        {
          id: 1.2,
          title: `ОБЛИЕН ВО ХРОМ`,
          description: `Премиум хром обезбедува многу сјај што ја "врти" главата.`,
          image: Scout2,
        },
        {
          id: 1.3,
          title: `СЕДИШТЕ`,
          description: `Седиштето е дизајнирано да ги издржи елементите додека ја одржува својата природна убавина.`,
          image: Scout3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: `МОДЕРЕН КЛАСИК`,
      mainDescription: `Scout комбинира безвременски стил со модерен дизајн за да обезбеди извонредно искуство за секој возач.`,
      members: [
        {
          id: 2.1,
          title: `ВОЗЕТЕ СО САМОДОВЕРБА`,
          description: `Ниската висина на седиштето и лесната рамка овозможуваат лесно и сигурно возење при секоја брзина.`,
          image: Scout4,
        },
        {
          id: 2.2,
          title: `ПОЛНЕЊЕ`,
          description: `Скриената USB порта го олеснува одржувањето на вашите уреди, што значи дека не треба да го жртвувате стилот за модерните времиња.`,
          image: Scout5,
        },
        {
          id: 2.3,
          title: `СТОПИРАЊЕ`,
          description: `Супериорна моќ на запирање со стандардот ABS во сите опции за боја.`,
          image: Scout6,
        },
      ],
    },
  ];

  export { ScoutFeaturedItems }; 


const ScoutBobberFeaturedItems = [
    {
      id: 1,
      mainTitle: `ГОЛЕМИТЕ РАБОТИ ДОАЃААТ ДО ОНИЕ КОИ ГИ ЛОВАТ`,
      mainDescription: `затемнет со агресивен став и сирова моќ, Scout Bobber е модерен приказ на иконскиот стил на Bobber.`,
      members: [
        {
          id: 1.1,
          title: `95кс`,
          description: `V-twin со течно ладење, 1133 кубика и 95 коњски сили обезбедува брзо забрзување и моќ да помине секого, секаде. Електронското вбризгување гориво испорачува врвни коњски сили и забрзување, додека респонзивниот менувач со 6 брзини овозможува спортско, агресивно возење.`,
          image: ScoutBobber1,
        },
        {
          id: 1.2,
          title: `ИКОНСКИ СТИЛ`,
          description: `Ретровизорите ја рационализираат силуетата, осигурувајќи дека мотоциклот изгледа исто толку добро додека стои и во место.`,
          image: ScoutBobber2,
        },
        {
          id: 1.3,
          title: `СЕДИШТЕ`,
          description: `Седиштето во стилот на Bobber ве става во агресивна позиција на возење без да се загрози удобноста.`,
          image: ScoutBobber3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: `ПРЕМИУМ СТИЛ`,
      mainDescription: `Дизајниран со бескомпромисно внимание на деталите, Bobber има стил што е невозможно да се игнорира.`,
      members: [
        {
          id: 2.1,
          title: `ПОЛНЕЊЕ`,
          description: `Скриената USB порта го олеснува одржувањето на вашите уреди, што значи дека не треба да го жртвувате стилот за модерните времиња.`,
          image: ScoutBobber4,
        },
        {
          id: 2.2,
          title: `СТОПИРАЊЕ`,
          description: `На крајот ќе мора да застанете. ABS сега е достапен во секоја боја. Моделот е прикажан со опционални додатоци.`,
          image: ScoutBobber5,
        },
        {
          id: 2.3,
          title: `BLACKED-OUT`,
          description: `Ставот е сè. И Bobber има став во браникот со затемнети врвни завршетоци од крило до крило`,
          image: ScoutBobber6,
        },
      ],
    },
  ];

  export { ScoutBobberFeaturedItems }; 



const ScoutRogueFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `БИДЕТЕ ДЕЛ ОД НЕШТО ПОГОЛЕМО`,
          description: `Со модерен инспириран стил и удобности, Scout Rogue му дава поагресивен дух на семејството Scout.`,
          image: ScoutRogue1,
        },
        {
          id: 1.2,
          title: `МОЌ`,
          description: `V-twin со течно ладење, 1133сс и 95 коњски сили обезбедува брзо забрзување и моќ да помине секого, секаде. Електронското вбризгување гориво испорачува врвни коњски сили и забрзување, додека респонзивниот менувач со 6 брзини овозможува спортско, агресивно возење.`,
          image: ScoutRogue2,
          video: ScoutRogueVideo1,
          videoID: `RogueVideo1`
        },
        {
          id: 1.3,
          title: `ЕРГОНОМИЧЕН`,
          description: `Mini-ape рачките го надополнуваат смелиот став, молејќи ги возачите да возат толку агресивно како што изгледа.`,
          image: ScoutRogue3,
          video: ScoutRogueVideo2,
          videoID: `RogueVideo2`
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `ДИЗАЈН`,
          description: `Мини облогата му дава на Scout Rogue посебен изглед. Додајте додатоци како LED осветлување или мини облога во исти бои за да го направите прилагодлив за вас.`,
          image: ScoutRogue4,
          video: ScoutRogueVideo3,
          videoID: `RogueVideo3`
        },
        {
          id: 2.2,
          title: `СЕДИШТЕ ВО СПОРТСКИ СТИЛ`,
          description: `Седиштето во спортски стил обезбедува елегантен изглед додека возачите го одржуваат самоуверено својот мотоцикл.`,
          image: ScoutRogue5,
          video: ScoutRogueVideo4,
          videoID: `RogueVideo4`
        },
        {
          id: 2.3,
          title: `19" ПРЕДНО ТРКАЛО`,
          description: `Предното тркало од 19 инчи ја додава агресивната природа на Scout Rogue - и во стилот и во агилното управување.`,
          image: ScoutRogue6,
          video: ScoutRogueVideo5,
          videoID: `RogueVideo5`
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `ИЗРАБОТЕН ЗА ЛЕСНОТИЈА`,
          description: `Лесната, лиена алуминиумска рамка и ниското седиште создаваат стручно избалансиран мотоцикл со кој е лесен за ракување при сите брзини.`,
          image: ScoutRogue7,
        },
        {
          id: 2.2,
          title: `ПОЛНЕЊЕ`,
          description: `Скриената USB порта го олеснува одржувањето на вашите уреди, што значи дека не треба да го жртвувате стилот за модерните времиња.`,
          image: ScoutRogue8,
        },
        {
          id: 2.3,
          title: `СТОПИРАЊЕ`,
          description: `На крајот ќе мора да застанете. ABS сега е достапен во секоја боја. Моделот е прикажан со опционални додатоци.`,
          image: ScoutRogue9,
        },
      ],
    },
  ];

  export { ScoutRogueFeaturedItems }; 


  const ScoutBobber20FeaturedItems = [
    {
      id: 1,
      mainTitle: `OLD SCHOOL BOBBER ИЗГЛЕД. НОВА ТЕХНОЛОГИЈА`,
      mainDescription: `Со модерна моќ и повратен стил на возење сè-во-едно, дизајнот на Scout Bobber Twenty се навраќа на оригиналниот Scout со својот соголен, минималистички стил на Bobber со доза на класично наследство.`,
      members: [
        {
          id: 1.1,
          title: `КЛАСИЧЕН`,
          description: `Инспириран од оригиналот, Scout Bobber Twenty изгледа груб со причина. Со мешавина од хромирани и затемнети завршетоци и жичени тркала, ова возење му дава до знаење на светот дека сте сериозни.`,
          image: ScoutBobber201,
        },
        {
          id: 1.2,
          title: `95кс`,
          description: `V-twin со течно ладење, 1133сс и 95 коњски сили обезбедува брзо забрзување и моќ да помине секого, секаде.`,
          image: ScoutBobber202,
        },
        {
          id: 1.3,
          title: `СТИЛ`,
          description: `Двобојното седиште во стил на Bobber ви дава заповедничкo чувство, додека напредните држачи и mini ape рачките ги ставаат вашите стапала напред и рацете пред ветрот.`,
          image: ScoutBobber203,
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: `Направен со макотрпно внимание на деталите, Scout Bobber Twenty е злобен, old school. Исполнет со дизајнерски знаци од оригиналниот Scout, тој е наменет за возачи кои сакаат иконски стилски стил.`,
      members: [
        {
          id: 2.1,
          title: `ТРКАЛА`,
          description: `Scout Bobber Twenty има жичени тркала со стилизирање.`,
          image: ScoutBobber204,
        },
        {
          id: 2.2,
          title: `РАЧКИ`,
          description: `Mini Ape рачки за порелаксирано возење и иконски профил.`,
          image: ScoutBobber205,
        },
        {
          id: 2.3,
          title: `ПОЛНЕЊЕ`,
          description: `USB-портата го олеснува полнењето на вашите мобилни уреди во движење.`,
          image: ScoutBobber206,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `СТОПИРАЊЕ`,
          description: `На крај мора да застанете. ABS е сега достапен во секоја боја по ваша желба.`,
          image: ScoutBobber207,
        },
        {
          id: 2.2,
          title: ``,
          description: ``,
          image: ``,
        },
        {
          id: 2.3,
          title: ``,
          description: ``,
          image: ``,
        },
      ],
    },
  ];

  export { ScoutBobber20FeaturedItems }; 