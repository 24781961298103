import imr from '../Home  Page Images/imr.webp';
import history2 from '../Home  Page Images/history2.webp';
import accessories from '../Home  Page Images/accessories.webp';
import apparel from '../Home  Page Images/apparel.webp';
import { ApparelLink } from '../../Constants/AllConstants';

const bloglist = [
    {
        id: 1,
        image: imr,
        title: `Indian Motorcycle Riders`,
        link: '/imrg',
    },
    {
        id: 2,
        image: history2,
        title: `Историја`,
        link: '/history',
    },
    {
        id: 3,
        image: accessories,
        title: `Додатоци`,
        link: 'https://www.indianmotorcycle.com/en-001/build-model/',
    },
    {
        id: 4,
        image: apparel,
        title: `Облека`,
        link: ApparelLink,
    },
];

export default bloglist;