const SingleFamilyModels = ({family, setChosenModel, setModelsPopUp, modelsPopUp, setChosenModelTitle}) => {


    const handleChosenModel = (image, title) => {
        setChosenModel(image);
        setModelsPopUp(!modelsPopUp);
        setChosenModelTitle(title)
    }

    return (
        <div className="motorcycles_family">
            <div className="motorcycles_models">
                {
                    family.members.map((item) => (
                        <div className="family_single_member">
                            <img alt="" src={item.image} onClick={(e) => handleChosenModel(item.image, item.title)}></img>
                            <p>{item.title}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SingleFamilyModels;