
import scoutclassic1 from '../Carousel Images/scout-classic-1.jpg';
import scoutclassic2 from '../Carousel Images/scout-classic-2.jpg';
import scoutclassic3 from '../Carousel Images/scout-classic-3.jpg';
import scoutclassic4 from '../Carousel Images/scout-classic-4.jpg';
import scoutclassic5 from '../Carousel Images/scout-classic-5.jpg';
import scoutclassic6 from '../Carousel Images/scout-classic-6.jpg';

import scoutbobber1 from '../Carousel Images/scout-bobber-1.jpg';
import scoutbobber2 from '../Carousel Images/scout-bobber-2.jpg';
import scoutbobber3 from '../Carousel Images/scout-bobber-3.jpg';
import scoutbobber4 from '../Carousel Images/scout-bobber-4.jpg';
import scoutbobber5 from '../Carousel Images/scout-bobber-5.jpg';
import scoutbobber6 from '../Carousel Images/scout-bobber-6.jpg';

import scoutsport1 from '../Carousel Images/scout-sport-1.jpg';
import scoutsport2 from '../Carousel Images/scout-sport-2.jpg';
import scoutsport3 from '../Carousel Images/scout-sport-3.jpg';
import scoutsport4 from '../Carousel Images/scout-sport-4.jpg';
import scoutsport5 from '../Carousel Images/scout-sport-5.jpg';
import scoutsport6 from '../Carousel Images/scout-sport-6.jpg';

import scoutsuper1 from '../Carousel Images/scout-super-1.jpg';
import scoutsuper2 from '../Carousel Images/scout-super-2.jpg';
import scoutsuper3 from '../Carousel Images/scout-super-3.jpg';
import scoutsuper4 from '../Carousel Images/scout-super-4.jpg';
import scoutsuper5 from '../Carousel Images/scout-super-5.jpg';
import scoutsuper6 from '../Carousel Images/scout-super-6.jpg';

import scout1011 from '../Carousel Images/scout-101-1.jpg';
import scout1012 from '../Carousel Images/scout-101-2.jpg';
import scout1013 from '../Carousel Images/scout-101-3.jpg';
import scout1014 from '../Carousel Images/scout-101-4.jpg';
import scout1015 from '../Carousel Images/scout-101-5.jpg';
import scout1016 from '../Carousel Images/scout-101-6.jpg';

const ScoutClassicCarouselImages = [
    {
        id: 1,
        image: scoutclassic1
    },
    {
        id: 2,
        image: scoutclassic2
    },
    {
        id: 3,
        image: scoutclassic3
    },
    {
        id: 4,
        image: scoutclassic4
    },
    {
        id: 5,
        image: scoutclassic5
    },
    {
        id: 6,
        image: scoutclassic6
    },
]

export { ScoutClassicCarouselImages }

const ScoutBobber1250CarouselImages = [
    {
        id: 1,
        image: scoutbobber1
    },
    {
        id: 2,
        image: scoutbobber2
    },
    {
        id: 3,
        image: scoutbobber3
    },
    {
        id: 4,
        image: scoutbobber4
    },
    {
        id: 5,
        image: scoutbobber5
    },
    {
        id: 6,
        image: scoutbobber6
    },
]

export { ScoutBobber1250CarouselImages }


const ScoutSportCarouselImages = [
    {
        id: 1,
        image: scoutsport1
    },
    {
        id: 2,
        image: scoutsport2
    },
    {
        id: 3,
        image: scoutsport3
    },
    {
        id: 4,
        image: scoutsport4
    },
    {
        id: 5,
        image: scoutsport5
    },
    {
        id: 6,
        image: scoutsport6
    },
]

export { ScoutSportCarouselImages }

const ScoutSuperCarouselImages = [
    {
        id: 1,
        image: scoutsuper1
    },
    {
        id: 2,
        image: scoutsuper2
    },
    {
        id: 3,
        image: scoutsuper3
    },
    {
        id: 4,
        image: scoutsuper4
    },
    {
        id: 5,
        image: scoutsuper5
    },
    {
        id: 6,
        image: scoutsuper6
    },
]

export { ScoutSuperCarouselImages }

const Scout101CarouselImages = [
    {
        id: 1,
        image:scout1011
    },
    {
        id: 2,
        image: scout1012
    },
    {
        id: 3,
        image: scout1013
    },
    {
        id: 4,
        image: scout1014
    },
    {
        id: 5,
        image: scout1015
    },
    {
        id: 6,
        image: scout1016
    },
]

export { Scout101CarouselImages }


