import { Link } from 'react-router-dom';
import '../Main Style/topnavigationsection.css';
import { BsGlobeAmericas } from 'react-icons/bs';


const TopNavigationSection = () => {
    return (
        <div className='top_navigation_section'>
            <div>
                <Link to='https://www.indianmotorcycle-intl.eu/select-country'><BsGlobeAmericas/></Link>
            </div>
        </div>
    )
}

export default TopNavigationSection;