const FTROverviewDetails = [
    {
        id: 1,
        title: `Разновидност`,
        description: `FTR мотоциклите нудат избалансирана позиција за возење—агресивна кога ви е потребна или опуштена за секојдневни возења.`
    },
    {
        id: 2,
        title: `Перформанси`,
        description: `Моќните V-twin мотори со течно ладење од 1203cc и одговорното ракување се комбинираат за агилно и возбудливо искуство.`
    },
    {
        id: 3,
        title: `Цел`,
        description: `Секој детал и замисла има една цел: одлично возење.`
    }
]

export default FTROverviewDetails;