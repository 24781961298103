const EraLayout = ({list}) => {
    console.log(list)
    return (
        <>

        {
            list.map((item) => (
            <div className={item.id%2===1 ? `era_layout_container` : `era_layout_container era_layout_reverse`}>
            <div className="era_layout_inner era_layout_text">
              <h1>{item.title}</h1>
                {
                    item.text&&item.text.map((item) => (
                        <p>{item.description}</p>
                    ))
                }
            </div>
            <div className="era_layout_inner">
                <img alt="" src={item.image}></img>
            </div>
        </div>
            ))
        }
        </>
    )
}

export default EraLayout;