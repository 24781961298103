import { useEffect, useState } from 'react';
import bloglist from '../../../Home Page/constants/bloglist';
import background from './Tumultuous Times Images/tumultuous-times-hero-lg.webp';
import historycaleras from '../../History/constants/historycaleras';
import Parallax from '../../../Main Components/Parallax';
import Footer from '../../../Main Components/Footer';
import { TumultuousTimesEra, TumultuousTimesTopDescription } from '../constants/HistorySubPagesList';
import TopSection from '../components/TopSection';
import Header from '../../../Main Components/Header';
import EraLayout from '../components/EraLayout';
import { Link } from 'react-router-dom';

const TumultuousTimes = () => {
    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
    }, []);

    const topTitle = `1953 - 2010`;

    return (
        <>
            <Header/>
            <img alt="" src={background} className='historysub_topimg'></img>
            <TopSection title={topTitle} description={TumultuousTimesTopDescription}/>
            <EraLayout list={TumultuousTimesEra}/>
            <div className='people_container'>
                <h4>FLOYD CLYMER</h4>
                <p>Кога Indian Motorcycle престана да се произведува во 1953 година, американскиот свет на мотоцикли беше измачуван од загубата на еден од најисториските брендови во индустријата. Во 1963 година, тркач на мотоцикли, дилер, дистрибутер, издавач на списанија, промотор на трки, автор и производител на мотоцикли Флојд Клајмер ја презеде сопственоста на трговската марка Indian Motorcycle. Неговата борба да го оживее непостоечкиот бренд Indian Motorcycle вклучува брендирање на увезените мотоцикли Royal Enfield и Velocette со името Indian Motorcycle.</p>
                <p>Во 1970 година се случи трагедија, а Флојд Клајмер почина од срцев удар. Тој му ја пренел трговската марка на Indian Motorcycle на својот адвокат Алан Њуман, кој продолжил да продава мали мотоцикли, главно произведени во Тајван, носејќи го брендот пред да престане со работа во 1977 година.</p>
            </div>
            <div className='historycal_eras_wrapper'>
                    {
                        historycaleras.map((item) => (
                            <Link to={item.link}>
                                  <div className='historycal_eras_inner'>
                                <img alt='' src={item.image}></img>
                                <div className='eras_hero'>
                                    <h2>{item.title}</h2>
                                    <p>{item.date}</p>
                                </div>

                            </div>
                            </Link>
                         
                        ))
                    }
                </div>
                <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default TumultuousTimes;