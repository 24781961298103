const VideoModal = ({image, video, videoID}) => {

    return (
        <>
        <img src={image} alt="" data-bs-toggle="modal" data-bs-target={`#${videoID}`}></img>

        <div class="modal fade" id={videoID} tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <video controls autoPlay className="video_modal">
                <source src={video} type="video/mp4"/> 
                Sorry, your browser doesn't support embedded videos.
                </video>
            </div>
            </div>
        </div>
        </div>
</>
    )
}

export default VideoModal;