import { useEffect, useState } from "react";
import bloglist from "../../../Home Page/constants/bloglist";
import background from './2011 and Beyond Images/indian-motorcycle-returns-hero-lg.webp';
import { AndBeyondBox, AndBeyondEra, AndBeyondTopDescription } from "../constants/HistorySubPagesList";
import TopSection from "../components/TopSection";
import Header from "../../../Main Components/Header";
import EraLayout from "../components/EraLayout";
import historycaleras from "../../History/constants/historycaleras";
import { Link } from "react-router-dom";
import Parallax from "../../../Main Components/Parallax";
import Footer from "../../../Main Components/Footer";
import Box from "../components/Box";

const AndBeyond = () => {
    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
    }, []);

    const topTitle = `2011 - ДЕНЕС`;

    return (
        <>
            <Header/>
            <img alt="" src={background} className='historysub_topimg'></img>
            <TopSection title={topTitle} description={AndBeyondTopDescription}/>
            <EraLayout list={AndBeyondEra}/>
            <Box list={AndBeyondBox}/>
            <div className='historycal_eras_wrapper'>
                    {
                        historycaleras.map((item) => (
                            <Link to={item.link}>
                                  <div className='historycal_eras_inner'>
                                <img alt='' src={item.image}></img>
                                <div className='eras_hero'>
                                    <h2>{item.title}</h2>
                                    <p>{item.date}</p>
                                </div>

                            </div>
                            </Link>
                         
                        ))
                    }
                </div>
                <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default AndBeyond;