import Button from "../../Single Motorcycle/Components/Button"; 

const ImrgHero = ({title, description, buttonText, link}) => {
    return (
        <div className="imrg_hero_wrapper">
            <div className="imrg_hero_container">
                <h1 className="imrg_hero_title">{title}</h1>
                <p className="imrg_hero_description">{description}</p>
                <Button text={buttonText} link={link}></Button>
            </div>
        </div>
    )
}

export default ImrgHero;