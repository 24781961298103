import scout from '../Main Images/Motorcycles Dropdown Family/scout.webp';
import scoutBobber from '../Main Images/Motorcycles Dropdown Family/scout-bobber.webp';
import scoutBobber20 from '../Main Images/Motorcycles Dropdown Family/scout-bobber-20.webp';
import scoutRogue from '../Main Images/Motorcycles Dropdown Family/scout-rogue.webp';

import ftr from '../Main Images/Motorcycles Dropdown Family/ftr.webp';
import ftrRally from '../Main Images/Motorcycles Dropdown Family/ftr-rally-menu.webp';
import ftrSport from '../Main Images/Motorcycles Dropdown Family/ftr-sport.webp';
import ftrrCarbon from '../Main Images/Motorcycles Dropdown Family/ftr-r-carbon.webp';
import ftrhooligan from '../Main Images/Motorcycles Dropdown Family/ftr-hooligan.jpg';
import ftrxrcarbon from '../Main Images/Motorcycles Dropdown Family/ftr-x-100-r-carbon.jpg';

import chiefDarkHorse from '../Main Images/Motorcycles Dropdown Family/chief-dark-horse.webp';
import chiefbobberdarkhorse from '../Main Images/Motorcycles Dropdown Family/chief-bobber-dark-horse.webp';
import superchieflimited from '../Main Images/Motorcycles Dropdown Family/super-chief-limited.webp';
import sportchief from '../Main Images/Motorcycles Dropdown Family/sport-chief.webp'

import indianSpringfield from '../Main Images/Motorcycles Dropdown Family/springfield.webp';
import indianspringfielddarkhorse from '../Main Images/Motorcycles Dropdown Family/springfield-dark-horse.webp';
import chieftaindarkhorse from '../Main Images/Motorcycles Dropdown Family/chieftain-dark-horse.webp';
import chieftainlimited from '../Main Images/Motorcycles Dropdown Family/chieftain-limited.webp';
import challengerlimited from '../Main Images/Motorcycles Dropdown Family/challenger-limited.webp';
import challengerdarkhorse from '../Main Images/Motorcycles Dropdown Family/challenger-dark-horse.webp';

import roadmaster from '../Main Images/Motorcycles Dropdown Family/roadmaster.webp';
import roadmasterdarkhorse from '../Main Images/Motorcycles Dropdown Family/roadmaster-dark-horse.webp';
import roadmasterlimited from '../Main Images/Motorcycles Dropdown Family/roadmaster-limited.webp';
import indianpursuitdarkhorse from '../Main Images/Motorcycles Dropdown Family/pursuit-dh-prem.webp'
import pursuitlimited from '../Main Images/Motorcycles Dropdown Family/pursuit-limited.webp';

import indianchallengerelite from '../Main Images/Motorcycles Dropdown Family/indian-challenger-elite.png';
import indianroadmasterelite from '../Main Images/Motorcycles Dropdown Family/indian-roadmaster-elite.png';

import scoutclassic from '../Main Images/Motorcycles Dropdown Family/scout-classic.jpg';
import scoutbobber from '../Main Images/Motorcycles Dropdown Family/scout-bobber.jpg';
import scoutsport from '../Main Images/Motorcycles Dropdown Family/sport-scout.jpg';
import superscout from '../Main Images/Motorcycles Dropdown Family/super-scout.jpg';
import scout101 from '../Main Images/Motorcycles Dropdown Family/101-scout.jpg';

const MotorcyclesDropdownFamily = [
    {
        id: 1,
        family: `Scout 1250cc`,
        footer: `Сите Scout 1250cc`,
        link: `/scout-1250cc-family`,
        members: [
            {
                id:1,
                title: `Scout Classic`,
                image: scoutclassic,
                link: '/scout-classic-1250cc',
            },
            {
                id:2,
                title: `Scout Bobber`,
                image: scoutbobber,
                link: '/scout-bobber-1250cc',
            },
            {
                id:3,
                title: `Sport Scout`,
                image: scoutsport,
                link: '/sport-scout-1250cc',
            },
            {
                id:4,
                title: `Super Scout`,
                image: superscout,
                link: '/super-scout-1250cc',
            },
        ],
    },
    {
        id: 2,
        family: `Scout 1133cc`,
        footer: `Сите Scout 1133cc`,
        link: `/scout-family`,
        members: [
            {
                id: 1,
                title: `Scout`,
                image: scout,
                link: '/scout',
            },
            {
                id: 2,
                title: `Scout Bobber`,
                image: scoutBobber,
                link: '/scout-bobber',
            },
            {
                id: 3,
                title: `Scout Bobber Twenty`,
                image: scoutBobber20,
                link: '/scout-bobber-twenty',
            },
            {
                id: 4,
                title: `Scout Rogue`,
                image: scoutRogue,
                link: '/scout-rogue',
            },
        ],
    },
    {
        id: 3,
        family: `FTR`,
        footer: `Сите FTR`,
        link: `/ftr-family`,
        members: [
            {
                id:1,
                title: `FTR`,
                image: ftr,
                link: '/ftr'
            },
            {
                id:2,
                title: `FTR Rally`,
                image: ftrRally,
                link: '/ftr-rally'
            },
            {
                id:3,
                title: `FTR Sport`,
                image: ftrSport,
                link: '/ftr-sport'
            },
            {
                id:4,
                title: `FTR R Carbon`,
                image: ftrrCarbon,
                link: '/ftr-r-carbon'
            }
        ],
    },
    {
        id: 4,
        family: `Cruiser`,
        footer: `Сите Cruiser`,
        link: `/cruiser-family`,
        members: [
            {
                id:1,
                title: `Chief Dark Horse`,
                image: chiefDarkHorse,
                link: '/chief-dark-horse'
            },
            {
                id:2,
                title: `Chief Bobber Dark Horse`,
                image: chiefbobberdarkhorse,
                link: '/chief-bobber-dark-horse'
            },
            {
                id:3,
                title: `Super Chief Limited`,
                image: superchieflimited,
                link: '/super-chief-limited'
            },
            {
                id:4,
                title: `Sport Chief`,
                image: sportchief,
                link: '/sport-chief'
            },
        ],
    },
    {
        id: 5,
        family: `Bagger`,
        footer: `Сите Bagger`,
        link: `/bagger-family`,
        members: [
            {
                id:1,
                title: `Indian Springfield`,
                image: indianSpringfield,
                link: `/indian-springfield`
            },
            {
                id:2,
                title: `Indian Springfield Dark Horse`,
                image: indianspringfielddarkhorse,
                link: `/indian-springfield-dark-horse`
            },
            {
                id:3,
                title: `Chieftain Dark Horse`,
                image: chieftaindarkhorse,
                link: `/chieftain-dark-horse`
            },
            {
                id:4,
                title: `Chieftain Limited`,
                image: chieftainlimited,
                link: `/chieftain-limited`
            },
        ],
    },
    {
        id: 6,
        family: `Touring`,
        footer: `Сите Touring`,
        link: `/touring-family`,
        members: [
            {
                id:1,
                title: `Roadmaster`,
                image: roadmaster,
                link: `/roadmaster`
            },
            {
                id:2,
                title: `Roadmaster Dark Horse`,
                image: roadmasterdarkhorse,
                link: `/roadmaster-dark-horse`
            },
            {
                id:3,
                title: `Roadmaster Limited`,
                image: roadmasterlimited,
                link: `/roadmaster-limited`
            },
            {
                id:4,
                title: `Indian Pursuit Dark Horse`,
                image: indianpursuitdarkhorse,
                link: `/indian-pursuit-dark-horse`
            },
        ],
    },
    {
        id: 7,
        family: `Dark Horse`,
        footer: `Сите Dark Horse`,
        link: `/dark-horse-family`,
        members: [
            {
                id:1,
                title: `Chief Dark Horse`,
                image: chiefDarkHorse,
                link: `/chief-dark-horse`
            },
            {
                id:2,
                title: `Chief Bobber Dark Horse`,
                image: chiefbobberdarkhorse,
                link: `/chief-bobber-dark-horse`
            },
            {
                id:3,
                title: `Indian Springfield Dark Horse`,
                image: indianspringfielddarkhorse,
                link: `/indian-springfield-dark-horse`
            },
            {
                id:4,
                title: `Chieftain Dark Horse`,
                image: chieftaindarkhorse,
                link: `/chieftain-dark-horse`
            },
        ],
    },
    {
        id: 8,
        family: `Elite`,
        footer: `Сите Elite`,
        link: `/elite-family`,
        members: [
            {
                id:1,
                title: `Indian Challenger Elite`,
                image: indianchallengerelite,
                link: `/indian-challenger-elite`
            },
            {
                id: 2,
                title: `Indian Roadmaster Elite`,
                image: indianroadmasterelite,
                link: `/indian-roadmaster-elite`
            }
        ]
    }
    // {
    //     id: 7,
    //     family: `The 2023 line-up`,
    //     link: `/test`,
    //     footer: ``,
    //     members: [
    //         {
    //             id:7.1,
    //             title: `Scout`,
    //             image: scout,
    //             link: `/scout-family`
    //         },
    //         {
    //             id:7.2,
    //             title: `FTR`,
    //             image: ftr,
    //             link: `/ftr-family`
    //         },
    //         {
    //             id:7.3,
    //             title: `Cruiser`,
    //             image: chiefDarkHorse,
    //             link: `/cruiser-family`
    //         },
    //         {
    //             id: 7.4,
    //             title: `Bagger`,
    //             image: indianSpringfield,
    //             link: `/bagger-family`
    //         },
    //         {
    //             id: 7.5,
    //             title: `Touring`,
    //             image: roadmaster,
    //             link: `/touring-family`
    //         },
    //         {
    //             id:7.6,
    //             title: `Dark Horse`,
    //             image: chieftainlimited,
    //             link: `/dark-horse-family`
    //         },
    //     ],
    // },

];

export default MotorcyclesDropdownFamily;



const MotorcyclesTestRideDropdownFamily = [
    {
        id: 1,
        family: `Scout 1250cc`,
        footer: `Сите Scout 1250cc`,
        members: [
            {
                id:1,
                title: `Scout Classic`,
                image: scoutclassic,
            },
            {
                id:2,
                title: `Scout Bobber`,
                image: scoutbobber,
            },
            {
                id:3,
                title: `Sport Scout`,
                image: scoutsport,
            },
            {
                id:4,
                title: `Super Scout`,
                image: superscout,
            },
            {
                id:5,
                title: `101 Scout`,
                image: scout101,
            },
        ],
    },
    {
        id: 2,
        family: `Scout 1133cc`,
        footer: `Сите Scout 1133cc`,
        members: [
            {
                id:1,
                title: `Scout`,
                image: scout,
            },
            {
                id:2,
                title: `Scout Bobber`,
                image: scoutBobber,
            },
            {
                id:3,
                title: `Scout Bobber Twenty`,
                image: scoutBobber20,
            },
            {
                id:4,
                title: `Scout Rogue`,
                image: scoutRogue,
            },
        ],
    },
    {
        id: 3,
        family: `FTR`,
        footer: `All FTR`,
        members: [
            {
                id:1,
                title: `FTR`,
                image: ftr,
            },
            {
                id:2,
                title: `FTR Rally`,
                image: ftrRally,
            },
            {
                id:3,
                title: `FTR Sport`,
                image: ftrSport,
            },
            {
                id:4,
                title: `FTR R Carbon`,
                image: ftrrCarbon,
            },
            {
                id: 5,
                title: `FTR X RSD Super Hooligan`,
                image: ftrhooligan
            },
            {
                id: 6,
                title: `FTR X 100% R Carbon`,
                image: ftrxrcarbon,
            }
        ],
    },
    {
        id: 4,
        family: `Cruiser`,
        footer: `All Cruiser`,
        members: [
            {
                id:1,
                title: `Chief Dark Horse`,
                image: chiefDarkHorse,
            },
            {
                id:2,
                title: `Chief Bobber Dark Horse`,
                image: chiefbobberdarkhorse,
            },
            {
                id:3,
                title: `Super Chief Limited`,
                image: superchieflimited,
            },
            {
                id:4,
                title: `Sport Chief`,
                image: sportchief,
            },
        ],
    },
    {
        id: 5,
        family: `Bagger`,
        footer: `All Bagger`,
        members: [
            {
                id:1,
                title: `Indian Springfield`,
                image: indianSpringfield,
            },
            {
                id:2,
                title: `Indian Springfield Dark Horse`,
                image: indianspringfielddarkhorse,
            },
            {
                id:3,
                title: `Chieftain Dark Horse`,
                image: chieftaindarkhorse,
            },
            {
                id:4,
                title: `Chieftain Limited`,
                image: chieftainlimited,
            },
            {
                id:5,
                title: `Challenger Limited`,
                image: challengerlimited,
            },
            {
                id:6,
                title: `Challenger Dark Horse`,
                image: challengerdarkhorse,
            },
        ],
    },
    {
        id: 6,
        family: `Touring`,
        footer: `All Touring`,
        members: [
            {
                id:1,
                title: `Roadmaster`,
                image: roadmaster,
            },
            {
                id:2,
                title: `Roadmaster Dark Horse`,
                image: roadmasterdarkhorse,
            },
            {
                id:3,
                title: `Roadmaster Limited`,
                image: roadmasterlimited,
            },
            {
                id:4,
                title: `Indian Pursuit Dark Horse`,
                image: indianpursuitdarkhorse,
            },
            {
                id: 5,
                title: `Pursuit Limited`,
                image: pursuitlimited
            }
        ],
    },
    {
        id: 7,
        family: `Elite`,
        footer: `All Elite`,
        members: [
            {
                id: 1,
                title: `Indian Challenger Elite`,
                image: indianchallengerelite,
            },
            {
                id: 2,
                title: `Indian Roadmaster Elite`,
                image: indianroadmasterelite,
            }
        ]
    }
]

export { MotorcyclesTestRideDropdownFamily};