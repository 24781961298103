const BuildYours = `https://www.indianmotorcycle.com/en-001/build-model/`;
const TestRide = `/test-ride`;
const BuildYoursText = `Направете по желба`;
const TestRideText = `Закажете тест возење`;
const soundHeadingText = `ПОЧУСТВУВАЈТЕ ГО ФЕНОМЕНАЛНИОТ ЗВУК НА МОТОРОТ FTR V-TWIN!`;
const innerSoundText = `Без разлика дали работи во лер или забрзува до брзина, моќниот мотор на FTR го истакнува стилот на мотоциклот. Не заборавајте да ја зголемите јачината на звукот!`;
const innerInformationText = `без вклучено ДДВ`;
const topWarningMessage = `*Може да се прикаже со дополнителни модификации и/или додатоци`;
const ContactUsLink = `/contact-us`;
const ContactUsText = `Контакт`;
const InstagramLink = `https://www.instagram.com/indianmotorcycle.mk/`;
const ApparelLink = `https://shop-indianmotorcycle.com/ie/new.html`;


const BuildYourOwnLinks = [
    {
        id: 1,
        name: `FTR R Carbon`,
        link: `https://www.indianmotorcycle.com/en-001/build/?selectedmodel=standard&CatalogContentId=723924__CatalogContent`
    },
    {
        id: 2,
        name: `FTR Rally`,
        link: `https://www.indianmotorcycle.com/en-001/build/?selectedmodel=standard&CatalogContentId=723926__CatalogContent`
    },
    {
        id: 3,
        name: `FTR Sport`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=standard&CatalogContentId=723920__CatalogContent`
    },
    {
        id: 4,
        name: `FTR`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=standard&CatalogContentId=723917__CatalogContent`
    },
    {
        id: 5,
        name: `Scout`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=cruiser&CatalogContentId=723939__CatalogContent`
    },
    {
        id: 6,
        name: `Scout Bobber`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=cruiser&CatalogContentId=723967__CatalogContent`
    },
    {
        id: 7,
        name: `Scout Rogue`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=cruiser&CatalogContentId=723987__CatalogContent`
    },
    {
        id: 8,
        name: `Scout Bobber Twenty`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=cruiser&CatalogContentId=723978__CatalogContent`
    },
    {
        id: 9,
        name: `Chief Dark Horse`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=cruiser&CatalogContentId=724006__CatalogContent`
    },
    {
        id: 10,
        name: `Chief Bobber Dark Horse`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=cruiser&CatalogContentId=724016__CatalogContent`
    },
    {
        id: 11,
        name: `Sport Chief`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=cruiser&CatalogContentId=756585__CatalogContent`
    },
    {
        id: 12,
        name: `Super Chief Limited`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=bagger&CatalogContentId=725033__CatalogContent`
    },
    {
        id: 13,
        name: `Indian Springfield`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=bagger&CatalogContentId=725039__CatalogContent`
    },
    {
        id: 14,
        name: `Indian Springfield Dark Horse`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=bagger&CatalogContentId=725043__CatalogContent`
    },
    {
        id: 15,
        name: `Chieftain Limited`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=bagger&CatalogContentId=725059__CatalogContent`
    },
    {
        id: 16,
        name: `Chieftain Dark Horse`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=bagger&CatalogContentId=725050__CatalogContent`
    },
    {
        id: 17,
        name: `Indian Challenger Limited`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=bagger&CatalogContentId=725073__CatalogContent`
    },
    {
        id: 18,
        name: `Indian Challenger Dark Horse`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=bagger&CatalogContentId=725068__CatalogContent`
    },
    {
        id: 19,
        name: `Roadmaster`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=touring&CatalogContentId=725084__CatalogContent`
    },
    {
        id: 20,
        name: `Roadmaster Limited`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=touring&CatalogContentId=725093__CatalogContent`
    },
    {
        id: 21,
        name: `Roadmaster Dark Horse`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=touring&CatalogContentId=725088__CatalogContent`
    },
    {
        id: 22,
        name: `Indian Pursuit Limited`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=touring&CatalogContentId=725111__CatalogContent`
    },
    {
        id: 23,
        name: `Indian Pursuit Dark Horse`,
        link: `https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=touring&CatalogContentId=725098__CatalogContent`
    },
    {
        id: 24,
        name: `Challenger Elite`,
        link: `https://www.indianmotorcycle.com/en-us/build/?CatalogContentId=725082__CatalogContent`
    },
]

export { BuildYourOwnLinks };
export { ApparelLink, InstagramLink, BuildYours, TestRide, BuildYoursText, TestRideText, soundHeadingText,  innerSoundText, innerInformationText, topWarningMessage, ContactUsLink, ContactUsText};