const CruiserOverviewDetails = [
    {
        id: 1,
        title: `Класичен стил`,
        description: `Ова се мотоциклите што ги гледате кога ќе ги затворите очите: совршени пропорции и врвни завршетоци.`
    },
    {
        id: 2,
        title: `Лесно возење`,
        description: `Мазната моќ се комбинира со лесната рамка и ниското седиште за да ги направи овие мотоцикли достапни, дури и за помали или нови возачи.`
    },
    {
        id: 3,
        title: `Забава како ниеден друг`,
        description: `Cruiser се дефинирани со моќни мотори во минимално пакување. Тие се одлични опции за нови возачи, но сепак возбудливи за искусни професионалци.`
    },
    {
        id: 4,
        title: `Личен допир`,
        description: `Крузерот може да се персонализира за да биде токму мотоциклот што го сакате, со низа корисни и стилски додатоци како што се издувни гасови, седишта, складиште и ретровизори.`
    }
]

export default CruiserOverviewDetails;