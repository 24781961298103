import { Link } from "react-router-dom";

const Parallax = () => {
    return (
        <div className="home_parallax">
                <div className="parallax_inner">
                    <h2>Пребарајте ги нашите локации</h2>
                    <Link to='/find-a-dealer/map-view/'>Пребарај</Link>
                </div>
            </div>
    )
}

export default Parallax;