import '../Main Style/header.css';
import headerlogo from '../Main Images/indianmotorcycle-logo.webp';
import { Link } from "react-router-dom";
import MotorcyclesDropdown from './MotorcyclesDropdown';
import { useRef, useState } from 'react';
import TopNavigationSection from '../Main Components/TopNavigationSection';
import About from './About';
import { RxHamburgerMenu } from 'react-icons/rx';
import { IoClose } from 'react-icons/io5';

const Header = () => {


    const navigationList = useRef();

    const [overlay, setOverlay] = useState(false);
    const [hamMenu, setHamMenu] = useState(true);
    const [popUp, setPopUp] = useState(false);

    const handleNotClosingDropdown = (event) => {
        event.stopPropagation();
      };

      const handleOverlay = () => {
        setOverlay(!overlay);
      }

      const handleOverlayClick = () => {
        setOverlay(false);
      }

      const handleHamMenu = () => {
        const navList = navigationList.current;
        hamMenu ? navList.classList.add('hamMenu-open') : navList.classList.remove('hamMenu-open');
        setHamMenu(!hamMenu);
      }

    return (
      <>
      {/* {overlay&&!popUp&&<div className='overlay' onClick={handleOverlayClick}></div>} */}
      <header>
      <TopNavigationSection/>
      <div className='header'>
      <div className='header_logo'>
              <a href="/"><img src={headerlogo} alt='' /></a>
              {
                hamMenu ? <RxHamburgerMenu onClick={handleHamMenu}/> : <IoClose onClick={handleHamMenu}/>
              }
          </div>
          <div className='header_navigation' ref={navigationList}>
              <ul>
                  <li><Link to="/find-a-dealer/map-view/">локација</Link></li>
                  <li className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleOverlay}>мотоцикли
                  </li>
                  <ul className={popUp ? `dropdown-menu dropdown_fixed dropdown_hidden` : `dropdown-menu dropdown_fixed`} aria-labelledby="dropdownMenuButton1" onClick={handleNotClosingDropdown}>
                     <MotorcyclesDropdown popUp={popUp} setPopUp={setPopUp}/>
                  </ul>
                  <li><a href='https://shop-indianmotorcycle.com/ie/'>облека</a></li>
                  <li><Link to='/test-ride'>Тест возење</Link></li>
                  <li className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleOverlay}>За нас</li>
                  <ul className={popUp ? `dropdown-menu about_dropdown dropdown_fixed dropdown_hidden` : `dropdown-menu about_dropdown dropdown_fixed`} aria-labelledby="dropdownMenuButton1" onClick={handleNotClosingDropdown}>
                     <About popUp={popUp} setPopUp={setPopUp}/>
                  </ul>
              </ul>
          </div>
      </div>
         
      </header>
      </>
    )
}

export default Header;

