import { Link } from "react-router-dom";


const SingleMTFMotorcycle = ({motorcycle}) => {
    return (
        <div className="single_mtf_motorcycle">
            <div>
                <img src={motorcycle.image} alt=""></img>
            </div>
            <h3>{motorcycle.title}</h3>
            <div className="price_wrapper">
                <span>Веќе од <span>{motorcycle.price} без вклучено ДДВ</span></span>
            </div>
            <div className="button_wrapper">
                {/* <Link to={motorcycle.link}>Повеќе за {motorcycle.title}</Link> */}
                <Link to={motorcycle.link}>Повеќе информации</Link>
            </div>
        </div>
    )
}

export default SingleMTFMotorcycle;