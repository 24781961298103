import { useEffect, useState } from "react";
import Table from "./Table";

const Specifications = ({specificationsData}) => {

    const [currentSpecs, setCurrentSpecs] = useState(1);
    const [specsData, setSpecsData] = useState();
    const [engineData, setEngineData] = useState();
    const [drivechainData, setDrivechainData] = useState();
    const [chasisData, setChasisData] = useState();
    const [dimensionsData, setDimensionsData] = useState();
    const [standardData, setStandardData] = useState();

    const handleCurrentSpecs = (num) => {
        setCurrentSpecs(num);
        if(num === 1) {
            setSpecsData(engineData);
        }
        else if(num === 2) {
            setSpecsData(drivechainData);
        }
        else if(num === 3) {
            setSpecsData(chasisData);
        }
        else if(num === 4) {
            setSpecsData(dimensionsData);
        }
        else {
            setSpecsData(standardData);
        }
    }

    useEffect(() => {
        setEngineData(specificationsData[0].engine)
        setDrivechainData(specificationsData[0].drivechain);
        setChasisData(specificationsData[0].chasis);
        setDimensionsData(specificationsData[0].dimensions);
        setStandardData(specificationsData[0].standard);
        setSpecsData(specificationsData[0].engine);
    }, [])


    return (
        <>
        <h1 className="specs_heading">Спецификации</h1>
         <div className="specs_list">
            <ul>
                <li className={currentSpecs===1 ? `currentTab` : `normalTab`} onClick={() => handleCurrentSpecs(1)}>МОТОР</li>
                <li className={currentSpecs===2 ? `currentTab` : `normalTab`} onClick={() => handleCurrentSpecs(2)}>МЕНУВАЧ</li>
                <li className={currentSpecs===3 ? `currentTab` : `normalTab`} onClick={() => handleCurrentSpecs(3)}>ШАСИЈА</li>
                <li className={currentSpecs===4 ? `currentTab` : `normalTab`} onClick={() => handleCurrentSpecs(4)}>ДИМЕНЗИИ</li>
                <li className={currentSpecs===5 ? `currentTab` : `normalTab`} onClick={() => handleCurrentSpecs(5)}>ОПРЕМА</li>
            </ul>
            <Table specsData={specsData}/>
        </div>
        </>
       
    )
}

export default Specifications;