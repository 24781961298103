import cdh1 from '../Carousel Images/chiefdarkhorse-1.webp';
import cdh2 from '../Carousel Images/chiefdarkhorse-2.webp';
import cdh3 from '../Carousel Images/chiefdarkhorse-3.webp';
import cdh4 from '../Carousel Images/chiefdarkhorse-4.webp';
import cdh5 from '../Carousel Images/chiefdarkhorse-5.webp';
import cdh6 from '../Carousel Images/chiefdarkhorse-6.webp';

import cbdh1 from '../Carousel Images/cbdh-1.webp';
import cbdh2 from '../Carousel Images/cbdh-2.webp';
import cbdh3 from '../Carousel Images/cbdh-3.webp';
import cbdh4 from '../Carousel Images/cbdh-4.webp';
import cbdh5 from '../Carousel Images/cbdh-5.webp';
import cbdh6 from '../Carousel Images/cbdh-6.webp';

import sportchief1 from '../Carousel Images/sportchief-1.webp';
import sportchief2 from '../Carousel Images/sportchief-2.webp';
import sportchief3 from '../Carousel Images/sportchief-3.webp';
import sportchief4 from '../Carousel Images/sportchief-4.webp';
import sportchief5 from '../Carousel Images/sportchief-5.webp';
import sportchief6 from '../Carousel Images/sportchief-6.webp';

import scl1 from '../Carousel Images/scl-1.webp';
import scl2 from '../Carousel Images/scl-2.webp';
import scl3 from '../Carousel Images/scl-3.webp';
import scl4 from '../Carousel Images/scl-4.webp';
import scl5 from '../Carousel Images/scl-5.webp';
import scl6 from '../Carousel Images/scl-6.webp';


const ChiefDarkHorseCarouselImages = [
    {
        id: 1,
        image: cdh1
    },
    {
        id: 2,
        image: cdh2
    },
    {
        id: 3,
        image: cdh3
    },
    {
        id: 4,
        image: cdh4
    },
    {
        id: 5,
        image: cdh5
    },
    {
        id: 6,
        image: cdh6
    },
]

export { ChiefDarkHorseCarouselImages }

const CBDHCarouselImages = [
    {
        id: 1,
        image: cbdh1
    },
    {
        id: 2,
        image: cbdh2
    },
    {
        id: 3,
        image: cbdh3
    },
    {
        id: 4,
        image: cbdh4
    },
    {
        id: 5,
        image: cbdh5
    },
    {
        id: 6,
        image: cbdh6
    },
]

export { CBDHCarouselImages }

const SportChiefCarouselImages = [
    {
        id: 1,
        image: sportchief1
    },
    {
        id: 2,
        image: sportchief2
    },
    {
        id: 3,
        image: sportchief3
    },
    {
        id: 4,
        image: sportchief4
    },
    {
        id: 5,
        image: sportchief5
    },
    {
        id: 6,
        image: sportchief6
    },
]

export { SportChiefCarouselImages }

const SuperChiefLimitedCarouselImages = [
    {
        id: 1,
        image: scl1
    },
    {
        id: 2,
        image: scl2
    },
    {
        id: 3,
        image: scl3
    },
    {
        id: 4,
        image: scl4
    },
    {
        id: 5,
        image: scl5
    },
    {
        id: 6,
        image: scl6
    },
]

export { SuperChiefLimitedCarouselImages }