import { NorthMacedoniaDealerInformation } from "../../../Constants/DealersInformation";
import DealerSingleInformation from "./Dealer Components/DealerSingleInformation";
import './dealercountries.css';

const ListNorthMacedonia = () => {
    return (
        <div className="country_dealer_container">
            <div className="country_dealer_wrapper">
                <div className="country_dealer_full_list">
                    {
                        NorthMacedoniaDealerInformation.map((dealer) => (
                            <DealerSingleInformation dealer={dealer}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ListNorthMacedonia;