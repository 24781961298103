import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';


const MotorcycleDropdownFamily = ({family}) => {

    return (
        <div className="motorcycles_family">
            <div className="motorcycles_models">
                {
                    family.members.map((item) => (
                        <div className="family_single_member" key={item.id}>
                            <Link to={item.link}>
                            <img alt="" src={item.image}></img>
                            <p>{item.title}</p>
                            </Link>
                            
                        </div>
                    ))
                }
            </div>
            {
                family.footer&&
                <div className="motorcycles_family_bottom">
                    <p><Link to={family.link}>{family.footer} <IoIosArrowForward/></Link></p>
            </div>
            }
            
        </div>
    )
}

export default MotorcycleDropdownFamily;