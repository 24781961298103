import Header from "../../../../Main Components/Header";
import FTRBackground from './FTR Family Images/ftr-background.webp';
import '../singlemotorcyclestyle.css';
import HeroHeading from "../../components/HeroHeading";
import Overview from "../../components/Overview";
import FTROverviewDetails from "./FTROverviewDetails";
import FTRVideoOne from './FTR Family Images/FTR-family-eu.mp4';
import firstVideoPoster from './FTR Family Images/first-video-poster.webp';
import FTRVideoSecond from './FTR Family Images/FTR-second-video.mp4';
import secondVideoPoster from './FTR Family Images/second-video-poster.webp';
import MeetTheFamily from "../../components/MeetTheFamily";
import FTRMTFDetails from './FTRMTFDetails';
import './ftrstyle.css';
import historylogo from './FTR Family Images/logo.webp';
import leftimage from './FTR Family Images/image-left.webp';
import rightimage from './FTR Family Images/image-right.webp';
import historyBottomImage from './FTR Family Images/ftr.webp';
import bloglist from '../../../../Home Page/constants/bloglist';
import { useEffect, useState } from "react";
import Parallax from "../../../../Main Components/Parallax";
import Footer from '../../../../Main Components/Footer';

const FtrFamily = () => {

    const heroTitle = `FTR`;
    const heroDescription = `
    Овие мотори се моќни, но агилни. Со исправена и удобна положба за возење, овие мотори обезбедуваат агилно управување што е погодно за ветер низ планинските патишта, движење низ градот или патување со стил.`;

    const [sortedList, setSortedList] = useState();

   useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
    const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
    setSortedList(newList);
   }, [])


    return(
        <>
        <Header/>
            <div className="family_background">
                <img src={FTRBackground} alt=""></img>
            </div>
            <HeroHeading heroTitle={heroTitle} heroDescription={heroDescription}/>
            <Overview details={FTROverviewDetails}/>
            <div className="family_videos">
                <video controls controlslist="nodownload noremoteplayback" playsinline="" poster={firstVideoPoster} preload>
                    <source src={FTRVideoOne} type="video/mp4"/>
                </video>
                <video class='family_second_video' controls controlslist="nodownload noremoteplayback" playsinline="" poster={secondVideoPoster} preload>
                    <source src={FTRVideoSecond} type="video/mp4"/>
                </video>
            </div>
            <MeetTheFamily mtfdetails={FTRMTFDetails}/>
            <div className="ftr_history">
                <div className="ftr_history_container">
                    <div className="ftr_history_logo">
                        <img src={historylogo} alt=""></img>
                    </div>
                    <div className="ftr_history_text">
                        <h3>Врската помеѓу <br/>стилот и перфомансите</h3>
                        <p>Со ДНК докажана во American Flat Track, наследството продолжува со повеќе доусовршување на иконската платформа FTR - изведба на улица, пристапност и контрола на сосема ново ниво. Овој неспоредлив американски V-twin чека да биде вашето следно бегство за викенд</p>
                    </div>
                    <div className="ftr_history_images">
                        <div className="ftr_left_image">
                            <img src={leftimage} alt=""></img>
                        </div>
                        <div className="ftr_right_image">
                            <img src={rightimage} alt=""></img>
                        </div>
                    </div>
                    <div className="ftr_history_bottom">
                        <div className="ftr_history_bottom_inner">
                            <h3>Изгледајте брзо</h3>
                            <p>Фамилијата FTR се одликува со смислено рафиниран мотор, суспензија наместена за улица и гуми и тркала од 17" заедно обезбедуваат перформанси од светска класа додека го прават моторот да се чувствува покомпактен и поманеврирачки.</p>
                        </div>
                        <img src={historyBottomImage} alt=""></img>
                    </div>
                </div>
            </div>
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>

    )
}

export default FtrFamily;