const BaggerOverviewDetails = [
    {
        id: 1,
        title: `Суштинска моќ`,
        description: `Bagger's доминираат со многу километри со мотори со голема зафатнина кои може да ги изберете со воздушно или течно ладење.`
    },
    {
        id: 2,
        title: `Личен допир`,
        description: `Достапни во широк спектар на стилови, Bagger's им дозволуваат на возачите да изберат што било, од дизајн инспириран од наследството до модерно ремек дело за да одговараат на нивниот вкус.`
    },
    {
        id: 3,
        title: `Простор за Вашата опрема`,
        description: `Нудат многу простор за ставање на опрема, товар или нешто друго што ви е потребно за да појдете на пат.`
    },
    {
        id: 4,
        title: `Заштита од ветер`,
        description: `Со приспособливиот избор на облоги и шофершајбни монтирани на рамка и вилушка, Bagger's ве спречуваат да имате "проблем" со ветерот.`
    }
]

export default BaggerOverviewDetails;