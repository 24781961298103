import IndianChallengerEliteBlackCandy from '../../../Single Motorcycle/Elite Motorcycles/Elite Motorcycles Images/IndianChallengerEliteBlackCandy.png';
import IndianChallengerEliteBlackCandyIcon from '../../../Single Motorcycle/Elite Motorcycles/Elite Motorcycles Images/IndianChallengerEliteBlackCandyIcon.png';
import IndianRoadmasterEliteRedCandy from '../../../Single Motorcycle/Elite Motorcycles/Elite Motorcycles Images/IndianRoadmasterEliteRedCandy.png';
import IndianRoadmasterEliteRedCandyIcon from '../../../Single Motorcycle/Elite Motorcycles/Elite Motorcycles Images/IndianRoadmasterEliteRedCandyIcon.png';


const ELITEMTFDetails = [
    {
        id: 1,
        image: IndianChallengerEliteBlackCandy,
        title: `Indian Challenger Elite`,
        price: `41 890 €`,
        link: `/indian-challenger-elite`,
        members: [
            {
                color: `Charcoal Candy Black/Black Candy`,
                price: `41 890 €`,
                icon: IndianChallengerEliteBlackCandyIcon,
                image: IndianChallengerEliteBlackCandy
            }
        ]
    },
    {
        id: 2,
        image: IndianRoadmasterEliteRedCandy,
        title: `Indian Roadmaster Elite`,
        price: `44 990 €`,
        link: `/indian-roadmaster-elite`,
        members: [
            {
                color: `Charcoal Candy Black/Black Candy`,
                price: `44 990 €`,
                icon: IndianRoadmasterEliteRedCandyIcon,
                image: IndianRoadmasterEliteRedCandy
            }
        ]
    },
]

export default ELITEMTFDetails;