import VideoModal from "./VideoModal";

const FeaturedItem = ({singleItem}) => {

    return (
        <div className="featureditem">
            {
                singleItem.video ? (<VideoModal image={singleItem.image} video={singleItem.video} videoID={singleItem.videoID}/>) : (<img alt="" src={singleItem.image}></img>)
            }
            
            <h3>{singleItem.title}</h3>
            <p>{singleItem.description}</p>
        </div>
    )
}

export default FeaturedItem;