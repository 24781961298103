import item1 from '../History Images/eras-item-1.webp';
import item2 from '../History Images/eras-item-2.webp';
import item3 from '../History Images/eras-item-3.webp';

const historycaleras = [
    {
        id: 1,
        image: item1,
        title: `Becoming Legendary `,
        date: `1897 - 1952`,
        link: `/becoming-legendary`
    },
    {
        id: 2,
        image: item2,
        title: `Tumultuous Times and the Fight for Survival `,
        date: `1953 - 2010 `,
        link: `/tumultuous-times`
    },
    {
        id: 3,
        image: item3,
        title: `Indian Motorcycle Returns Stronger Than Ever `,
        date: `2011 and Beyond `,
        link: `/2011-and-beyond`
    }
]

export default historycaleras;