import Header from "../../../../Main Components/Header";
import HeroHeading from "../../components/HeroHeading";
import MeetTheFamily from "../../components/MeetTheFamily";
import Overview from "../../components/Overview";
import DarkHorseBackground from './Dark Horse Family Images/darkhorse-background.webp'
import DarkHorseMTFDetails from "./DarkHorseMTFDetails";
import DarkHorseOverviewDetails from "./DarkHorseOverviewDetails";
import DarkHorseHistoryLogo from './Dark Horse Family Images/darkhorse-history-logo.webp';
import DarkHorseHistoryDouble from './Dark Horse Family Images/darkhorse-history-double.webp';
import './darkhorsestyle.css';
import bloglist from "../../../../Home Page/constants/bloglist";
import Parallax from "../../../../Main Components/Parallax";
import Footer from "../../../../Main Components/Footer";
import { useEffect, useState } from "react";


const DarkHorseFamily = () => {

    const heroTitle = `DARK HORSE`;
    const heroDescription = `Агресивен стајлинг покриен со црни завршетоци. Семејството Dark Horse има затемнет, агресивен стајлинг што ќе ве натера да тргнете на пат.`;

    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
       }, [])


    return (
        <>
            <Header/>
            <div className="family_background">
                <img src={DarkHorseBackground} alt=""></img>
            </div>
            <HeroHeading heroTitle={heroTitle} heroDescription={heroDescription}/>
            <Overview details={DarkHorseOverviewDetails}/>
            <MeetTheFamily mtfdetails={DarkHorseMTFDetails}/>
            <div className="darkhorse_history">
                <div className="darkhorse_history_container">
                    <div className="darkhorse_history_logo">
                        <img src={DarkHorseHistoryLogo} alt=""></img>
                    </div>
                    <div className="darkhorse_history_text">
                        <h3>Дизајн што одзема здив</h3>
                        <p>Семејството на Dark Horse е сила на "природата" што нема да сакате да ја избегнете. Со неговиот затемнет мотор, мат и сјајно црно премиум завршетоци и елегантниот дизајн, го привлекува вашето внимание од која било далечина.</p>
                    </div>
                    <div className="darkhorse_history_images">
                        <img src={DarkHorseHistoryDouble} alt=""/>
                    </div>
                </div>
        </div>
        <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default DarkHorseFamily;