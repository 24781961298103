const RacingHero = ({title, description, subject, bbflag}) => {
    return (
        <div className={bbflag ? `racing_hero hero_reverse` : `racing_hero`}>
            <div className="racing_hero_inner">
                <h1 className="racing_hero_title">{title}</h1>
                <p className="racing_hero_description">{description}</p>
            </div>
            {
                bbflag ? <div className="racing_hero_inner">
                <div dangerouslySetInnerHTML={{ __html: subject }} />
                </div> : <img src={subject} alt=""/>
                
            }
           
            
            
        </div>
    )
}

export default RacingHero;