const CarouselImages = ({ images }) => {

    return (
        <div className="carousel_images">
        <h1>Галерија</h1>
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
                {
                    images.map((img) => (
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={img.id-1} class={img.id===1 ? `active` : ``} aria-current={img.id===1 ? `true` : ``} aria-label={`Slide` + img.id}></button>
                    ))
                }
            </div>
            <div class="carousel-inner">
                {
                    images.map((img) => (
                        <div class={img.id === 1 ? `carousel-item active` : `carousel-item`}>
                            <img src={img.image} alt="" style={{width: '100%'}}></img>
                        </div>
                    ))
                }
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
            </div>
        </div>
    )
}

export default CarouselImages;