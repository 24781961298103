import scoutclassic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/scout-classic.jpg'
import scoutbobber from  '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/scout-bobber.jpg';
import sportscout from  '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/sport-scout.jpg';
import superscout from  '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/super-scout.jpg';
import scout101 from  '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/101-scout.jpg';

import ScoutClassicBlackMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutClassicBlackMetallicIcon.jpg';
import ScoutClassicSilverQuartz from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutClassicSilverQuartz.jpg';
import ScoutClassicSilverQuartzIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutClassicSilverQuartzIcon.jpg';
import ScoutClassicGhostWhiteMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutClassicGhostWhiteMetallic.jpg';
import ScoutClassicGhostWhiteMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutClassicGhostWhiteMetallicIcon.jpg';
import ScoutClassicSunsetRedMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutClassicSunsetRedMetallic.jpg';
import ScoutClassicSunsetRedMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutClassicSunsetRedMetallicIcon.jpg';

import ScoutBobberBlackMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBlackMetallic.jpg';
import ScoutBobberBlackMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBlackMetallicIcon.jpg';
import ScoutBobberBlackSmoke from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBlackSmoke.jpg';
import ScoutBobberBlackSmokeIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBlackSmokeIcon.jpg';
import ScoutBobberRedSmoke from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberRedSmoke.jpg';
import ScoutBobberRedSmokeIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberRedSmokeIcon.jpg';
import ScoutBobberBlackSmoke2 from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBlackSmoke2.jpg';
import ScoutBobberBlueMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBlueMetallic.jpg';
import ScoutBobberBlueMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBlueMetallicIcon.jpg';
import ScoutBobberBronzeMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBronzeMetallic.jpg';
import ScoutBobberBronzeMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBronzeMetallicIcon.jpg';

import SportScoutBlackMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SportScoutBlackMetallic.jpg';
import SportScoutBlackMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SportScoutBlackMetallicIcon.jpg';
import SportScoutBlackSmoke from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SportScoutBlackSmoke.jpg';
import SportScoutBlackSmokeIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBlackSmokeIcon.jpg';
import SportScoutStormBlue from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SportScoutStormBlue.jpg';
import SportScoutStormBlueIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SportScoutStormBlueIcon.jpg';
import SportScoutBronzeMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SportScoutBronzeMetallic.jpg';
import SportScoutBronzeMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/ScoutBobberBronzeMetallicIcon.jpg';

import SuperScoutBlackSmoke from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SuperScoutBlackSmoke.jpg';
import SuperScoutBlackSmokeIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SuperScoutBlackSmokeIcon.jpg';
import SuperScoutMaroonMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SuperScoutMaroonMetallic.jpg';
import SuperScoutMaroonMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/SuperScoutMaroonMetallicIcon.jpg';

import Scout101RedMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/Scout101RedMetallic.jpg';
import Scout101RedMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/Scout101RedMetallicIcon.jpg';
import Scout101WhiteMetallic from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/Scout101WhiteMetallic.jpg';
import Scout101WhiteMetallicIcon from '../../../Single Motorcycle/Scout 1250cc Motorcycles/Scout 1250cc Motorcycles Images/Scout101WhiteMetallicIcon.jpg';

const Scout1250ccMTFDetails = [
    {
        id: 1,
        image: scoutclassic,
        title: `Scout Classic`,
        price: `15 690 €`,
        link: `/scout-classic-1250cc`,
        members: [
            {
                id: 1,
                name: `SCOUT CLASSIC`,
                items: [
                    {
                        color: `Black Metallic`,
                        price: `15 690 €`,
                        icon: ScoutClassicBlackMetallicIcon,
                        image: scoutclassic
                    },
                ]
            },
            {
                id: 2,
                name: `SCOUT CLASSIC LIMITED`,
                items: [
                    {
                        color: `Silver Quartz Smoke`,
                        price: `16 690 €`,
                        icon: ScoutClassicSilverQuartzIcon,
                        image: ScoutClassicSilverQuartz
                    }
                ]
            },
            {
                id: 3,
                name: `SCOUT CLASSIC LIMITED + TECH`,
                items: [
                    {
                        color: `Ghost White Metallic`,
                        price: `17 990 €`,
                        icon: ScoutClassicGhostWhiteMetallicIcon,
                        image: ScoutClassicGhostWhiteMetallic
                    },
                    {
                        color: `Sunset Red Metallic`,
                        price: `17 990 €`,
                        icon: ScoutClassicSunsetRedMetallicIcon,
                        image: ScoutClassicSunsetRedMetallic
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        image: scoutbobber,
        title: `Scout Bobber`,
        price: `15 390 €`,
        link: `/scout-bobber-1250cc`,
        members: [
            {
                id: 1,
                name: `SCOUT BOBBER`,
                items: [
                    {
                        color: `Black Metallic`,
                        price: `15 390 €`,
                        icon: ScoutBobberBlackMetallicIcon,
                        image: ScoutBobberBlackMetallic
                    },
                ]
            },
            {
                id: 2,
                name: `SCOUT BOBBER LIMITED`,
                items: [
                    {
                        color: `Black Smoke`,
                        price: `16 390 €`,
                        icon: ScoutBobberBlackSmokeIcon,
                        image: ScoutBobberBlackSmoke
                    },
                    
                    {
                        color: `Sunset Red Smoke`,
                        price: `16 390 €`,
                        icon: ScoutBobberRedSmokeIcon,
                        image: ScoutBobberRedSmoke
                    }
                ]
            },
            {
                id: 3,
                name: `SCOUT BOBBER LIMITED + TECH`,
                items: [
                    {
                        color: `Black Smoke`,
                        price: `17 390 €`,
                        icon: ScoutBobberBlackSmokeIcon,
                        image: ScoutBobberBlackSmoke2
                    },
                    {
                        color: `Spirit Blue Metallic`,
                        price: `17 690 €`,
                        icon: ScoutBobberBlueMetallicIcon,
                        image: ScoutBobberBlueMetallic
                    },
                    {
                        color: `Nara Bronze Metallic`,
                        price: `17 690 €`,
                        icon: ScoutBobberBronzeMetallicIcon,
                        image: ScoutBobberBronzeMetallic
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        image: sportscout,
        title: `Sport Scout`,
        price: `15 690 €`,
        link: `/sport-scout-1250cc`,
        members: [
            {
                id: 1,
                name: `SPORT SCOUT`,
                items: [
                    {
                        color: `Black Metallic`,
                        price: `15 690 €`,
                        icon: SportScoutBlackMetallicIcon,
                        image: SportScoutBlackMetallic
                    },
                ]
            },
            {
                id: 2,
                name: `SPORT SCOUT LIMITED`,
                items: [
                    {
                        color: `Black Smoke`,
                        price: `16 690 €`,
                        icon: SportScoutBlackSmokeIcon,
                        image: SportScoutBlackSmoke
                    },
                    {
                        color: `Storm Blue`,
                        price: `16 690 €`,
                        icon: SportScoutStormBlueIcon,
                        image: SportScoutStormBlue
                    },
                    {
                        color: `Nara Bronze Metallic`,
                        price: `16 690 €`,
                        icon: SportScoutBronzeMetallicIcon,
                        image: SportScoutBronzeMetallic
                    },
                ]
            },
            {
                id: 3,
                name: `SPORT SCOUT LIMITED +TECH`,
                items: [
                    {
                        color: `Black Smoke`,
                        price: `17 690 €`,
                        icon: SportScoutBlackSmokeIcon,
                        image: SportScoutBlackSmoke
                    },
                ]
            },
        ]
    },
    {
        id: 4,
        image: superscout,
        title: `Super Scout`,
        price: `18 990 €`,
        link: `/super-scout-1250cc`,
        members: [
            {
                id: 1,
                name: `SUPER SCOUT`,
                items: [
                    {
                        color: `Black Smoke with Graphics`,
                        price: `18 990 €`,
                        icon: SuperScoutBlackSmokeIcon,
                        image: SuperScoutBlackSmoke
                    },
                    {
                        color: `Maroon Metallic with Graphics`,
                        price: `18 990 €`,
                        icon: SuperScoutMaroonMetallicIcon,
                        image: SuperScoutMaroonMetallic
                    },
                ]
            }
        ]
    },
    {
        id: 5,
        image: scout101,
        title: `101 Scout`,
        price: `18 990 €`,
        link: `/101-scout-1250cc`,
        members: [
            {
                id: 1,
                name: `101 SCOUT`,
                items: [
                    {
                        color: `Sunset Red Metallic with Graphics`,
                        price: `18 990 €`,
                        icon: Scout101RedMetallicIcon,
                        image: Scout101RedMetallic
                    },
                    {
                        color: `Ghost White Metallic with Graphics`,
                        price: `18 990 €`,
                        icon: Scout101WhiteMetallicIcon,
                        image: Scout101WhiteMetallic
                    },
                ]
            }
        ]
    },
]

export default Scout1250ccMTFDetails;