import background from '../Touring Motorcycles Images/feature-bg.webp';

const Feature = () => {
    return (
        <>
        <div className='touring_feature'>
            <img alt='' src={background}></img>
            <div className='touring_feature_inner'>
                <h1>Apple carplay®</h1>
                <h3>Дополнително подобрено искуство во возењето.</h3>
                <p>Корисниците на iPhone® сега можат лесно да пристапат до Apple Music, Maps, да испраќаат пораки со Siri<br/> и повеќе преку екранот Ride Command од 7“ и поддржаните Bluetooth слушалки.<br/>Сè на кориснички интерфејс што корисниците на iPhone веднаш ќе го препознаат.</p>
            </div>
        </div>
        </>
    )
}

export default Feature;