const TouringOverviewDetails = [
    {
        id: 1,
        title: `Целата удобност`,
        description: `Ниска висина на седиштата, облоги и подни даски, амортизирање. Кога возите Touring за турнеи, возите луксузно.`
    },
    {
        id: 2,
        title: `Максимална заштита од ветер`,
        description: `Целосниот додаток на шофершајбната, облогите и спуштањата целосно го изолира возачот од ефектите на ветрот.`
    },
    {
        id: 3,
        title: `Простор за Вашата опрема`,
        description: `багажникот и долниот простор за складирање на облогата нудат речиси неограничен капацитет за носење сè што ви треба.`
    },
    {
        id: 4,
        title: `Врвни перфоманси`,
        description: `V-twin мотори со голема зафатнина опремени со најсовремена технологија обезбедуваат низок вртежен момент и врвна моќ на поминување.`
    }
]

export default TouringOverviewDetails;