import ChiefDarkHorse from './CRUISER Family Images/chief-dh-black-smoke.webp';
import ChiefBobberDarkHorse from './CRUISER Family Images/chief-bobber-dh-black-smoke.webp';
import SportChief from './CRUISER Family Images/sport-chief-black.webp';
import SuperChiefLimited from './CRUISER Family Images/super-chief-limited-black.webp';
import CDHBlackSmoke from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefDarkHorseBlackSmoke.webp';
import CDHBlackSmokeIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefDarkHorseBlackSmokeIcon.png';
import CDHBlueSmoke from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefDarkHorseBlueSmoke.jpg';
import CDHBlueSmokeIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefDarkHorseBlueSmokeIcon.png';
import CDHRedMetallic from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefDarkHorseRedMetallic.jpg';
import CDHRedMetallicIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefDarkHorseRedMetallicIcon.png';
import CBDHBlackSmoke from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefBobberDarkHorseBlackSmoke.webp';
import CBDHStormGray from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefBobberDarkHorseStormGray.jpg';
import CBDHStormGrayIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefBobberDarkHorseStormGrayIcon.png';
import CBDHRedSmoke from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefBobberDarkHorseRedSmoke.jpg';
import CBDHRedSmokeIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/ChiefBobberDarkHorseRedSmokeIcon.png';
import SCLBlackMetallic from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SCLBlackMetallic.webp';
import SCLBlackMetallicIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SCLBlackMetallicIco.webp';
import SCLBlueMetallic from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SCLBlueMetallic.jpg';
import SCLBlueMetallicIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SCLBlueMetallicIcon.png';
import SCLMaroonMetallic from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SCLMaroonMetallic.jpg';
import SCLMaroonMetallicIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SCLMaroonMetallicIcon.png';
import SCBlackSmoke from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SportChiefBlackSmoke.webp';
import SCBlackSmokeIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SportChiefBlackSmokeIcon.webp';
import SCRubySmoke from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SportChiefRuby.webp';
import SCRubySmokeIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SportChiefRubyIcon.webp';
import SCWhiteMetallic from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SportChiefWhiteMetallic.jpg';
import SCWhiteMetallicIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SportChiefWhiteMetallicIcon.png';
import SCGraniteGray from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SportChiefGraniteGray.jpg';
import SCGraniteGrayIcon from '../../../Single Motorcycle/Cruiser Motorcycles/Cruiser Motorcycles Images/SportChiefGraniteGrayIcon.png';

const CruiserMTFDetails = [
    {
        id: 1,
        image: ChiefDarkHorse,
        title: `Chief Dark Horse`,
        price: `19 390 €`,
        link: `/chief-dark-horse`,
        members: [
            {
                color: 'Black Smoke',
                price: `19 390 €`,
                icon: CDHBlackSmokeIcon,
                image: CDHBlackSmoke
            },
            {
                color: 'Springfield Blue Smoke',
                price: `19 890 €`,
                icon: CDHBlueSmokeIcon,
                image: CDHBlueSmoke
            },
            {
                color: 'Sunset Red Metallic',
                price: `19 890 €`,
                icon: CDHRedMetallicIcon,
                image: CDHRedMetallic
            }
        ]
    },
    {
        id: 2,
        image: ChiefBobberDarkHorse,
        title: `Chief Bobber Dark Horse`,
        price: `21 790 €`,
        link: `/chief-bobber-dark-horse`,
        members: [
            {
                color: `Black Smoke`,
                price: `21 790 €`,
                icon: CDHBlackSmokeIcon,
                image: CBDHBlackSmoke
            },
            {
                color: `Storm Gray`,
                price: `22 290 €`,
                icon:  CBDHStormGrayIcon,
                image: CBDHStormGray
            },
            {
                color: `Sunset Red Smoke`,
                price: `22 290 €`,
                icon:  CBDHRedSmokeIcon,
                image: CBDHRedSmoke
            },
        ]
    },
    {
        id: 3,
        image: SportChief,
        title: `Sport Chief`,
        price: `22 490 €`,
        link: `/sport-chief`,
        members: [
            {
                color: `Black Smoke`,
                price: `22 490 €`,
                icon: SCBlackSmokeIcon,
                image: SCBlackSmoke
            },
            {
                color: `Sunset Red Smoke`,
                price: `22 990 €`,
                icon: SCRubySmokeIcon,
                image: SCRubySmoke
            },
            {
                color: `Ghost White Metallic Smoke`,
                price: `22 990 €`,
                icon: SCWhiteMetallicIcon,
                image: SCWhiteMetallic
            },
            {
                color: `Granite Gray`,
                price: `22 990 €`,
                icon: SCGraniteGrayIcon,
                image: SCGraniteGray
            },
        ]
    },
    {
        id: 4,
        image: SuperChiefLimited,
        title: `Super Chief Limited`,
        price: `24 590 €`,
        link: `/super-chief-limited`,
        members: [
            {
                color: `Black Metallic`,
                price: `24 590 €`,
                icon: SCLBlackMetallicIcon,
                image: SCLBlackMetallic
            },
            {
                color: `Spirit Blue Metallic`,
                price: `25 090 €`,
                icon: SCLBlueMetallicIcon,
                image: SCLBlueMetallic
            },
            {
                color: `Maroon Metallic`,
                price: `25 090 €`,
                icon: SCLMaroonMetallicIcon,
                image: SCLMaroonMetallic
            }
        ]
    },
]

export default CruiserMTFDetails;