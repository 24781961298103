import indianspringfield1 from '../Bagger Motorcycles Images/indianspringfield-item-1.webp';
import indianspringfield2 from '../Bagger Motorcycles Images/indianspringfield-item-2.webp';
import indianspringfield3 from '../Bagger Motorcycles Images/indianspringfield-item-3.webp';
import indianspringfield4 from '../Bagger Motorcycles Images/indianspringfield-item-4.webp';
import indianspringfield5 from '../Bagger Motorcycles Images/indianspringfield-item-5.webp';
import indianspringfield6 from '../Bagger Motorcycles Images/indianspringfield-item-6.webp';
import indianspringfield7 from '../Bagger Motorcycles Images/indianspringfield-item-7.webp';



import isdh1 from '../Bagger Motorcycles Images/isdh-item-1.webp';
import isdh2 from '../Bagger Motorcycles Images/isdh-item-2.webp';
import isdh3 from '../Bagger Motorcycles Images/isdh-item-3.webp';
import isdh4 from '../Bagger Motorcycles Images/isdh-item-4.webp';
import isdh5 from '../Bagger Motorcycles Images/isdh-item-5.webp';
import isdh6 from '../Bagger Motorcycles Images/isdh-item-6.webp';
import isdh7 from '../Bagger Motorcycles Images/isdh-item-7.webp';
import isdh8 from '../Bagger Motorcycles Images/isdh-item-8.webp';
import isdh9 from '../Bagger Motorcycles Images/isdh-item-9.webp';



import cheiftainlimited1 from '../Bagger Motorcycles Images/chieftainlimited-item-1.webp';
import cheiftainlimited2 from '../Bagger Motorcycles Images/chieftainlimited-item-2.webp';
import cheiftainlimited3 from '../Bagger Motorcycles Images/chieftainlimited-item-3.webp';
import cheiftainlimited4 from '../Bagger Motorcycles Images/chieftainlimited-item-4.webp';
import cheiftainlimited5 from '../Bagger Motorcycles Images/chieftainlimited-item-5.webp';
import cheiftainlimited6 from '../Bagger Motorcycles Images/chieftainlimited-item-6.webp';
import cheiftainlimited7 from '../Bagger Motorcycles Images/chieftainlimited-item-7.webp';
import cheiftainlimited8 from '../Bagger Motorcycles Images/chieftainlimited-item-8.webp';
import cheiftainlimited9 from '../Bagger Motorcycles Images/chieftainlimited-item-9.webp';

import cdh1 from '../Bagger Motorcycles Images/CDH-item-1.webp';
import cdh2 from '../Bagger Motorcycles Images/CDH-item-2.webp';
import cdh3 from '../Bagger Motorcycles Images/CDH-item-3.webp';
import cdh4 from '../Bagger Motorcycles Images/CDH-item-4.webp';
import cdh5 from '../Bagger Motorcycles Images/CDH-item-5.webp';
import cdh6 from '../Bagger Motorcycles Images/CDH-item-6.webp';
import cdh7 from '../Bagger Motorcycles Images/CDH-item-7.webp';
import cdh8 from '../Bagger Motorcycles Images/CDH-item-8.webp';
import cdh9 from '../Bagger Motorcycles Images/CDH-item-9.webp';

import icl1 from '../Bagger Motorcycles Images/icl-item-1.webp';
import icl2 from '../Bagger Motorcycles Images/icl-item-2.webp';
import icl3 from '../Bagger Motorcycles Images/icl-item-3.webp';
import icl4 from '../Bagger Motorcycles Images/icl-item-4.webp';
import icl5 from '../Bagger Motorcycles Images/icl-item-5.webp';
import icl6 from '../Bagger Motorcycles Images/icl-item-6.webp';
import icl7 from '../Bagger Motorcycles Images/icl-item-7.webp';
import icl8 from '../Bagger Motorcycles Images/icl-item-8.webp';
import icl9 from '../Bagger Motorcycles Images/icl-item-9.webp';

import icdh1 from '../Bagger Motorcycles Images/icdh-item-1.webp';
import icdh2 from '../Bagger Motorcycles Images/icdh-item-2.webp';
import icdh3 from '../Bagger Motorcycles Images/icdh-item-3.webp';
import icdh4 from '../Bagger Motorcycles Images/icdh-item-4.webp';
import icdh5 from '../Bagger Motorcycles Images/icdh-item-5.webp';
import icdh6 from '../Bagger Motorcycles Images/icdh-item-6.webp';
import icdh7 from '../Bagger Motorcycles Images/icdh-item-7.webp';
import icdh8 from '../Bagger Motorcycles Images/icdh-item-8.webp';
import icdh9 from '../Bagger Motorcycles Images/icdh-item-9.webp';


const IndianSpringfieldFeaturedItems = [
    {
      id: 1,
      mainTitle: `СЕКОЈ ПАТ Е ВАШ.`,
      mainDescription: `Со комбинација на познатата моќност од Thunderstroke 116 V-Twin Engine, инженерството од светска класа и врвните карактеристики за турнеи, Indian Springfield е изграден за долги дестинации.`,
      members: [
        {
          id: 1.1,
          title: `КЛАСИЧЕН V-TWIN СТИЛ`,
          description: `Класичниот американски стил изобилува со икона осветлена покривка која го краси избалансираниот преден браник. Премиум, хромираните детали и завршетоци слични на скапоцени камења привлекуваат внимание.`,
          image: indianspringfield1,
        },
        {
          id: 1.2,
          title: `THUNDERSTROKE 116 V-TWIN`,
          description: `Придвижуван од наградуваниот Thunderstroke 116 со воздушно ладење, кој испорачува вртежен момент од 171 Nm за импресивно забрзување и минлива моќ.`,
          image: indianspringfield2,
        },
        {
          id: 1.3,
          title: `ПРОСТОР`,
          description: `Цврстите торбички за простор со далечинско заклучување обезбедуваат безбедно складирање за викенди или брзи патувања низ градот. Рамката е компатибилна со багажникот за да додаде дополнително складирање доколку е потребно.`,
          image: indianspringfield3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: `Со модерна технологија, долга листа на премиум карактеристики и додатоци, Springfield беше дизајниран за патување со стил и удобност.`,
      members: [
        {
          id: 2.1,
          title: `КОМФОРТ`,
          description: `Проѕирното ветробранско стакло со брзо ослободување управува со протокот на воздух и го намалува заморот, но се одвојува за неколку секунди за да го зачува чистиот и класичен изглед на мотоциклот. Хромираните рачки на автопатот обезбедуваат уште поголема удобност при подолгите патувања.`,
          image: indianspringfield4,
        },
        {
          id: 2.2,
          title: `КАРАКТЕРИСТИКИ`,
          description: `Долга листа на модерни погодности како палење без клуч, контрола на патувањето и USB полнење не значи дека нема компромиси за функционирање во безвременски дизајн.`,
          image: indianspringfield5,
        },
        {
          id: 2.3,
          title: `РЕЖИМИ НА ВОЗЕЊЕ`,
          description: `Изберете помеѓу три режими на возење - Tour, Standard и Sport - за искуство што е приспособено на вашиот стил на возење.`,
          image: indianspringfield6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 3.1,
          title: `ПРИЛАГОДЕН`,
          description: `Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога мотоциклот е запрен за поголема удобност во бавниот сообраќај. Потоа, кога ќе го "стиснете" на гасот, задниот цилиндар беспрекорно се враќа до целосна моќност.`,
          image: indianspringfield7,
        },
        {
          id: 3.2,
          title: ``,
          description: ``,
          image: ``,
        },
        {
          id: 3.3,
          title: ``,
          description: ``,
          image: ``,
        },
      ],
    },
  ];
  
  export { IndianSpringfieldFeaturedItems };


  const IndianSpringfieldDarhHorseFeaturedItems = [
    {
      id: 1,
      mainTitle: `ИЗГРАДЕН ЗА ХРАБРИТЕ`,
      mainDescription: `Indian Springfield Dark Horse е за оние кои сакаат повеќе. Со рационализиран став, моќен V-Twin мотор и злобен стил, вози толку моќно како што изгледа.`,
      members: [
        {
          id: 1.1,
          title: `THUNDERSTROKE 116 V-TWIN`,
          description: `Моторот Thunderstroke 116 со воздушно ладење испорачува вртежен момент од 171 Nm за исклучителна моќност при мали вртежи во минута и непречено возење при секоја брзина.`,
          image: isdh1,
        },
        {
          id: 1.2,
          title: `СРЕДНИ РАЧКИ`,
          description: `Додавајќи прилагоден стил на мотоциклот, средните рачки се стандардни за релаксиран досег што се чувствуваат одлично кога сте на пат.`,
          image: isdh2,
        },
        {
          id: 1.3,
          title: `ПРИЛАГОДЕН ИЗГЛЕД`,
          description: `Инспириран од изгледот на сопствените мотоцикли, отворениот преден браник го изложува предното тркало од 19 инчи со 10 краци со контрастно пресекување.`,
          image: isdh3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: `БАРА ВНИМАНИЕ.`,
      mainDescription: `Со својот стил и дизајн, Indian Springfield Dark Horse е невозможно да се игнорира додека се движи по патот.`,
      members: [
        {
          id: 2.1,
          title: `БАГАЖ`,
          description: `Тврди торби за простор што се заклучуваат со далечински управувач обезбедуваат сигурно складирање за викендите и во комбинација со средните рачки создаваат заканувачки профил.`,
          image: isdh4,
        },
        {
          id: 2.2,
          title: `СТИЛ`,
          description: `Точното внимание на деталите во бојата со врвни мат и сјајни црни завршетоци напред и назад даваат смел приказ што го прави познат по неговото присуство дури и кога не се движи.`,
          image: isdh5,
        },
        {
          id: 2.3,
          title: `КАРАКТЕРИСТИКИ`,
          description: `Springfield Dark Horse доаѓа со функции за подобрување на возењето, како што се, следење на притисокот во гумите, темпомат и палење без клуч.`,
          image: isdh6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: `Наполнет со напредна технологија и карактеристики, Springfield Dark Horse комбинира модерна удобност со непогрешлив стил.`,
      members: [
        {
          id: 3.1,
          title: `РЕЖИМИ НА ВОЗЕЊЕ`,
          description: `Изберете помеѓу три режими на возење - Tour, Standard и Sport - за искуство што е приспособено на вашиот стил на возење.`,
          image: isdh7,
        },
        {
          id: 3.2,
          title: `КОМФОРТ`,
          description: `Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога мотоциклот е запрен за поголема удобност во бавниот сообраќај. Потоа, кога ќе "стиснете" на гасот, задниот цилиндар беспрекорно се враќа до целосна моќност.`,
          image: isdh8,
        },
        {
          id: 3.3,
          title: `МОЌНОСТ`,
          description: `Лесен за ракување и без напор избалансиран, Springfield Dark Horse брзо работи на аглите со својата лесна лиена алуминиумска рамка и подобрениот дизајн на турнеи.`,
          image: isdh9,
        },
      ],
    },
  ];
  
  export { IndianSpringfieldDarhHorseFeaturedItems };


  const ChieftainLimitedFeaturedItems = [
    {
      id: 1,
      mainTitle: `ПРЕЗЕМЕТЕ ГО ЛИДЕРСТВОТО`,
      mainDescription: `Со обемниот пакет на премиум погодности, импресивната моќ на Thunderstroke 116 V-Twin моторот и неспоредливото управување, природно доаѓа за Chieftain Limited.`,
      members: [
        {
          id: 1.1,
          title: `THUNDERSTROKE 116 V-TWIN`,
          description: `Моторот Thunderstroke 116 со воздушно ладење произведува 171 Nm вртежен момент за исклучителна моќност при ниски вртежи во минута и непречено возење при секоја брзина.`,
          image: cheiftainlimited1,
        },
        {
          id: 1.2,
          title: `МОДЕРЕН. АГРЕСИВЕН.`,
          description: `Премиум хромирани завршетоци, елегантна пластика, торби за складирање и седиште во стилот на gunfighter обезбедуваат заповедничко присуство. Спуштениот став и LED осветлувањето од напред до назад го комплетираат изгледот.`,
          image: cheiftainlimited2,
        },
        {
          id: 1.3,
          title: `АГРЕСИВНО СТИЛИЗИРАН`,
          description: `Мотоцикл што изгледа исто толку агресивно како и перформансите, Chieftain Limited се одликува со рестилизирана пластика, торби за складирање, предно тркало 19 инчи и нов амблем.`,
          image: cheiftainlimited3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `ЗВУК`,
          description: `Слушајте ја секоја нота со звучниците со висок излез во ферингот што обезбедуваат кристално чист звук од 100W. И динамичкиот еквилајзер автоматски се прилагодува за бучавата од патот, ветерот и моторот.`,
          image: cheiftainlimited4,
        },
        {
          id: 2.2,
          title: `АГИЛНО РАКУВАЊЕ И КОНТРОЛА`,
          description: `Ниската висина на седиштата, ергономски дизајнираните контроли и лесната лиена алуминиумска рамка се здружуваат за одзивно ракување и агилно возење во кривина - обезбедувајќи возење што инспирира самодоверба за мотоциклистите преку таблата.`,
          image: cheiftainlimited5,
        },
        {
          id: 2.3,
          title: `КОМФОРТ`,
          description: `Изберете помеѓу три режими на возење, Tour, Standard и Sport, за искуство што е приспособено на вашиот стил на возење. Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога мотоциклот е запрен за зголемена удобност во бавниот сообраќај.`,
          image: cheiftainlimited6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: `СЕ ШТО ВИ ТРЕБА И ПОВЕЌЕ`,
      mainDescription: `Chieftain Limited е преполн со сите премиум карактеристики и технологија која е лесна за користење што го носи вашето возење на сосемена ново ниво.`,
      members: [
        {
          id: 3.1,
          title: `ШОФЕРШАЈБНА`,
          description: `Со притискање на копче поставено на рачката, ова ветробранско стакло ќе се подигне или спушти до вашата идеална положба.`,
          image: cheiftainlimited7,
        },
        {
          id: 3.2,
          title: `КАРАКТЕРИСТИКИ`,
          description: `Chieftain Limited стандардно доаѓа со палење без клуч, темпомат, ABS и следење на притисокот во гумите.`,
          image: cheiftainlimited8,
        },
        {
          id: 3.3,
          title: `68+ ЛИТРИ ТОВАРЕН ПРОСТОР`,
          description: `Чувајте го вашиот товар заштитен од елементите со 68+ литри складиште отпорно на атмосферски влијанија во торбички што се заклучуваат.`,
          image: cheiftainlimited9,
        },
      ],
    },
  ];
  
  export { ChieftainLimitedFeaturedItems };


  const ChieftainDarkHorseFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `THUNDERSTROKE 116 V-TWIN`,
          description: `Моторот Thunderstroke 116 со воздушно ладење произведува 171 Nm вртежен момент за исклучителна моќност при ниски вртежи во минута и непречено возење при секоја брзина.`,
          image: cdh1,
        },
        {
          id: 1.2,
          title: `СТИЛ`,
          description: `Затемнети завршетоци, елегантен, торби за складирање и седиште во стилот на gunfighter даваат рационализирано, заповедничко присуство.`,
          image: cdh2,
        },
        {
          id: 1.3,
          title: `МОДЕРЕН. ЖЕСТОК.`,
          description: `LED осветлувањето од напред до назад и спуштената положба го комплетираат изгледот.`,
          image: cdh3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: `НАПРАВЕН ДА НАДМИНЕ`,
      mainDescription: `Chieftain Dark Horse комбинира супериорна моќ и агилно управување за да обезбеди сигурно возење со високи перформанси.`,
      members: [
        {
          id: 2.1,
          title: `ЗВУК`,
          description: `Слушајте ја секоја нота со звучниците со висок излез во ферингот што обезбедуваат кристално чист звук од 100W. И динамичкиот еквилајзер автоматски се прилагодува за бучавата од патот, ветерот и моторот.`,
          image: cdh4,
        },
        {
          id: 2.2,
          title: `АГИЛНО РАКУВАЊЕ И КОНТРОЛА`,
          description: `Ниската висина на седиштата, ергономски дизајнираните контроли и лесната лиена алуминиумска рамка се здружуваат за одзивно ракување и агилно возење во кривина - обезбедувајќи возење што инспирира самодоверба за мотоциклисите преку таблата.`,
          image: cdh5,
        },
        {
          id: 2.3,
          title: `КОМФОРТ`,
          description: `Изберете помеѓу три режими на возење, Tour, Standard и Sport, за искуство што е приспособено на вашиот стил на возење. Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога велосипедот е запрен за зголемена удобност во бавниот сообраќај.`,
          image: cdh6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: `СЕТА ТЕХНОЛОГИЈА ШТО ТИ Е ПОТРЕБНА`,
      mainDescription: `Целосниот пакет на најнова технологија за прилагодување и удобност на секое возење.`,
      members: [
        {
          id: 3.1,
          title: `ШОФЕРШАЈБНА`,
          description: `Со притискање на копче поставено на рачката, ова ветробранско стакло ќе се подигне или спушти до вашата идеална положба.`,
          image: cdh7,
        },
        {
          id: 3.2,
          title: `КАРАКТЕРИСТИКИ`,
          description: `Chieftain Dark Horse стандардно доаѓа со LED осветлување, палење без клуч, темпомат, торби за складирање што се заклучуваат, ABS и контрола на притисокот во гумите.`,
          image: cdh8,
        },
        {
          id: 3.3,
          title: `68+ ЛИТРИ ТОВАРЕН ПРОСТОР`,
          description: `Чувајте го вашиот товар заштитен од елементите со 68+ литри складиште отпорно на атмосферски влијанија во торбички што се заклучуваат.`,
          image: cdh9,
        },
      ],
    },
  ];
  
  export { ChieftainDarkHorseFeaturedItems };


  const IndianChallengerLimitedFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `ДОМИНАЦИЈА`,
          description: `Нашиот PowerPlus мотор со течно ладење е целосно модерен дизајн и ги редефинира американските V-twin перформанси. Водечките во класата 178 Nm вртежен момент и 122 КС доминираат директно од фабриката.`,
          image: icl1,
        },
        {
          id: 1.2,
          title: `ИСПОЛНЕТ СО ПРЕМИУМ СТИЛ`,
          description: `Тргнете на пат со врвни хромирани завршни облоги и торбички за складирање усогласени со боја кои ве прават вас и вашето возење невозможно да ве игнорираат.`,
          image: icl2,
        },
        {
          id: 1.3,
          title: `ПРИМЕНА`,
          description: `Smart Lean Technology™, ве одржува самоуверено приземјени и владее со патот со Bosch IMU со 6 оски, динамичка контрола на тракцијата, ABS со контрола во свиоци и контрола на вртежниот момент.`,
          image: icl3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: `КОНТРОЛИРАН КОМФОРТ`,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `СТИЛ`,
          description: `Агресивниот дизајн со целосно LED осветлување и опасни стилизирани светла обезбедуваат препознатливо лице кое води и во стилот и во функцијата. Хромираните завршетоци и опциите за метална боја придонесуваат за премиум дизајнот.`,
          image: icl4,
        },
        {
          id: 2.2,
          title: `ОСВОЈТЕ ГИ СВИОЦИТЕ`,
          description: `Превртената предна суспензија, лесната рамка од лиено алуминиум и хидраулично прилагодливиот заден шок на Fox® обезбедуваат ракување со свиоците и непопустлива стабилност.`,
          image: icl5,
        },
        {
          id: 2.3,
          title: `ЗВУК`,
          description: `Звучниците од 6,5 инчи кои обезбедуваат кристално чист звук од 100W, додека динамичниот еквилајзер автоматски се прилагодува за бучавата од патот, ветерот и моторот за никогаш да не пропуштите никаков ритам.`,
          image: icl6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: `Возете со врвна самодоверба на Indian Challenger Limited. Тој е целосно натоварен со сета најнова технологија, така што секое возење е исто толку удобно, колку што е возбудливо.`,
      members: [
        {
          id: 3.1,
          title: `СУПЕРИОРНА ЗАПИРНА МОЌ`,
          description: `Сопирачките Brembo® со радијално монтирани спецификации за трка обезбедуваат супериорна моќ на запирање и перформанси пневматиците Metzeler® Cruisetec® нудат врвна влечна сила.`,
          image: icl7,
        },
        {
          id: 3.2,
          title: `КОМФОРТ`,
          description: `Изберете помеѓу три режими на возење, Rain, Standard, and Sport, за искуство што е приспособено на вашиот стил на возење. Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога мотоциклот е запрен за зголемена удобност во бавниот сообраќај.`,
          image: icl8,
        },
        {
          id: 3.3,
          title: `КАРАКТЕРИСТИКИ`,
          description: `Возете удобно подолго време со палење без клуч, тврди торби за складирањ што се заклучуваат со далечинско, контрола на патувањето и USB-порта за полнење.`,
          image: icl9,
        },
      ],
    },
  ];
  
  export { IndianChallengerLimitedFeaturedItems };

  const IndianChallengerDarkHorseFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `ДОМИНАЦИЈА`,
          description: `Нашиот PowerPlus мотор со течно ладење е целосно модерен дизајн и ги редефинира американските V-twin перформанси. Водечките во класата 178 Nm вртежен момент и 122 КС доминираат директно од фабриката.`,
          image: icdh1,
        },
        {
          id: 1.2,
          title: `ОСВОЈТЕ ГИ СВИОЦИТЕ.`,
          description: `Превртената предна суспензија, лесната рамка од лиено алуминиум и хидраулично прилагодливиот заден шок на Fox® обезбедуваат ракување со свиоците и непопустлива стабилност.`,
          image: icdh2,
        },
        {
          id: 1.3,
          title: `ПРИМЕНА`,
          description: `Smart Lean Technology™, ве одржува самоуверено приземјени и владее со патот со Bosch IMU со 6 оски, динамичка контрола на тракцијата, ABS со контрола во свиоци и контрола на вртежниот момент.`,
          image: icdh3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: `Злобен мотоцикл со удобност и стил што го прави возењето понатаму и подолго како авантура како никогаш досега.`,
      members: [
        {
          id: 2.1,
          title: `ДИЗАЈН`,
          description: `Агресивниот дизајн на целосно LED осветлување и заканувачки стилизирани светла обезбедуваат препознатливо лице кое води и во стилот и во функцијата. Затемнетите завршетоци и премиум бојата придонесуваат за агресивниот изглед.`,
          image: icdh4,
        },
        {
          id: 2.2,
          title: `ЛИДЕР`,
          description: `Создаден во црно од браник до браник, Challenger Dark Horse има лидерско присуство.`,
          image: icdh5,
        },
        {
          id: 2.3,
          title: `ЗВУК`,
          description: `Звучниците од 6,5 инчи кои обезбедуваат кристално чист звук од 100W, додека динамичниот еквилајзер автоматски се прилагодува за бучавата од патот, ветерот и моторот за никогаш да не пропуштите никаков ритам.`,
          image: icdh6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: `ЦРНАТА БОЈА СИНОНИМ ЗА ИНОВАЦИЈА.`,
      mainDescription: `Создаден со напредна технологија и функции, Indian Challenger Dark Horse ја комбинира модерната удобност со изразениот затемнет стил и удобност.`,
      members: [
        {
          id: 3.1,
          title: `СТОПИРАЊЕ`,
          description: `Сопирачките Brembo® со радијално монтирани спецификации за трки обезбедуваат супериорна моќ на запирање, а пневматиците Metzeler® Cruisetec® при изведба на турнеи нудат врвна влечна сила.`,
          image: icdh7,
        },
        {
          id: 3.2,
          title: `КОМФОРТ`,
          description: `Изберете помеѓу три режими на возење,Rain, Standard, and Sport, за искуство што е приспособено на вашиот стил на возење. Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога мотоциклот е запрен за зголемена удобност во бавниот сообраќај.`,
          image: icdh8,
        },
        {
          id: 3.3,
          title: `КАРАКТЕРИСТИКИ`,
          description: `Возете удобно подолго време со палење без клуч, тврди торби за складирање што се заклучуваат со далечинско, темпомат и USB-порта за полнење.`,
          image: icdh9,
        },
      ],
    },
  ];
  
export { IndianChallengerDarkHorseFeaturedItems };