import { useRef, useState } from 'react';
import { innerSoundText, soundHeadingText } from '../../../Constants/AllConstants';

const SoundMotorcycle = ({ sound, soundPoster}) => {

    const video = useRef();
    const [isPlaying, setIsPlaying] = useState(false);


    const handleVideoPlaying = () => {
        if (isPlaying) {
            video.current.pause();
          } else {
            video.current.play();
          }
          setIsPlaying(!isPlaying);
    }

    return (
        <div className="sound_motorcycle">
            <div className="sound_text">
                <h3>{soundHeadingText}</h3>
                <p>{innerSoundText}</p>
            </div>
            <div className="sound_diagram">
                <video poster={soundPoster} ref={video}>
                    <source src={sound} type="video/mp4"/>
                </video>
                <div className='audio_controls' data-state='visible'>
                    <div className='toggle_switch_engine' data-state='play'>
                    <span onClick={handleVideoPlaying}>{isPlaying ? 'Stop' : 'Start'}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoundMotorcycle;