import { useEffect, useState } from "react";
import bloglist from "../../../../Home Page/constants/bloglist";
import Header from "../../../../Main Components/Header";
import HeroHeading from "../../components/HeroHeading";
import MeetTheFamily from "../../components/MeetTheFamily";
import Parallax from "../../../../Main Components/Parallax";
import Footer from "../../../../Main Components/Footer";
import backgroundImage from './Elite Family Images/elite-family-background.jpg';
import ELITEMTFDetails from "./ELITEMTFDetails";


const EliteFamily = () => {

    const heroTitle = `ELITE`;
    const heroDescription = `
    Елитните модели се дизајнирани за возачи кои го бараат најдоброто од најдобрите и сакаат да се издвојат од толпата. Обработката на бојата, завршетоците и компонентите инспирирани по нарачка во комбинација со врвните погодности ги прават овие мотоцикли навистина елитни.`;

    const [sortedList, setSortedList] = useState();

   useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
    const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
    setSortedList(newList);
   }, [])


    return(
        <>
        <Header/>
            <div className="family_background">
                <img src={backgroundImage} alt=""></img>
            </div>
            <HeroHeading heroTitle={heroTitle} heroDescription={heroDescription}/>
            <MeetTheFamily mtfdetails={ELITEMTFDetails}/>
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
        <Parallax/>
        <Footer/>
        </>

    )
}

export default EliteFamily;