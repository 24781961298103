import { useEffect, useState } from "react"
import Header from "../../Main Components/Header"
import TestRideForm from "../Test Ride/TestRideForm"
import bloglist from "../../Home Page/constants/bloglist";
import Parallax from "../../Main Components/Parallax";
import Footer from "../../Main Components/Footer";

const ContactUs = () => {

    const [sortedBlogList, setSortedBlogList] = useState();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })

        const sortedArray = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedBlogList(sortedArray);
    }, []);

    return (
        <>
            <Header/>
            <section className="test_ride_heading">
                    <h1>КОНТАКТ</h1>
                    <TestRideForm/>
            </section>
            <div className="home_blog_container">
            {
                sortedBlogList&&sortedBlogList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default ContactUs;