
import roadmaster1 from '../Touring Motorcycles Images/roadmaster-item-1.webp';
import roadmaster2 from '../Touring Motorcycles Images/roadmaster-item-2.webp';
import roadmaster3 from '../Touring Motorcycles Images/roadmaster-item-3.webp';
import roadmaster4 from '../Touring Motorcycles Images/roadmaster-item-4.webp';
import roadmaster5 from '../Touring Motorcycles Images/roadmaster-item-5.webp';
import roadmaster6 from '../Touring Motorcycles Images/roadmaster-item-6.webp';
import roadmaster7 from '../Touring Motorcycles Images/roadmaster-item-7.webp';
import roadmaster8 from '../Touring Motorcycles Images/roadmaster-item-8.webp';

import rl1 from '../Touring Motorcycles Images/rl-item-1.webp';
import rl2 from '../Touring Motorcycles Images/rl-item-2.webp';
import rl3 from '../Touring Motorcycles Images/rl-item-3.webp';
import rl4 from '../Touring Motorcycles Images/rl-item-4.webp';
import rl5 from '../Touring Motorcycles Images/rl-item-5.webp';
import rl6 from '../Touring Motorcycles Images/rl-item-6.webp';
import rl7 from '../Touring Motorcycles Images/rl-item-7.webp';
import rl8 from '../Touring Motorcycles Images/rl-item-8.webp';


import rdh1 from '../Touring Motorcycles Images/rdh-item-1.webp';
import rdh2 from '../Touring Motorcycles Images/rdh-item-2.webp';
import rdh3 from '../Touring Motorcycles Images/rdh-item-3.webp';
import rdh4 from '../Touring Motorcycles Images/rdh-item-4.webp';
import rdh5 from '../Touring Motorcycles Images/rdh-item-5.webp';
import rdh6 from '../Touring Motorcycles Images/rdh-item-6.webp';
import rdh7 from '../Touring Motorcycles Images/rdh-item-7.webp';
import rdh8 from '../Touring Motorcycles Images/rdh-item-8.webp';
import rdh9 from '../Touring Motorcycles Images/rdh-item-9.webp';

import ipl1 from '../Touring Motorcycles Images/ipl-item-1.webp';
import ipl2 from '../Touring Motorcycles Images/ipl-item-2.webp';
import ipl3 from '../Touring Motorcycles Images/ipl-item-3.webp';
import ipl4 from '../Touring Motorcycles Images/ipl-item-4.webp';
import ipl5 from '../Touring Motorcycles Images/ipl-item-5.webp';
import ipl6 from '../Touring Motorcycles Images/ipl-item-6.webp';
import ipl7 from '../Touring Motorcycles Images/ipl-item-7.webp';
import ipl8 from '../Touring Motorcycles Images/ipl-item-8.webp';
import ipl9 from '../Touring Motorcycles Images/ipl-item-9.webp';

import ipdh1 from '../Touring Motorcycles Images/ipdh-item-1.webp';
import ipdh2 from '../Touring Motorcycles Images/ipdh-item-2.webp';
import ipdh3 from '../Touring Motorcycles Images/ipdh-item-3.webp';
import ipdh4 from '../Touring Motorcycles Images/ipdh-item-4.webp';
import ipdh5 from '../Touring Motorcycles Images/ipdh-item-5.webp';
import ipdh6 from '../Touring Motorcycles Images/ipdh-item-6.webp';
import ipdh7 from '../Touring Motorcycles Images/ipdh-item-7.webp';
import ipdh8 from '../Touring Motorcycles Images/ipdh-item-8.webp';
import ipdh9 from '../Touring Motorcycles Images/ipdh-item-9.webp';


const RoadmasterFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `TOUCHSCREEN ОД RIDE COMMAND`,
          description: `Прекрасниот екран на допир од 7 инчи, напојуван со RIDE COMMAND, се одликува со интеграција на Apple CarPlay®, навигација, поврзување со Bluetooth® и многу повеќе.`,
          image: roadmaster1,
        },
        {
          id: 1.2,
          title: `THUNDERSTROKE 116 V-TWIN`,
          description: `Моторот Thunderstroke 116 со воздушно ладење произведува 171 Nm вртежен момент за исклучителна моќност при ниски вртежи во минута и непречено возење при секоја брзина.`,
          image: roadmaster2,
        },
        {
          id: 1.3,
          title: `КОМФОРТ`,
          description: `Загреаните рачки и кадифното седиште за двајца со независни контроли на топлина и за патникот и за возачот гарантираат дека ќе продолжите понатаму со удобност. Цврстите долни облоги со прилагодливи отвори им овозможуваат на возачите да го приспособат протокот на воздух за супериорна удобност на возачот.`,
          image: roadmaster3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: `ПОВИСОК СТАНДАРД`,
      mainDescription: `Врвните погодности за возење вклучуваат LED осветлување, палење без клуч, темпомат, ABS и следење на притисокот во гумите.`,
      members: [
        {
          id: 2.1,
          title: `ДОЛГИ ДЕСТИНАЦИИ`,
          description: `Патот можеби е долг, но не мора да се чувствувате така. Без разлика колку далеку возите, Roadmaster е опремен да ве однесе таму со стил и удобност.`,
          image: roadmaster4,
        },
        {
          id: 2.2,
          title: `135 ЛИТРИ ТОВАРЕН ПРОСТОР`,
          description: `Заштитете го вашиот товар од елементите во складиштето отпорно на атмосферски влијанија со торби за складирање со далечинско заклучување, багажникот и складирање со лесен пристап во долните делови.`,
          image: roadmaster5,
        },
        {
          id: 2.3,
          title: `ПРЕМИУМ АУДИО`,
          description: `Звучниците со висок излез и багажникот обезбедуваат кристално чист звук од 200W. Исто така, има динамичен еквилајзер кој автоматски се прилагодува за бучавата од патот, ветерот и моторот.`,
          image: roadmaster6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 3.1,
          title: `ШОФЕРШАЈБНА`,
          description: `Ветробранското стакло со копче ви овозможува лесно менување на висината. Чувајте го на ниско ниво за ветар во вашето лице или подигнете го за удобно патување.`,
          image: roadmaster7,
        },
        {
          id: 3.2,
          title: `ВОЗЕТЕ КОМФОРНО`,
          description: `Изберете помеѓу три режими на возење, Tour, Standard, and Sport,  за искуство што е приспособено на вашиот стил на возење. Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога мотоциклот е запрен за зголемена удобност во бавниот сообраќај.`,
          image: roadmaster8,
        },
        {
          id: 3.3,
          title: ``,
          description: ``,
          image: ``,
        },
      ],
    },
  ];
  
  export { RoadmasterFeaturedItems };

  const RoadmasterLimitedFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `ВОЗЕТЕ ВО УДОБНОСТ И СТИЛ`,
          description: `Без разлика колку далеку возите, Roadmaster Limited е опремен да ве однесе таму во удобност и стил со брилијантна боја и врвни хромирани завршетоци.`,
          image: rl1,
        },
        {
          id: 1.2,
          title: `THUNDERSTROKE 116 V-TWIN`,
          description: `Моторот Thunderstroke 116 со воздушно ладење произведува 171 Nm вртежен момент за исклучителна моќност при ниски вртежи во минута и непречено возење при секоја брзина.`,
          image: rl2,
        },
        {
          id: 1.3,
          title: `СЕДИШТЕ`,
          description: `Седиште со греење/ладење на ClimaCommand стандард, што додава уште поголема удобност на возачот и патникот без оглед на временските услови. Прилагодете се според вашите желби со контролите на седиштето или од екранот.`,
          image: rl3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: `ЗА ДОЛГИ ПАТУВАЊА`,
      mainDescription: `Патот можеби е долг, но не мора да се чувствува така. Roadmaster Limited доаѓа со низа модерни туристички погодности кои го подобруваат секое возење.`,
      members: [
        {
          id: 2.1,
          title: `140 ЛИТРИ ТОВАРЕН ПРОСТОР`,
          description: `Заштитете го вашиот товар од елементите во складиштето отпорно на атмосферски влијанија со торби за складирање со далечинско заклучување, багажникот и складирање со лесен пристап во долните делови.`,
          image: rl4,
        },
        {
          id: 2.2,
          title: `ПРЕМИУМ АУДИО`,
          description: `Звучниците со висок излез и багажникот обезбедуваат кристално чист звук од 200W. Исто така, има динамичен еквилајзер кој автоматски се прилагодува за бучавата од патот, ветерот и моторот.`,
          image: rl5,
        },
        {
          id: 2.3,
          title: `ОПТИМИЗИРАН ВОЗДУХ`,
          description: `Ветробранското стакло со копче ви овозможува лесно менување на висината.`,
          image: rl6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 3.1,
          title: `ВОЗЕТЕ КОМФОРНО`,
          description: `Изберете помеѓу три режими на возење, Tour, Standard, and Sport,  за искуство што е приспособено на вашиот стил на возење. Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога мотоциклот е запрен за зголемена удобност во бавниот сообраќај.`,
          image: rl7,
        },
        {
          id: 3.2,
          title: `ПОВИСОК СТАНДАРД`,
          description: `Врвните погодности за возење вклучуваат LED осветлување, палење без клуч, темпомат, торби за складирање со заклучување, следење на притисокот во гумите и грејни рачки.`,
          image: rl8,
        },
        {
          id: 3.3,
          title: ``,
          description: ``,
          image: ``,
        },
      ],
    },
  ];
  
  export { RoadmasterLimitedFeaturedItems };




const RoadmasterDarkHorseFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: `Со поцврсти рабови, поостри линии и повеќе црни дизајнерски карактеристики, целосно наполнетиот Indian Motorcycle Roadmaster Dark Horse е мајсторски направен, силен и добро избалансиран мотоцикл за турнеи.`,
      members: [
        {
          id: 1.1,
          title: `ЗНАЧАЕН СТИЛ`,
          description: `Самоуверениот и злобен американски стил дава смела изјава со врвни затемнети завршетоци напред-назад.`,
          image: rdh1,
        },
        {
          id: 1.2,
          title: `THUNDERSTROKE 116 V-TWIN`,
          description: `Моторот Thunderstroke 116 со воздушно ладење произведува 171 Nm вртежен момент за исклучителна моќност при ниски вртежи во минута и непречено возење при секоја брзина.`,
          image: rdh2,
        },
        {
          id: 1.3,
          title: `140 ЛИТРИ ТОВАРЕН ПРОСТОР`,
          description: `Заштитете го вашиот товар од елементите во складиштето отпорно на атмосферски влијанија со торби за складирање со далечинско заклучување, багажникот и складирање со лесен пристап во долните делови.`,
          image: rdh4,
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `СЕДИШТЕ`,
          description: `Седиште со греење/ладење на ClimaCommand се стандардно, што додава уште поголема удобност на возачот и патникот без оглед на временските услови. Прилагодете се според вашите желби со контролите на седиштето или од екранот.`,
          image: rdh5,
        },
        {
          id: 2.2,
          title: `ПРЕМИУМ АУДИО`,
          description: `Звучниците со висок излез и багажникот обезбедуваат кристално чист звук од 200W. Исто така, има динамичен еквилајзер кој автоматски се прилагодува за бучавата од патот, ветерот и моторот.`,
          image: rdh6,
        },
        {
          id: 2.3,
          title: `ОПТИМИЗИРАН ВОЗДУХ`,
          description: `Ветробранското стакло со копче ви овозможува лесно менување на висината.`,
          image: rdh7,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 3.1,
          title: `ВОЗЕТЕ КОМФОРНО`,
          description: `Изберете помеѓу три режими на возење, Tour, Standard, and Sport,  за искуство што е приспособено на вашиот стил на возење. Деактивирањето на задниот цилиндар автоматски го исклучува задниот цилиндар кога мотоциклот е запрен за зголемена удобност во бавниот сообраќај.`,
          image: rdh8,
        },
        {
          id: 3.2,
          title: `ПОВИСОК СТАНДАРД`,
          description: `Врвните погодности за возење вклучуваат LED осветлување, палење без клуч, темпомат, торби за складирање со заклучување, следење на притисокот во гумите и грејни рачки.`,
          image: rdh9,
        },
        {
          id: 3.3,
          title: ``,
          description: ``,
          image: ``,
        },
      ],
    },
  ];
  
  export { RoadmasterDarkHorseFeaturedItems };


  const IndianPursuitLimitedFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `АМЕРИКАНСКИОТ V-TWIN`,
          description: `Овој хромиран моќник ги редефинира перформансите на американските Touring. Подготвен е за секоја рута што можете да ја сонувате.`,
          image: ipl1,
        },
        {
          id: 1.2,
          title: `ГОЛЕМ МОТОР СО ГОЛЕМА СИЛА`,
          description: `Незамислив ниски вртежен момент веднаш надвор од линијата и возбудлива моќ на поминување од врвот од моторот V-twin PowerPlus со течно ладење.`,
          image: ipl2,
        },
        {
          id: 1.3,
          title: `СТАБИЛНОСТ`,
          description: `Со облога поставена на шасија, со опционално електронски прилагодлива задна суспензија Fox®, превртени предни вилушки и рамка од лиено алуминиум, ја имате врвната контрола.`,
          image: ipl3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `СОВРЕМЕНА ТЕХНОЛОГИЈА`,
          description: `Темпомат, вграденото аудио, палењето без клуч и уште повеќе технологија за подобрување на возењето се комбинирани со 7-инчен екран на допир.`,
          image: ipl4,
        },
        {
          id: 2.2,
          title: `ПРЕМИУМ АУДИО`,
          description: `Звучниците со висок излез обезбедуваат кристално чист звук од 200W. Исто така, има динамичен еквилајзер кој автоматски се прилагодува за бучавата од патот, ветерот и моторот.`,
          image: ipl5,
        },
        {
          id: 2.3,
          title: `СКЛАДИРАЊЕ`,
          description: `Повеќе од 133 литри складиште отпорно на атмосферски влијанија и со далечинско заклучување може да се справи со сета опрема што ја имате. Или откачете го багажникот со брзо ослободување за да ја намалите тежината и да си дадете сосема нов профил.`,
          image: ipl6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 3.1,
          title: `ЛУКСУЗ`,
          description: `Ткаенината поставена на рамка обезбедува исклучителна заштита од ветер самостојно. За уште повеќе, прилагодете ги отворите за вентилација во долните или едноставно притиснете го копчето за да ја дигнете шофершајбната нагоре. И возачот и патникот добиваат загреано седиште со врвен стил. Прилагодете се според вашите желби со контролите на седиштето или од екранот.`,
          image: ipl7,
        },
        {
          id: 3.2,
          title: `ВРВНО РАКУВАЊЕ`,
          description: `Електронски прилагодливото оптоварување на задната суспензија ви овозможува да го оптимизирате поставувањето на суспензијата врз основа на точно со кого возите и што влечете. Плус, технологијата Smart Lean користи Bosch Inertial Measuremenat Unit за прилагодување на контролата на влечење, ABS и вртежен момент за уште поголема контрола.`,
          image: ipl8,
        },
        {
          id: 3.3,
          title: `ПОСВЕТЛО, ПОГОЛЕМО ОСВЕТЛУВАЊЕ`,
          description: `Заедно со агресивните светла и LED фаровите, добивате и врвни светла за возење интегрирани во долните делови.`,
          image: ipl9,
        },
      ],
    },
  ];
  
  export { IndianPursuitLimitedFeaturedItems };




const IndianPursuitDarkHorseFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `АМЕРИКАНСКИОТ V-TWIN`,
          description: `Новата генерација на американскиот Touring, со агресивен затемнет крај за да одговара за секого.`,
          image: ipdh1,
        },
        {
          id: 1.2,
          title: `ГОЛЕМ МОТОР СО ГОЛЕМА СИЛА`,
          description: `Незамислив ниски вртежен момент веднаш надвор од линијата и возбудлива моќ на поминување од врвот од моторот V-twin PowerPlus со течно ладење.`,
          image: ipdh2,
        },
        {
          id: 1.3,
          title: `СТАБИЛНОСТ`,
          description: `Со облога поставена на шасија, со опционално електронски прилагодлива задна суспензија Fox®, превртени предни вилушки и рамка од лиено алуминиум, ја имате врвната контрола.`,
          image: ipdh3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `СОВРЕМЕНА ТЕХНОЛОГИЈА`,
          description: `Темпомат, вграденото аудио, палењето без клуч и уште повеќе технологија за подобрување на возењето се комбинирани со 7-инчен екран на допир.`,
          image: ipdh4,
        },
        {
          id: 2.2,
          title: `ПРЕМИУМ АУДИО`,
          description: `Звучниците со висок излез обезбедуваат кристално чист звук од 200W. Исто така, има динамичен еквилајзер кој автоматски се прилагодува за бучавата од патот, ветерот и моторот.`,
          image: ipdh5,
        },
        {
          id: 2.3,
          title: `СКЛАДИРАЊЕ`,
          description: `Повеќе од 133 литри складиште отпорно на атмосферски влијанија и со далечинско заклучување може да се справи со сета опрема што ја имате. Или откачете го багажникот со брзо ослободување за да ја намалите тежината и да си дадете сосема нов профил.`,
          image: ipdh6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 3.1,
          title: `ЛУКСУЗ`,
          description: `Ткаенината поставена на рамка обезбедува исклучителна заштита од ветер самостојно. За уште повеќе, прилагодете ги отворите за вентилација во долните или едноставно притиснете го копчето за да ја дигнете шофершајбната нагоре. И возачот и патникот добиваат загреано седиште со врвен стил. Прилагодете се според вашите желби со контролите на седиштето или од екранот.`,
          image: ipdh7,
        },
        {
          id: 3.2,
          title: `ВРВНО РАКУВАЊЕ`,
          description: `Електронски прилагодливото оптоварување на задната суспензија ви овозможува да го оптимизирате поставувањето на суспензијата врз основа на точно со кого возите и што влечете. Плус, технологијата Smart Lean користи Bosch Inertial Measuremenat Unit за прилагодување на контролата на влечење, ABS и вртежен момент за уште поголема контрола.`,
          image: ipdh8,
        },
        {
          id: 3.3,
          title: `ПОСВЕТЛО, ПОГОЛЕМО ОСВЕТЛУВАЊЕ`,
          description: `Заедно со агресивните светла и LED фаровите, добивате и врвни светла за возење интегрирани во долните делови.`,
          image: ipdh9,
        },
      ],
    },
  ];
  
  export { IndianPursuitDarkHorseFeaturedItems };
