import georgehendee from '../Becoming Legendary/Becoming Legendary Images/george-hendee-lg.webp';
import carloscar from '../Becoming Legendary/Becoming Legendary Images/carl-oscar-hedstrom-lg.webp';
import erwin from '../Becoming Legendary/Becoming Legendary Images/erwin-cannon-ball-baker-lg.webp';
import records from '../Becoming Legendary/Becoming Legendary Images/records-and-accolades-lg.webp';
import wartime from '../Becoming Legendary/Becoming Legendary Images/war-time-production-lg.webp';

import blbox1 from '../Becoming Legendary/Becoming Legendary Images/BL-item-1.webp';
import blbox2 from '../Becoming Legendary/Becoming Legendary Images/BL-item-2.webp';
import blbox3 from '../Becoming Legendary/Becoming Legendary Images/BL-item-3.webp';
import blbox4 from '../Becoming Legendary/Becoming Legendary Images/BL-item-4.webp';


import brockhouse from '../Tumultuous Times/Tumultuous Times Images/brockhouse-era-lg.webp';
import gilroy from '../Tumultuous Times/Tumultuous Times Images/gilroy-stellican-eras-lg.webp';
import burt from '../Tumultuous Times/Tumultuous Times Images/burt-munro-lg.webp';

import jaredmees from '../2011 and Beyond/2011 and Beyond Images/jared-mees-lg.webp';
import vicky from '../2011 and Beyond/2011 and Beyond Images/vicki-golden-lg.webp';
import travis from '../2011 and Beyond/2011 and Beyond Images/travis-pastrana-lg.webp';

import chief from '../2011 and Beyond/2011 and Beyond Images/chief-lg.webp';
import scout from '../2011 and Beyond/2011 and Beyond Images/indian-scout-lg.webp';
import ftr1200 from '../2011 and Beyond/2011 and Beyond Images/ftr-1200-lg.webp';
import challenger from '../2011 and Beyond/2011 and Beyond Images/indian-challenger-lg.webp';




const BecomingLegendaryTopDescription = [
    {
        id: 1,
        description: `Во 1897 година, индустрискиот пионер Џорџ М. Хенди основал компанија за производство на мотоцикли наречена Hendee Manufacturing Company. Заедно со ко-основачот и главен инженер Оскар Хедстром, Хенди ја отвори првата фабрика на компанијата во центарот на Спрингфилд во 1901 година.`
    },
    {
        id: 2,
        description: `Она што следеше беше половина век неспоредлив раст во производството и иновациите на мотоцикли, и основно лидерство во курирањето на вистинската американска култура на возење. Во 1923 година, компанијата го промени своето име од The Hendee Manufacturing Company во The Indian Motocycle Company -  кога зборот првпат се користеше со името Indian. Помалку од две децении подоцна, возачката група Jackpine Gypsies со седиште во Sturgis, Јужна Дакота, одржа трка наречена Black Hills Classic, која еволуираше и стана најголемото мото рели во светот.`,
    },
    {
        id: 3,
        description: `Во текот на раните години на постоење, компанијата покажа извонредна издржливост додека нејзиниот врвен успех во иновациите и на тркачката патека продолжи и покрај доаѓањето на Големата депресија и двете светски војни. Всушност, не само што брендот ги преживеа овие бурни времиња, туку неговиот значителен придонес во воените напори за време на Првата и Втората светска војна обезбеди основни возила и за американските и за француските трупи на теренот.`
    }
]

export { BecomingLegendaryTopDescription }



const BecomingLegendaryEra1 = [
    {
        id: 1,
        title: `GEORGE HENDEE`,
        image: georgehendee,
        text: [
            {
                description: `Пред да ја основа компанијата за производство на Хенди во 1897 година, Џорџ М. Хенди беше успешен тркач на велосипеди и производител од Вотертаун, Масачусетс. Во 1901 година, Хенди го ангажирал Оскар Хедстром за да изгради велосипеди на бензин за да ги забрза велосипедските трки и ја отвори првата фабрика на компанијата подоцна истата година во центарот на Спрингфилд, Масачусетс. Машината што ја произведе парот се покажа како моќна и сигурна, воспоставувајќи ја репутацијата на компанијата за извонредни перформанси.`,
            },
            {
                description: `Подоцна во 1915 година, Хенди поднесе оставка како генерален директор, но одлучи да остане на функцијата претседател, додека целосно не се повлече од компанијата за производство на Хенди во 1916 година.`
            }
        ]
    },
    {
        id: 2,
        title: `CARL OSCAR HEDSTROM`,
        image: carloscar,
        text: [
            {
                description: `Карл Оскар Хедстром ја ко-основаше компанијата за производство Хенди, служејќи како главен инженер до неговата оставка во 1913 година. Првата американска компанија за мотоцикли.`,
            },
            {
                description: `Покрај неговата револуционерна кариера како производител на мотоцикли, Хедстром, исто така, уживаше значителен успех како возач, особено постигнувајќи тогаш рекордна брзина од 56 милји на час додека победи на трката од Њујорк до Спрингфилд, Масачусетс во 1903 година.`
            }
        ]
    }
]

export { BecomingLegendaryEra1 }

const BecomingLegendaryEra2 = [
    {
        id: 1,
        title: `ERWIN “CANNON BALL” BAKER`,
        image: erwin,
        text: [
            {
                description: `Ервин „Кенон Бол“ Бејкер беше пионер на трките на долги патеки и ран амбасадор на Indian Motorcycle. Како одличен доказ за издржливоста на Indian Motorcycle, Бејкер возеше од Сан Диего до источниот брег за нов рекорд од 11 дена, 12 часа и 10 минути во 1914 година.`,
            },
        ]
    },
    {
        id: 2,
        title: `РЕКОРДИ И ПРИЗНАНИЦИ`,
        image: records,
        text: [
            {
                description: `Раните тркачки години на Hendee Manufacturing Company беа обележани со најголем успех во издржливите возења. Во првото тестирање за доверливост на компанијата во 1903 година, ко-основачот и главен инженер Оскар Хедстром возеше еден од неговите мотоцикли до нов светски брзински рекорд од 56 милји на час и победи на трката на издржливост од Њујорк до Спрингфилд, Масачусетс и назад. Надоврзувајќи се на успехот на ко-основачот, во 1906 година Џорџ Холден и Луис Џеј Мулер возеле индиски мотоцикл од Сан Франциско до Њујорк Сити за рекордни 31 ден без механички проблем.`,
            },
            {
                description: `Како што растеше институцијата американски трки со мотоцикли, конкурентниот фокус на компанијата се префрли на патеката. Во 1937 година, познатиот тркач Ед Крец победи на првата трка Дејтона 200 на Indian Sport Scout.`
            },
            {
                description: `Оваа рана ера на тркачки успех, исто така, го виде основањето на  Indian Motorcycle Wrecking Crew во доцните 1940-ти, тркачки тим кој соборуваше рекорди составен од легендарните тркачи Боби Хил, Бил Туман и Ерни Бекман. До почетокот на 1950-тите, екипажот доминираше и на земјените и на патиштата; Боби Хил победи на Спрингфилд Миле во 1951 и 1952 година, а соиграчот Бил Туман го заврши трите-пет за ИМ во 1953 година.`
            }
        ]
    },
    {
        id: 3,
        title: `ПРОИЗВОДСТВО ВО ВРЕМЕ НА ВОЈНА`,
        image: wartime,
        text: [
            {
                description: `Армијата на САД почна да користи Indian Motorcycles во 1913 година, а со доаѓањето на Првата светска војна, Indian Motorcycle го посвети поголемиот дел од своето производство на воените напори откако САД влегоа на теренот во 1917 година. Како резултат на оваа промена за да се сретне со војната временската побарувачка од војската, дилерите имаа ограничени залихи и продажбата на мало значително опадна. Севкупно, компанијата придонела со речиси 50.000 мотоцикли од 1917 до 1919 година, повеќето од нив базирани на моделот Powerplus.`
            },
            {
                description: `Две децении подоцна, САД беа заплеткани во уште еден глобален катаклизмичен конфликт: Втората светска војна. Повторно, Indian Motorcycle се засили бидејќи го фокусираа практично целото свое производство од 1940 до 1945 година на сојузничката кауза во Втората светска војна. Indian Motorcycle за прв пат произведе мотоцикли првенствено за француската влада и го изгради велосипедот Model 841 за армијата на САД во 1941 година. испратени до сојузничките трупи.`
            }
        ]
    }
]

export { BecomingLegendaryEra2 }


const BecomingLegendaryBox = [
    {
        id: 1,
        image: blbox1,
        title: `ПРВИОТ V-TWIN МОТОЦИКЛ`,
        date: `1906`,
        text: `Во 1906 година, беше изграден првиот V-twin фабрички тркачки велосипед. Верзијата на тркачкиот мотор беше воведена во моделите за широка потрошувачка за моделската година од 1907 година, со што 39-ci (633 cc), 42-степени V-twin беше првиот американски V-twin сериски мотор на мотоцикли.`
    }, 
    {
        id: 2,
        image: blbox2,
        title: `INDIAN SCOUT`,
        date: `1920`,
        text: `Во 1920 година, компанијата го издаде првиот Indian Scout. Овој брз, сигурен, маневрирачки мотоцикл мами многу луѓе да почнат да возат.`
    },
    {
        id: 3,
        image: blbox3,
        title: `INDIAN CHIEF`,
        date: `1922`,
        text: `По воведувањето на Indian Scout во 1920 година, компанијата го објави Chief, нова платформа која се покажа како цврста основа за раниот раст на Indian Motorcycle.`
    }, 
    {
        id: 4,
        image: blbox4,
        title: `INDIAN ACE`,
        date: `1927`,
        text: `Indian Ace, објавен по купувањето на Ace Motor Corporation од страна на Hendee, брзо стана главен производ на пионерското производство на компанијата во нејзините почетни години на развој.`
    }, 
]

export { BecomingLegendaryBox }


/* TUMULTUOUS TIMES LISTS */

const TumultuousTimesTopDescription = [
    {
        id: 1,
        description: `Запирањето на производството на сите модели на Indian Motorcycle во 1953 година започна петдецениска пауза во богатата историја на компанијата. Иако неколку напори во оваа ера на историјата на брендот го видоа името на заштитниот знак „Indian Motorcycle“ на неколку различни увезени мотоцикли, овие бурни времиња опфатени со неколку големи промени во сопственоста се покажаа како вистинска дивергенција од севкупниот пат до успехот поставен од оригиналот на Indian Motorcycle. пионерите. Периодот на неизвесност конечно заврши кога Polaris Industries ја купи Indian Motorcycle компанијата за мотоцикли во 2011 година, решително воведувајќи период на обновен успех кој потсетува на раниот одржлив раст на компанијата.`
    },
]

export { TumultuousTimesTopDescription }


const TumultuousTimesEra = [
    {
        id: 1,
        title: `THE BROCKHOUSE`,
        image: brockhouse,
        text: [
            {
                description: `Во 1950 година, тогашниот претседател на Indian Motorcycle Ралф Б. Роџерс поднесе оставка од својата позиција и беше заменет од англискиот бизнисмен Џон Брокхаус. Во 1953 година, Indian Motorcycle компанијата за производство на мотоцикли престана со работа и го прекина производството на сите модели, а името на брендот беше купено две години подоцна од Brockhouse Engineering, која почна да продава увезени мотоцикли Royal Enfield брендирани како Indian Motorcycles. Во 1960 година, Џон Брокхаус ги продаде сè помалите остатоци од бизнисот на Associated Motorcycle Company, а брендот згасна набргу потоа.`,
            },
            {
                description: `Водачот на индустријата Флојд Клајмер ги купи правата за името на брендот во 1963 година и повторно се обиде да ги ребрендира британските мотоцикли со значката „Indian Motorcycle“ сè до неговата смрт во 1970 година, кога неговиот адвокат ја презеде сопственоста на трговската марка. Под водство на адвокатот на Клајмер, продажбата нагло опадна и операциите повторно престанаа во 1977 година.`
            }
        ]
    },
    {
        id: 2,
        title: `THE GILROY AND STELLICAN`,
        image: gilroy,
        text: [
            {
                description: `Ексклузивното производство на Indian Motorcycles од брендот продолжи во 1998 година. Со спојување на девет компании беше формирана Indian Motorcycles компанијата за мотоцикли на Америка (IMCA), која отвори производствен капацитет во Гилрој, Калифорнија. IMCA произведе модифицирани верзии на некои од успешните рани модели на Indian, како што се моделите Chief и Scout, пред да банкротира во 2003 година.`,
            },
            {
                description: `По завршувањето на IMCA, фирмата за приватен капитал Stellican Ltd. со седиште во Лондон ги купи средствата за Indian Motorcycle и основа производствен капацитет на Индиската компанија за мотоцикли во Кингс Маунтин, Северна Каролина. Објектот произведе скромен број мотоцикли пред Stellican да го продаде Indian Motorcycle на Polaris Industries во 2011 година.`
            },
        ]
    },
    {
        id: 3,
        title: `BURT MUNRO`,
        image: burt,
        text: [
            {
                description: `Во 1967 година, 68-годишниот Новозеланѓанец Бурт Мунро влезе во историјата на мотоциклите поставувајќи нов официјален брзински рекорд на копно - 184,087 милји на час со неофицијална максимална брзина од 205,67 милји на час - кога се тркаше со својот силно модифициран Indian Scout Streamliner од 1920 година преку Бон Солт. Јута.`
            },
            {
                description: `Манро почна да зима голем број рекорди за брзина на копно во Нов Зеланд во 1940-тите, а до 1950-тите неговиот Scout беше пребрз за брзинските мотоцикли во Нов Зеланд. Со текот на годините, Манро и неговиот Scout се тркаа на Bonneville Salt Flats девет пати и поставија светски рекорди во три од нив, што кулминираше со неговото последно патување и возење со рекорди во 1967 година. Бурт Манро беше примен во Куќата на славните АМА во 2006 година.`
            }
        ]
    }
]

export { TumultuousTimesEra }


/* 2011 and Beyond Lists */


const AndBeyondTopDescription = [
    {
        id: 1,
        description: `2010-тите беа деценија полна со возбудлив развој и раст за Indian Motorcycle. Ревитализиран со преземањето на компанијата од страна на Polaris Industries во 2011 година, Indian Motorcycle оттогаш повторно се приклучи на патеката што ја започнаа првите пионери на компанијата во првата половина на 20 век. Денешната компанија за мотоцикли комбинира технолошки иновации со високи перформанси, наградувачки партнерства и постојано проширувачка постава на автентични, иконски мотоцикли.`
    },
]

export { AndBeyondTopDescription }




const AndBeyondEra = [
    {
        id: 1,
        title: `JARED MEES`,
        image: jaredmees,
        text: [
            {
                description: `Започнувајќи со враќањето на IIndian Motorcycle Wrecking Crew заедно со неговите соиграчи Брајар и Бронсон Бауман, Џаред „Џамер“ Мис навистина се етаблира како лидер на рамната патека. Младиот тркач е 5-кратен национален шампион во Flat Track Grand, вклучувајќи ги и првенствата во 2017 и 2018 година.`,
            },
        ]
    },
    {
        id: 2,
        title: `VICKY GOLDEN`,
        image: vicky,
        text: [
            {
                description: `Од кога зачекори на мотокрос сцената, Вики Голден ја менува перцепцијата за жените. Растејќи во Сан Диего, Калифорнија, таа почнала да вози и да се трка со мотоцикли на млада возраст. Таа беше првата жена-членка на озлогласениот Метал Мулиша и има освоено три последователни златни медали за женска трка во Мото Х на X Games. Во 2014 година, таа беше номинирана за наградата ESPY за најдобар женски акционен спортски спортист.`,
            },
        ]
    },
    {
        id: 3,
        title: `TRAVIS PASTRANA`,
        image: travis,
        text: [
            {
                description: `Во 2018 година, познатиот каскадер Тревис Пастрана рекреираше три од најпознатите акробации за скокови на Евел Нојвел во период од еден, три часа. `
            },
        ]
    }
]

export { AndBeyondEra }



const AndBeyondBox = [
    {
        id: 1,
        image: chief,
        title: `CHIEF`,
        date: `2013`,
        text: `Indian Motorcycle го лансираше својот прв мотоцикл по купувањето на Polaris: модерен Chief со високи перформанси, инспириран од успехот на својот имењак речиси еден век порано`
    }, 
    {
        id: 2,
        image: scout,
        title: `INDIAN SCOUT`,
        date: `2014`,
        text: `Прв, потполно нов Indian Scout  за повеќе од 70 години, додавајќи во обемната постава од пет модели.`
    },
    {
        id: 3,
        image: ftr1200,
        title: `FTR 1200`,
        date: `2017`,
        text: `По триумфалното враќање на трките на Flat Track со јавното деби на наменски изградениот FTR 750, Indian Motorcycle го претстави FTR 1200. Роден на патеката, изграден за улица.`
    }, 
    {
        id: 4,
        image: challenger,
        title: `INDIAN CHALLENGER`,
        date: `2019`,
        text: `Indian Motorcycle ги турка американските V-twins нанапред со доаѓањето на целосно новиот 2020 Challenger, придвижуван од новиот мотор PowerPlus`
    }, 
]

export { AndBeyondBox }