import FeaturedItem from "./FeaturedItem";

const Featured = ({items}) => {
    return (
        <div className="featured">
            <div className="featured_container">
                <h1>{items.mainTitle}</h1>
                <p>{items.mainDescription}</p>
                <div className="featured_items_wrapper">
                <div className="featured_items">
                    {
                        items&&items.members.map((singleItem) => (
                            <FeaturedItem singleItem={singleItem}/>
                        ))
                    }
                </div>
                </div>
               
            </div>
        </div>
    )
}

export default Featured;