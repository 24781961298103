import IndianChallengerElite1 from '../Elite Motorcycles Images/indian-challenger-elite-item-1.jpg';
import IndianChallengerElite2 from '../Elite Motorcycles Images/indian-challenger-elite-item-2.jpg';
import IndianChallengerElite3 from '../Elite Motorcycles Images/indian-challenger-elite-item-3.jpg';
import IndianChallengerElite4 from '../Elite Motorcycles Images/indian-challenger-elite-item-4.jpg';
import IndianChallengerElite5 from '../Elite Motorcycles Images/indian-challenger-elite-item-5.jpg';

import IndianRoadmasterElite1 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-1.jpg';
import IndianRoadmasterElite2 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-2.jpg';
import IndianRoadmasterElite3 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-3.jpg';
import IndianRoadmasterElite4 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-4.jpg';
import IndianRoadmasterElite5 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-5.jpg';
import IndianRoadmasterElite6 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-6.jpg';


const IndianChallengerEliteFeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `НОВ ЕЛИТЕН МОДЕЛ`,
        description: `Прилагодено инспирираниот стил ги врти главите, додека водечките перформанси во класата обезбедуваат неспоредлива моќ и способност за возбудливо возење.`,
        image: IndianChallengerElite1,
      },
      {
        id: 1.2,
        title: `ДАЛЕКУ ПРЕТПОЗНАТЛИВ`,
        description: `Елитните модели се познати по нивните дизајни инспирирани по нарачка, шеми за бои со ограничено издание, значки, завршетоци на моторот и многу повеќе.`,
        image: IndianChallengerElite2,
      },
      {
        id: 1.3,
        title: `ЕДНОСТАВНО НАЈДОБАР`,
        description: `Овие мотоцикли доаѓаат целосно натоварени од линијата со врвни погодности од браникот до браникот. Секој модел на Elite е опремен со Select подни даски, затемнети шофершајбни, врвно осветлување и Powerband Audio за да ви овозможи почеток во изградбата на вашиот мотоцикл од соништата.`,
        image: IndianChallengerElite3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 2.1,
        title: `ЕКСКЛУЗИВНОСТ`,
        description: `Прилагодените мотоцикли не се наменети за усогласеност - тие се наменети за пионерите кои сакаат да одат по својот пат. За да го прослават овој дух, само 300 од секој модел се достапни низ целиот свет.`,
        image: IndianChallengerElite4,
      },
      {
        id: 2.1,
        title: `ТЕХНОЛОГИЈА ЗА ПОДОБРУВАЊЕ НА ВОЗЕЊЕТО`,
        description: `Целата технологија и аудио што ги сакате се стандардни. Прекрасен звук од 400 вати, 7-инчен екран на допир напојуван со RIDE COMMAND, прилагодливо LED осветлување и многу повеќе.`,
        image: IndianChallengerElite5,
      }
    ]
  },
];

export { IndianChallengerEliteFeaturedItems };

const IndianRoadmasterEliteFeaturedItems = [
    {
        id: 1,
        mainTitle: ``,
        mainDescription: ``,
        members: [
          {
            id: 1.1,
            title: `ИНСПИРИРАН СТИЛ`,
            description: `Indian Motorcycle Red Candy, Dark Indian Motorcycle Red Candy и Black Candy обезбедуваат врвна шема на бои што црпи инспирација од минатото и денешните сопствени мотоцикли. Комплетен со рачно обоени Шампионатски златни ленти и завршени од две од најдобрите продавници за сопствени бои во индустријата, Gunslinger Custom Paint во Колорадо и Custom Painted Vehicles од Висконсин, на секој мотоцикл му требаат повеќе од 24 часа за да се заврши.`,
            image: IndianRoadmasterElite1,
          },
          {
            id: 1.2,
            title: `ВСАДЕН ВО ИСТОРИЈАТА`,
            description: `Во 1904 година Indian Motorcycle за првпат дебитираше со иконската шема на боја на индискиот мотоцикл на брендот. Денес, ние сме возбудени да ја почитуваме нејзината иконска боја со целосно нова шема на боење со три-тонски црвени бои со врвна завршница. Во чест на оваа годишнина, централната конзола е комплетирана со силуета на Indian Camelback од 1904 година - првиот модел на индискиот мотоцикл кој дебитираше со иконската боја на Indian Motorcycle.`,
            image: IndianRoadmasterElite2,
          },
          {
            id: 1.3,
            title: `THUNDERSTROKE 116`,
            description: `Нашиот наградуван Thunderstroke 116 V-Twin има 171 Nm вртежен момент и моќ во секоја брзина.`,
            image: IndianRoadmasterElite3,
          },
        ],
      },
      {
        id: 2,
        mainTitle: ``,
        mainDescription: ``,
        members: [
          {
            id: 2.1,
            title: `ПОСВЕТЛО, ПОГОЛЕМО ОСВЕТЛУВАЊЕ`,
            description: `Приспособливиот LED фар на Pathfinder обезбедува подобрена видливост и попривлечен стил - дури и ноќе.`,
            image: IndianRoadmasterElite4,
          },
          {
            id: 2.1,
            title: `КАРАКТЕРИСТИКИ`,
            description: `Воведен во есента 2023 година, PowerBand Audio на Indian Motorcycle со засилување на басот испорачува 50% погласен звук преку 12 звучници лоцирани во предниот дел, торбите и багажникот за турнеи со уште повеќе бас во споредба со другите звучни системи.`,
            image: IndianRoadmasterElite5,
          },
          {
            id: 2.3,
            title: `СКЛАДИРАЊЕ СО ДАЛЕЧИНСКИ ЗАКЛУЧУВАЊЕ`,
            description: `Целиот товар е заштитен во пространите, атмосферски вреќи и багажникот. Едноставно притиснете едно копче на конзолата или на клучот и вашиот товар безбедно се складира.`,
            image: IndianRoadmasterElite6,
          },
        ],
      },
]

export { IndianRoadmasterEliteFeaturedItems };