import Header from "../../Main Components/Header";
import background from './Ride Command Images/ridecommand-background.png';
import './ridecommandstyle.css';
import heroimage from './Ride Command Images/template-indian-home.webp';
import mobileimage from './Ride Command Images/iphone-carte.webp';
import logoapple from './Ride Command Images/logo-apple.webp';
import logoplaystore from './Ride Command Images/google-play-logo.webp';
import planicon from './Ride Command Images/planicon.png';
import trackicon from './Ride Command Images/trackicon.png';
import careicon from './Ride Command Images/careicon.png';
import mobileicon from './Ride Command Images/mobileicon.png';
import Feature from "../Single Motorcycle/Touring Motorcycles/components/Feature";
import Parallax from "../../Main Components/Parallax";
import Footer from "../../Main Components/Footer";
import { useEffect, useState } from "react";
import bloglist from "../../Home Page/constants/bloglist";
import { Link } from "react-router-dom";


const RideCommand = () => {

    const [sortedBlogList, setSortedBlogList] = useState();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const sortedArray = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedBlogList(sortedArray);
    }, [])

    return (
       <>
        <Header/>
        <img alt="" src={background} className="ridecommand_background"></img>
        <div className="ridecommand_hero">
            <div className="ridecommand_hero_container">
                <div className="ridecommand_hero_wrapper">
                    <div className="ride_command_hero_inner">
                        <h2>ПОВРЗЕТЕ СЕ</h2>
                        <p>Изградете ја вашата виртуелна гаража за да останете во тек со најновото возило</p>
                    </div>
                    <div className="ride_command_hero_inner">
                        <h2>ПЛАНИРАЈТЕ И СЛЕДЕТЕ</h2>
                        <p>Креирајте рути со алатката за планирање на возење. Следете ги и споделете ги вашите возења со другите.</p>
                    </div>
                    <div className="ride_command_hero_inner">
                        <h2>ДНЕВНИК</h2>
                        <p>Никогаш не ставајте погрешно запис за одржување. Пријавете ги директно од телефонот додека работите.</p>
                    </div>
                </div>
                <div className="ridecommand_hero_wrapper">
                    <img alt="" src={heroimage}></img>
                </div>
            </div>
        </div>
        <div className="ridecommand_mobile">
            <div className="ridecommand_mobile_inner">
                <img alt="" src={mobileimage}></img>
            </div>
            <div className="ridecommand_mobile_inner">
                <h1>Мобилна Апликација</h1>
                <p>Инсталирајте го Ride Command® на вашиот мобилен телефон<br/> подржан од Android или Apple</p>
                <div className="mobile_images">
                    <Link to='https://apps.apple.com/us/app/indian-ride-command/id493561742'><img src={logoapple} alt=""></img></Link>
                    <Link to='https://play.google.com/store/apps/details?id=net.weathernation.mobile.indianrides'><img src={logoplaystore} alt=""></img></Link>
                </div>
            </div>
        </div>
        <div className="ridecommand_instructions">
                <div className="instructions_wrapper">
                    <img alt="" src={planicon}></img>
                    <h3>НАПРАВЕТЕ ПЛАН</h3>
                    <p>Создадете рути со повеќе локации за вашиот екран на Ride Command®.</p>
                </div>
                <div className="instructions_wrapper">
                    <img alt="" src={trackicon}></img>
                    <h3>СЛЕДИ И СПОДЕЛИ</h3>
                    <p>Следете ги вашите омилени возења и споделете го најдоброто со другите возачи.</p>
                </div>
                <div className="instructions_wrapper">
                    <img alt="" src={careicon}></img>
                    <h3>НЕГА ЗА ВАШЕТО ВОЗИЛО</h3>
                    <p>Пријавете го вашето одржување на cloud и никогаш нема да се изгуби записот.</p>
                </div>
                <div className="instructions_wrapper">
                    <img alt="" src={mobileicon}></img>
                    <h3>АПЛИКАЦИЈА</h3>
                    <p>Следете го вашето возење, понесете го Ride Command® насекаде.</p>
                </div>
        </div>
        <Feature/>
        <div className="home_blog_container">
            {
                sortedBlogList&&sortedBlogList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
       </>
    )
}

export default RideCommand;