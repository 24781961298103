import Header from "../../Main Components/Header";
import './testridestyle.css';
import selectmodel from './Test Ride Images/select-a-model.webp';
import { useEffect, useState } from "react";
import bloglist from "../../Home Page/constants/bloglist";
import Parallax from "../../Main Components/Parallax";
import Footer from "../../Main Components/Footer";
import TestRideModels from "./TestRideModels";
import TestRideForm from "./TestRideForm";
import { useLocation } from "react-router";

const TestRide = () => {

    let location = useLocation();

    const [sortedBlogList, setSortedBlogList] = useState();
    const [modelsPopUp, setModelsPopUp] = useState(false);
    const [chosenModel, setChosenModel] = useState(selectmodel);
    const [chosenModelTitle, setChosenModelTitle] = useState();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        
        const sortedArray = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedBlogList(sortedArray);

        if(location.state) {
            setChosenModelTitle(location.state.name);
            setChosenModel(location.state.image);
        }

    }, [])

    const handleModelsPopUp = () => {
        setModelsPopUp(!modelsPopUp);
    }

    return (
        <>
            <Header/>
            <div className="test_ride">
                <div className="test_ride_container">
                    <div className="test_ride_heading">
                        <h1>Закажете тест возење</h1>
                    </div>
                    <div className="test_ride_wrapper">
                        <h3>1. Изберете модел</h3>
                        <div className="test_ride_model">
                            <img onClick={handleModelsPopUp} src={chosenModel} alt=""></img>
                            <h5>{chosenModelTitle}</h5>
                            <h3 onClick={handleModelsPopUp}>{chosenModelTitle === undefined ? 'Избери модел' : 'Промени'}</h3>
                        </div>
                    </div>
                </div>
                {
                chosenModelTitle !== undefined ? <TestRideForm modelTitle={chosenModelTitle}/> : ''
                }
            </div>


            {
                modelsPopUp&&<TestRideModels modelsPopUp={modelsPopUp} setModelsPopUp={setModelsPopUp} chosenModel={chosenModel} setChosenModel={setChosenModel} setChosenModelTitle={setChosenModelTitle}/>
            }
            <div className="home_blog_container">
            {
                sortedBlogList&&sortedBlogList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default TestRide;