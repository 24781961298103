import historyicon from '../Main Images/our_history.webp';
import testrideicon from '../Main Images/test_ride_icon.webp';
import imricon from '../Main Images/imr_icon.webp';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';

const About = ({setPopUp, popUp}) => {

    const handlePopUp = () => {
        setPopUp(!popUp)
    }

    return (
        <div className="about">
             <IoMdClose onClick={handlePopUp}/>
            <div className="about_container">
                <div className="about_inner first_about">
                    <div className='about_item'>
                        <img alt='' src={historyicon}/>
                        <Link to='/history'><h2>Историја</h2></Link>
                    </div>
                    <div className='about_item'>
                        <img alt='' src={testrideicon}/>
                        <Link to='/test-ride'><h2>тест возење</h2></Link>
                    </div>
                    <div className='about_item'>
                        <img alt='' src={imricon}/>
                        <Link to='/imrg'><h2>Indian Motorcycles Riders</h2></Link>
                    </div>
                </div>
                <div className="about_inner">
                    <div className='about_item second_about'>
                        <h3>Кои сме ние?</h3>
                        <Link to='/flat-track-race/racing/'><p>Indian Motorcycle Racing</p></Link>
                        <Link to='/history'><p>Нашата историја</p></Link>
                    </div>
                    <div className='about_item second_about'>
                        <h3>Алатки и ресурси</h3>
                        <Link to='/test-ride'><p>Тест возење</p></Link>
                        <Link to='/ride-command'><p>Ride Command</p></Link>
                        <Link to='/contact-us'><p>Контакт</p></Link>
                    </div>
                    <div className='about_item second_about last_about'>
                        <h3>Брошури</h3>
                        <Link to='https://www.indianmotorcycle.ro/fileadmin/templates/model_23/flipbook/2023-accessories-catalog-emea/index.html'><p>2023 Accessories Brochure</p></Link>
                        <Link to='https://www.indianmotorcycle.ro/fileadmin/templates/model_23/flipbook/indian-ss23-catalog-emea/index.html'><p>2023 Apparel Brochure</p></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;