const Table = ({ specsData}) => {


    return (
        <div className="specs_table">
          {
            specsData && specsData.map((data) => (
            Object.entries(data).map(([key, value]) => (
               <>
               <div className="specs_table_container">
               <h2 className="table_title">{key}</h2>
                   {
                    Object.entries(value).map(([key, value]) => (
                        <>
                        <div className="specs_table_inner">
                            <h2 className="table_inner_title">{key}</h2>
                            <h2 className="table_inner_info">{value}</h2>
                        </div>
                        </>
                    ))
                   }
               </div>
                </>
            ))
            ))}
        </div>
    )
}

export default Table;