import { ImLocation, ImPhone } from 'react-icons/im';
import { BiLogoTelegram } from 'react-icons/bi';
import { FiLink } from 'react-icons/fi';


const DealerSingleInformation = ( { dealer }) => {
    return (
        <div className="dealer_single_information">
            <div className="dealer_single_header">
                <h3><span>{dealer.id}</span>{dealer.title}</h3>
            </div>
            <div className="dealer_single_content">
                <div>
                    <span><ImLocation/></span><p>{dealer.location}</p>
                </div>
               <div>
                    <span><ImPhone/></span><p>{dealer.phone}</p>
               </div>
               {
                dealer.website?  <div>
                <span><FiLink/></span><a href={dealer.website}>{dealer.website}</a>
            </div> : ''
               }
                {
                dealer.contact?  <div>
                <span><BiLogoTelegram/></span><p>{dealer.contact}</p>
            </div> : ''
               }
            </div>
        </div>
    )
}

export default DealerSingleInformation;