import Roadmaster from './Touring Family Images/roadmaster-classic-black-1.webp';
import RoadmasterLimited from './Touring Family Images/roadmaster-limited-black-azure-crystal-2.webp';
import RoadmasterDarkHorse from './Touring Family Images/roadmaster-dh-black-3.webp';
import IndianPursuitLimitedWithPremiumPackage from './Touring Family Images/pursuit-limited-premium-black-metallic-5.webp';
import IndianPursuitLimitedDarkHorseWithPremiumPackage from './Touring Family Images/pursuit-limited-premium-black-metallic-5.webp';
import RoadmasterBlack from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterBlack.webp';
import RoadmasterBlackIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterBlackIcon.png';
import RoadmasterTitanium from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterTitanium.jpg';
import RoadmasterTitaniumIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterTitaniumIcon.png';
import RoadmasterBlueMetallic from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterBlueMetallic.jpg';
import RoadmasterBlueMetallicIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterBlueMetallicIcon.png';
import RoadmasterLimitedAzure from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterLimitedAzure.webp';
import RoadmasterLimitedAzureIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterLimitedAzureIcon.webp';
import RoadmasterLimitedBlueMetallic from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterLimitedBlueMetallic.jpg';
import RoadmasterLimitedBlueMetallicIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RoadmasterLimitedBlueMetallicIcon.png';
import RDHBlack from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RDHBlack.webp';
import RDHBlackIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RDHBlackIcon.png';
import RDHMaroonMetallic from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RDHMaroonMetallic.jpg';
import RDHMaroonMetallicIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/RDHMaroonMetallicIcon.png';
import IPLBlack from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPLBlack.webp';
import IPLBlackIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPLBlackIcon.png';
import IPLRedMetallic from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPLRedMetallic.jpg';
import IPLRedMetallicIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPLRedMetallicIcon.png';
import IPLBlueMetallic from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPLBlueMetallic.jpg';
import IPLBlueMetallicIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPLBlueMetallicIcon.png';
import IPLTitaniumBlack from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPLTitaniumBlack.jpg';
import IPLTitaniumBlackIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPLTitaniumBlackIcon.png';
import IPDHBlack from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPDHBlack.webp';
import IPDHBlackIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPDHBlackIcon.png';
import IPDHStealth from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPDHStealth.webp';
import IPDHStealthIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPDHStealthIcon.png';
import IPDHSpringfieldBlue from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPDHSpringfieldBlue.jpg';
import IPDHSpringfieldBlueIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/IPDHSpringfieldBlueIcon.png';

// import challengerelite from './Touring Family Images/challengerelite.webp';
// import CESapphireIcon from '../../../Single Motorcycle/Touring Motorcycles/Touring Motorcycles Images/CESapphireBlueIcon.webp';

const TouringMTFDetails = [
    {
        id: 1,
        image: Roadmaster,
        title: `Roadmaster`,
        price: `34 690 €`,
        link: `/roadmaster`,
        members: [
            {
                color: `Black Metallic`,
                price: `34 690 €`,
                icon: RoadmasterBlackIcon,
                image: RoadmasterBlack
            },
            {
                color: `Black Metallic/Titanium Metallic`,
                price: `36 190 €`,
                icon: RoadmasterTitaniumIcon,
                image: RoadmasterTitanium
            },
            {
                color: `Spirit Blue Metallic/Silver Quartz Metallic`,
                price: `36 190 €`,
                icon: RoadmasterBlueMetallicIcon,
                image: RoadmasterBlueMetallic
            }
        ]
    },
    {
        id: 2,
        image: RoadmasterLimited,
        title: `Roadmaster Limited`,
        price: `34 990 €`,
        link: `/roadmaster-limited`,
        members: [
            {
                color: `Black Azure Crystal`,
                price: `34 990 €`,
                icon: RoadmasterLimitedAzureIcon,
                image: RoadmasterLimitedAzure
            },
            {
                color: `Spirit Blue Metallic`,
                price: `34 990 €`,
                icon: RoadmasterLimitedBlueMetallicIcon,
                image: RoadmasterLimitedBlueMetallic
            }
        ]
    },
    {
        id: 3,
        image: RoadmasterDarkHorse,
        title: `Roadmaster Dark Horse`,
        price: `35 190 €`,
        link: `/roadmaster-dark-horse`,
        members: [
            {
                color: `Black Smoke`,
                price: `35 190 €`,
                icon: RDHBlackIcon,
                image: RDHBlack
            },
            {
                color: `Maroon Metallic`,
                price: `35 590 €`,
                icon: RDHMaroonMetallicIcon,
                image: RDHMaroonMetallic
            },
        ]
    },
    {
        id: 4,
        image: IndianPursuitLimitedWithPremiumPackage,
        title: `Indian Pursuit Limited`,
        price: `35 290 €`,
        link: `/indian-pursuit-limited`,
        members: [
            {
                color: `Black Metallic`,
                price: `35 290 €`,
                icon: IPLBlackIcon,
                image: IPLBlack
            },
            {
                color: `Sunset Red Metallic`,
                price: `35 290 €`,
                icon: IPLRedMetallicIcon,
                image: IPLRedMetallic
            },
            {
                color: `Spirit Blue Metallic`,
                price: `36 790 €`,
                icon: IPLBlueMetallicIcon,
                image: IPLBlueMetallic
            },
            {
                color: `Titanium/Black Metallic`,
                price: `36 790 €`,
                icon: IPLTitaniumBlackIcon,
                image: IPLTitaniumBlack
            }
        ]
    },
    {
        id: 5,
        image: IndianPursuitLimitedDarkHorseWithPremiumPackage,
        title: `Pursuit Limited Dark Horse`,
        price: `35 790 €`,
        link: `/indian-pursuit-dark-horse`,
        members: [
            {
                color: `Black Smoke`,
                price: `35 790 €`,
                icon: IPDHBlackIcon,
                image: IPDHBlack
            },
            {
                color: `Stealth Gray`,
                price: `36 190 €`,
                icon: IPDHStealthIcon,
                image: IPDHStealth
            },
            {
                color: `Springfield Blue`,
                price: `37 040 €`,
                icon: IPDHSpringfieldBlueIcon,
                image: IPDHSpringfieldBlue
            }
        ]
    },
    /* removed from Touring Family also from routing and dropdown list for test-ride*/
    // {
    //     id: 6,
    //     image: challengerelite,
    //     title: `Challenger Elite`,
    //     price: `36 236 €`,
    //     link: `/challenger-elite`,
    //     members: [
    //         {
    //             color: `Sapphire Blue Smoke`,
    //             price: `36 236 €`,
    //             icon: CESapphireIcon,
    //             image: challengerelite
    //         }
    //     ]
    // },
]

export default TouringMTFDetails;