import { useEffect, useState } from "react";
import Header from "../../../Main Components/Header";
import Button from "../Components/Button";
import SpecificationsList from "../constants/SpecificationsList";
import CruiserMTFDetails from "../../Motorcycles Family/Single Motorcycle Family/CRUISER Family/CruiserMTFDetails";
import bloglist from "../../../Home Page/constants/bloglist";
import Widget from "../Components/Widget";
import superchieflimitedtop from './Cruiser Motorcycles Images/superchieflimitedtop.webp';
import blackmetallic from './Cruiser Motorcycles Images/SCLBlackMetallic.webp';
import { SuperChiefLimitedFeaturedItems } from "./constants/CruiserFeaturedItems";
import Featured from "../Components/Featured";
import Specifications from "../Components/Specifications";
import Parallax from "../../../Main Components/Parallax";
import Footer from "../../../Main Components/Footer";
import CarouselImages from "../Components/CarouselImages";
import { SuperChiefLimitedCarouselImages } from "./constants/CruiserCarouselImages";
import { BuildYoursText, TestRideText, TestRide, innerInformationText, topWarningMessage, BuildYourOwnLinks, ContactUsLink, ContactUsText } from "../../../Constants/AllConstants";
import { useNavigate } from "react-router";



const SuperChiefLimited = () => {

    const navigate = useNavigate();

    const [motorcycleData, setMotorcycleData] = useState();
    const [sortedList, setSortedList] = useState();
    const [currentColor, setCurrentColor] = useState('Black Metallic');
    const [specificationsData, setSpecificationsData] = useState();
    const [chosenImage, setChosenImage] = useState(blackmetallic);
    const [priceMotorcycle, setPrice] = useState();
    const [allTypes, setAllTypes] = useState();
    const [buildYourOwnLink, setBuildYourOwnLink] = useState();


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        
        let foundData = SpecificationsList.filter((item) => item.family === 'Cruiser');
        let readyData = foundData[0].members.filter((item) => item.title === 'Super Chief Limited');
        setSpecificationsData(readyData);
    }, [])


    useEffect(() => {
        const getData = CruiserMTFDetails.filter((item) => item.title === `Super Chief Limited`);
        setMotorcycleData(getData);
        setPrice(getData[0].price);
        setAllTypes(getData[0].members);

        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);

        const FindBYOLink = BuildYourOwnLinks.find((item) => item.name === `Super Chief Limited`);
        setBuildYourOwnLink(FindBYOLink.link);

    }, []);

    if(!motorcycleData) {
        return null;
    }

    const { title } = motorcycleData[0];
    const headingText = title + ` `+ priceMotorcycle;

    const handleColorSwap = (color, image, newPrice) => {
        setCurrentColor(color);
        setChosenImage(image);
        setPrice(newPrice)
    }

    const handleTestRideNavigate = () => {
        const stateData = { 
            name: title,
            image: chosenImage
        };
        navigate(`${TestRide}`, { state: stateData });
}



    return (
        <>
            <Header/>
        <div className="top_content_container superchieflimited_background">
        <div className="top_content">
            <div className="top_content_text">
                <h1>{title}</h1>
                <p>Веќе од <span className="motorcycle_price">{priceMotorcycle}</span><span className="motorcycle_hero_info">{innerInformationText}</span></p>       
                <Button text={BuildYoursText} link={buildYourOwnLink}/>
                <Button text={TestRideText} onClick={handleTestRideNavigate}/>
                <Button text={ContactUsText} link={ContactUsLink}/>
                <div className="top_content_hero">
                    <p>{topWarningMessage}</p>
                </div>
            </div>
        </div>
    </div>
    <Widget headingText={headingText} innerText={innerInformationText}/>
    <div className="motorcycle_detail">
            <img src={superchieflimitedtop} alt='' className='scout_top_image'/>
            <div className="motorcycle_detail_container scout_detail_wrapper">
                <div className="motorcycle_detail_img">
                    <img alt="" src={chosenImage} ></img>
                </div>
                <div className="motorcycle_detail_wrapper">
                    <h1>{title}</h1>
                    <p>Веќе од <span className="bolded_price">{priceMotorcycle}</span> {innerInformationText}</p>
                    <div className="motorcycle_detail_colors">
                        <p>Боја: <span className="current_color">{currentColor}</span></p>
                        {
                            allTypes.map((item) => (
                                <img className="color_swap_img" src={item.icon} alt="" onClick={() => handleColorSwap(item.color, item.image, item.price)}/>
                            ))
                        }
                    </div>
                    <Button text={BuildYoursText} link={buildYourOwnLink}/>
                    <Button text={TestRideText} onClick={handleTestRideNavigate}/>
                </div>
            </div>
        </div>
        {
            SuperChiefLimitedFeaturedItems.map((items) => (
                <Featured items={items}/>
            ))
        }
        <Specifications specificationsData={specificationsData}/>
        <CarouselImages images={SuperChiefLimitedCarouselImages}/>
        <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default SuperChiefLimited;