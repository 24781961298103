
import Header from '../../../Main Components/Header';
import background from './Becoming Legendary Images/becominglegendary-background.webp';
import '../historysubstyle.css';
import TopSection from '../components/TopSection';
import { BecomingLegendaryBox, BecomingLegendaryEra1, BecomingLegendaryEra2, BecomingLegendaryTopDescription } from '../constants/HistorySubPagesList';
import EraLayout from '../components/EraLayout';
import Box from '../components/Box';
import historycaleras from '../../History/constants/historycaleras';
import { Link } from 'react-router-dom';
import Parallax from '../../../Main Components/Parallax';
import Footer from '../../../Main Components/Footer';
import { useEffect, useState } from 'react';
import bloglist from '../../../Home Page/constants/bloglist';

const BecomingLegendary = () => {

    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
    }, []);

    const topTitle = `1897 - 1952`;

    return (
        <>
            <Header/>
            <img alt="" src={background} className='historysub_topimg'></img>
            <TopSection title={topTitle} description={BecomingLegendaryTopDescription}/>
            <EraLayout list={BecomingLegendaryEra1}/>
            <div className='people_container'>
                <h4>E. PAUL DUPONT</h4>
                <p>Во 1930 година, еминентниот индустријалец Е. Пол Дупонт го продаде својот дел од Dupont Automobile на Indian Motorcycle, купувајќи голем дел од акциите на Indian Motorcycle. Е Пол го отфрли постојниот менаџерски тим на компанијата и го постави Лоринг Ф. „Џо“ Хосли задолжен за секојдневните операции, кој водеше период на застрашувачки раст за индискиот мотоцикл.</p>
            </div>
            <EraLayout list={BecomingLegendaryEra2}/>
            <Box list={BecomingLegendaryBox}/>
            <div className='historycal_eras_wrapper'>
                    {
                        historycaleras.map((item) => (
                            <Link to={item.link}>
                                  <div className='historycal_eras_inner'>
                                <img alt='' src={item.image}></img>
                                <div className='eras_hero'>
                                    <h2>{item.title}</h2>
                                    <p>{item.date}</p>
                                </div>

                            </div>
                            </Link>
                         
                        ))
                    }
                </div>
                <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default BecomingLegendary;