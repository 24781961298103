
import ftrrcarbon1 from '../Carousel Images/FTR-1.webp';
import ftrrcarbon2 from '../Carousel Images/FTR-2.webp';
import ftrrcarbon3 from '../Carousel Images/FTR-3.webp';
import ftrrcarbon4 from '../Carousel Images/FTR-4.webp';
import ftrrcarbon5 from '../Carousel Images/FTR-5.webp';


import rally1 from '../Carousel Images/Rally-1.webp';
import rally2 from '../Carousel Images/Rally-2.webp';
import rally3 from '../Carousel Images/Rally-3.webp';
import rally4 from '../Carousel Images/Rally-4.webp';
import rally5 from '../Carousel Images/Rally-5.webp';
import rally6 from '../Carousel Images/Rally-6.webp';

import sport1 from '../Carousel Images/Sport-1.webp';
import sport2 from '../Carousel Images/Sport-2.webp';
import sport3 from '../Carousel Images/Sport-3.webp';
import sport4 from '../Carousel Images/Sport-4.webp';
import sport5 from '../Carousel Images/Sport-5.webp';
import sport6 from '../Carousel Images/Sport-6.webp';

import ftr1 from '../Carousel Images/ftr-11.webp';
import ftr2 from '../Carousel Images/ftr-12.webp';
import ftr3 from '../Carousel Images/ftr-13.webp';
import ftr4 from '../Carousel Images/ftr-14.webp';
import ftr5 from '../Carousel Images/ftr-15.webp';
import ftr6 from '../Carousel Images/ftr-16.webp';

import superhooligan1 from '../Carousel Images/super-hooligan-1.jpg';
import superhooligan2 from '../Carousel Images/super-hooligan-2.jpg';
import superhooligan3 from '../Carousel Images/super-hooligan-3.jpg';
import superhooligan4 from '../Carousel Images/super-hooligan-4.jpg';
import superhooligan5 from '../Carousel Images/super-hooligan-5.jpg';
import superhooligan6 from '../Carousel Images/super-hooligan-6.jpg';
import superhooligan7 from '../Carousel Images/super-hooligan-7.jpg';

import xrcarbon1 from '../Carousel Images/x-r-carbon-1.jpg';
import xrcarbon2 from '../Carousel Images/x-r-carbon-2.jpg';
import xrcarbon3 from '../Carousel Images/x-r-carbon-3.jpg';
import xrcarbon4 from '../Carousel Images/x-r-carbon-4.jpg';
import xrcarbon5 from '../Carousel Images/x-r-carbon-5.jpg';
import xrcarbon6 from '../Carousel Images/x-r-carbon-6.jpg';


const FTRRCarbonCarouselImages = [
    {
        id: 1,
        image: ftrrcarbon1
    },
    {
        id: 2,
        image: ftrrcarbon2
    },
    {
        id: 3,
        image: ftrrcarbon3
    },
    {
        id: 4,
        image: ftrrcarbon4
    },
    {
        id: 5,
        image: ftrrcarbon5
    },
]

export { FTRRCarbonCarouselImages }


const FTRRallyCarouselImages = [
    {
        id: 1,
        image: rally1
    },
    {
        id: 2,
        image: rally2
    },
    {
        id: 3,
        image: rally3
    },
    {
        id: 4,
        image: rally4
    },
    {
        id: 5,
        image: rally5
    },
    {
        id: 6,
        image: rally6
    },
]

export { FTRRallyCarouselImages }


const FTRSportCarouselImages = [
    {
        id: 1,
        image: sport1
    },
    {
        id: 2,
        image: sport2
    },
    {
        id: 3,
        image: sport3
    },
    {
        id: 4,
        image: sport4
    },
    {
        id: 5,
        image: sport5
    },
    {
        id: 6,
        image: sport6
    },
]

export { FTRSportCarouselImages }

const FTRCarouselImages = [
    {
        id: 1,
        image: ftr1
    },
    {
        id: 2,
        image: ftr2
    },
    {
        id: 3,
        image: ftr3
    },
    {
        id: 4,
        image: ftr4
    },
    {
        id: 5,
        image: ftr5
    },
    {
        id: 6,
        image: ftr6
    },
]

export { FTRCarouselImages }

const FTRSuperHooliganCarouselImages = [
    {
        id: 1,
        image: superhooligan1
    },
    {
        id: 2,
        image: superhooligan2
    },
    {
        id: 3,
        image: superhooligan3
    },
    {
        id: 4,
        image: superhooligan4
    },
    {
        id: 5,
        image: superhooligan5
    },
    {
        id: 6,
        image: superhooligan6
    },
    {
        id: 7,
        image: superhooligan7
    }
]

export { FTRSuperHooliganCarouselImages }

const FTRXRCarbonCarouselImages = [
    {
        id: 1,
        image: xrcarbon1
    },
    {
        id: 2,
        image: xrcarbon2
    },
    {
        id: 3,
        image: xrcarbon3
    }, 
    {
        id: 4,
        image: xrcarbon4
    },
    {
        id: 5,
        image: xrcarbon5
    },
    {
        id: 6,
        image: xrcarbon6
    }
]

export { FTRXRCarbonCarouselImages }