const Overview = ({details}) => {
    return (
       <div className="family_overview">
            {
                details.map((item) => (
                    <div className="family_overview_item" key={item.id}>
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                    </div>
                ))
            }
       </div>
    )
}

export default Overview;