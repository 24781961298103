import Header from "../../../../Main Components/Header";
import HeroHeading from "../../components/HeroHeading";
import TouringBackground from './Touring Family Images/touring-background.webp';
import TouringOverviewDetails from "./TouringOverviewDetails";
import Overview from "../../components/Overview";
import MeetTheFamily from "../../components/MeetTheFamily";
import TouringMTFDetails from "./TouringMTFDetails";
import TouringHistoryLogo from './Touring Family Images/touring-history-logo.webp';
import TouringHistoryDouble from './Touring Family Images/touring-history-double.webp';
import './touringstyle.css';
import { useEffect, useState } from "react";
import bloglist from "../../../../Home Page/constants/bloglist";
import Parallax from "../../../../Main Components/Parallax";
import Footer from "../../../../Main Components/Footer";

const TouringFamily = () => {

    const heroTitle = `TOURING`;
    const heroDescription = `Овие мотоцикли се врвни машини за долги растојанија, со удобности и на возачот и на патникот им даваат луксузно искуство за секоја должина на возење.`;


    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
       }, [])


    return (
        <>
            <Header/>
            <div className="family_background">
                <img src={TouringBackground} alt=""></img>
            </div>
            <HeroHeading heroTitle={heroTitle} heroDescription={heroDescription}/>
            <Overview details={TouringOverviewDetails}/>
            <MeetTheFamily mtfdetails={TouringMTFDetails}/>
            <div className="touring_history">
                <div className="touring_history_container">
                    <div className="touring_history_logo">
                        <img src={TouringHistoryLogo} alt=""></img>
                    </div>
                    <div className="touring_history_text">
                        <h3>Патот е Вашиот дом</h3>
                        <p>Со сите елементи потребни за живот на отворен пат, туристичките мотоцикли се изградени за да поминуваат долги растојанија. Опремени со поголема моќност, повеќе простор и повеќе удобности, овие мотоцикли се направени за удобно и непречено возење.</p>
                    </div>
                    <div className="touring_history_images">
                        <img src={TouringHistoryDouble} alt=""/>
                    </div>
                </div>
        </div>
        <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default TouringFamily;