import Header from "../../Main Components/Header"
import './indianracingstyle.css'

import videotop from './Indian Racing Images/video-top.mp4';
import videoposter from './Indian Racing Images/video-poster.webp';
import { useEffect, useState } from "react";
import RacingHero from "./RacingHero";

import bikePhoto from './Indian Racing Images/bike-picture.webp';

import heroItem1 from './Indian Racing Images/hero-item-1.webp';
import heroItem2 from './Indian Racing Images/hero-item-2.webp';
import Parallax from "../../Main Components/Parallax";
import Footer from "../../Main Components/Footer";
import bloglist from "../../Home Page/constants/bloglist";

const IndianRacing = () => {

    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
    }, [])

    const title1 = `ПОВТОРУВАЊЕ НА ЛЕГЕНДАТА`;
    const description1 = `Со генерации, Indian Motorcycle® доминираше во светот на трките со мотоцикли. И сега го правиме нашето триумфално враќање на Flat Track. Со револуционерен наменски изграден тркачки мотоцикл и нов Indian Motorcycle® Wrecking Crew™ со тројца од најдобрите светски тркачи, Indian Motorcycle® се врати и подобар од кога било.`;
    const subject1 = `<iframe class="youtube-embed__item embed-responsive-item lazyloaded" data-src="//www.youtube-nocookie.com/embed/b0LS_iKOgMk?enablejsapi=1&amp;wmode=transparent&amp;hd=1&amp;autoplay=0&amp;loop=1&amp;showinfo=0&amp;rel=0&amp;origin=http://www.indianmotorcycle.com" frameborder="0" allowfullscreen="" id="embeddedvideoiframe0" src="//www.youtube-nocookie.com/embed/b0LS_iKOgMk?enablejsapi=1&amp;wmode=transparent&amp;hd=1&amp;autoplay=0&amp;loop=1&amp;showinfo=0&amp;rel=0&amp;origin=http://www.indianmotorcycle.com"></iframe>`;


    const title2 = `INDIAN WRECKING CREW`;
    const description2 = `Со генерации, Indian Motorcycle® одеше рака под рака со трките - и победувајќи. Запознајте го новиот Indian Motorcycle® Wrecking Crew, кој сака да продолжи со победничките начини на шампионатот.`;


    const title3 = `КРАЛОТ НА BAGGERS`;
    const description3 = `The Indian Challenger се враќа во King of the Baggers за 2022 година. Тимот, предводен од претходниот шампион Тајлер О'Хара, ќе се обиде да ја врати круната и да ја продолжи доминацијата на Indian Motorcycle во трките.`;
    const subject3 = `<iframe class="youtube-embed__item embed-responsive-item lazyloaded" data-src="//www.youtube-nocookie.com/embed/XB0AdIhAG5k?enablejsapi=1&amp;wmode=transparent&amp;hd=1&amp;autoplay=0&amp;loop=1&amp;showinfo=0&amp;rel=0&amp;origin=http://www.indianmotorcycle.com" frameborder="0" allowfullscreen="" id="embeddedvideoiframe0" src="//www.youtube-nocookie.com/embed/XB0AdIhAG5k?enablejsapi=1&amp;wmode=transparent&amp;hd=1&amp;autoplay=0&amp;loop=1&amp;showinfo=0&amp;rel=0&amp;origin=http://www.indianmotorcycle.com"></iframe>`;


    const title4 = `ИСТОРИЈА НА ТРКИ`;
    const description4 = `Indian Motorcyle® има историска историја на Flat Track. Оригиналниот Indian Motorcycle® Wrecking Crew од 1940-тите и 50-тите - Ерни Бекман, Бил Туман и Боби Хил - се икони на спортот. Познатиот ривал меѓу Indian и Harley-Davidson® е материјалот од кој се направени легендите. Се вративме за повторно да го разгориме огнот што со години го дефинираше спортот на трки со рамна патека. Со револуционерен нов мотоцикл и обновен Indian Motorcycle® Wrecking Crew, ние сме тука да ги предизвикаме врвните светски производители и да го вратиме нашето место на врвот на тркачкиот свет на Flat Track.`;


    return (
        <>
            <Header/>
            <div className="indianracing_top">
            <video autoPlay loop muted poster={videoposter}>
                <source src={videotop} type="video/mp4" />
            </video>
            </div>
            <RacingHero title={title1} description={description1} subject={subject1} bbflag={true}/>
            <div className="racing_box">
                <h1 className="racing_hero_title"></h1>
                <p className="racing_hero_description">Со генерации, Indian Motorcycle® доминираше во светот на трките со мотоцикли и продолжува да го прави тоа. FTR750 е докажан мотоцикл кој ги носи најдобрите тркачи во светот. Indian Scout  FTR750 беше наменски направен за да направи револуција во трките со рамна патека. Моторот од 750сс ги поместува границите на технологијата V-Twin и нашата филозофија за дизајн на чист лист гарантира дека ништо од таа моќ не се троши.</p>
            </div>
            <img alt="" src={bikePhoto} className="bike_photo"></img>
            <RacingHero title={title2} description={description2} subject={heroItem1} bbflag={false}/>
            <RacingHero title={title3} description={description3} subject={subject3} bbflag={true}/>
            <RacingHero title={title4} description={description4} subject={heroItem2} bbflag={false}/>
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default IndianRacing