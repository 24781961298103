import SingleMTFMotorcycle from "./SingleMTFMotorcycle";

const MeetTheFamily = ( { mtfdetails }) => {
    return (
        <div className="mtf_container">
           <div className="mtf_heading">
                <h2>МОДЕЛИ</h2>
           </div>
           <div className="mtf_bikes">
            <div className="mtf_bikes_wrapper">
            {
                    mtfdetails.map((motorcycle) => (
                        <SingleMTFMotorcycle motorcycle={motorcycle}/>
                    ))
                }
            </div>
               
           </div>
        </div>
    )
}

export default MeetTheFamily;