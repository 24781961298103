import { useEffect, useState } from 'react';
import './testridestyle.css';
import { IoCloseSharp } from 'react-icons/io5'
import { MotorcyclesTestRideDropdownFamily } from '../../Constants/MotorcyclesDropdownFamily';
import SingleFamilyModels from './SingleFamilyModels';

const TestRideModels = ({modelsPopUp, setModelsPopUp, setChosenModel, setChosenModelTitle}) => {

    const [modelsNavigation, setModelsNavigation] = useState([]);
    const [singleModelData, setSingleModelData] = useState(MotorcyclesTestRideDropdownFamily[0]);

    useEffect(() => {
        const getModelsFamilies = () => {
            const families = MotorcyclesTestRideDropdownFamily.map((item) => item.family);
            setModelsNavigation(families);
          };
          getModelsFamilies();
    }, [])

    const handleClickedFamily = (family, e) => {
        const elements =  document.querySelectorAll('.models_list_item');
        elements.forEach(element => {
            element.classList.remove('model_active');
          });
          e.target.classList.add('model_active')
        const singleFamilyArray = MotorcyclesTestRideDropdownFamily.filter((item) => item.family === family);
        setSingleModelData(singleFamilyArray[0]);
    }

    const handleModelsPopUp = () => {
        setModelsPopUp(!modelsPopUp);
    }


    return (
        <>
        <div className='test_ride_models'>
        <div className='models_header'>
            <h1>Изберете модел</h1>
            <IoCloseSharp onClick={handleModelsPopUp}/>
        </div>
            <ul className='testride_models_list'>
                {
                    modelsNavigation.map((item) => (
                            <li className='models_list_item' onClick={(e) => handleClickedFamily(item, e)}>{item}</li>
                    ))
                }
            </ul>
            <SingleFamilyModels family={singleModelData} setChosenModel={setChosenModel} setModelsPopUp={setModelsPopUp} modelsPopUp={modelsPopUp} setChosenModelTitle={setChosenModelTitle} />
        </div>
        
        </>
        
    )
}

export default TestRideModels;