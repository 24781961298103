import './homepagestyle.css';
import backgroundTop1 from '../Home Page/Home  Page Images/bobber-uk-lg.jpg';
import backgroundTop2 from '../Home Page/Home  Page Images/ftr-x-rsd-lg.jpg';
import backgroundTop3 from './Home  Page Images/sport-chief-background-3.webp';
import backgroundTopBottom from '../Home Page/Home  Page Images/BackgroundTopBottom.webp';
import Header from "../Main Components/Header";
import HomeHero from "./HomeHero";
import Footer from "../Main Components/Footer";
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
        <Header/>
            <div className="home_section">
                <div className="home_section_inner">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <Link to='https://www.indianmotorcycle.com/en-001/build-model/'><img src={backgroundTop1} className="d-block w-100" alt="..."/></Link>
            </div>
            <div className="carousel-item">
                <Link to='https://shop-indianmotorcycle.com/ie/new.html'><img src={backgroundTop2} className="d-block w-100" alt="..."/></Link>
           
            </div>
            <div className="carousel-item">
                <Link to='https://www.indianmotorcycle.com/en-001/build-color/?selectedmodel=cruiser&CatalogContentId=756585__CatalogContent'><img src={backgroundTop3} className="d-block w-100" alt="..."/></Link>
            
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
            <IoIosArrowBack/>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            {/* <span className="carousel-control-next-icon" aria-hidden="true"><AiOutlineArrowRight/></span> */}
            <IoIosArrowForward/>
            <span className="visually-hidden">Next</span>
        </button>
        </div>
                </div>
                <div className="home_section_inner">
                    <div className="home_top_child">
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/qKcIb-F01M0?autoplay=1&amp;mute=1&amp;rel=0" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="home_bottom_child">
                        <Link to='https://indianmotorcycle.mk/ftr-family'><img src={backgroundTopBottom} alt=""/></Link>
                    
                    </div>
                </div>
            </div>
        <HomeHero/>
        <Footer/>
        </>
        
    )
}

export default HomePage;