const DarkHorseOverviewDetails = [
    {
        id: 1,
        title: `Одговара на Вашиот стил`,
        description: `Cruiser или Touring. Indian Motorcycle има Dark Horse за да одговара на вашите потреби.`
    },
    {
        id: 2,
        title: `Моќни`,
        description: `Dark Horse ја користи моќта на Thunder Stroke V-twin или liquid-cooled, 108cu во PowerPlus кој произведува 122hp и 128 ft-lb, во комбинација со низок центар на гравитација за да обезбеди стил кој функционира.`,
    },
    {
        id: 3,
        title: `Модерна технологија`,
        description: `Технологијата што ви е потребна за да ги максимизирате вашите возења.`
    }
]

export default DarkHorseOverviewDetails;