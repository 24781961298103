import Header from "../../../../Main Components/Header";
import HeroHeading from "../../components/HeroHeading";
import MeetTheFamily from "../../components/MeetTheFamily";
import ScoutBackground from './SCOUT 1250CC Family Images/scout-1250cc-family-background.jpg';
import bloglist from "../../../../Home Page/constants/bloglist";
import { useEffect, useState } from "react";
import Parallax from "../../../../Main Components/Parallax";
import Footer from "../../../../Main Components/Footer";
import Scout1250ccMTFDetails from "./Scout1250ccMTFDetails";

const Scout1250ccFamily = () => {

    const heroTitle = `SCOUT 1250`;
    const heroDescription = `Родени од икони. Возени од легенди.Целосно новиот индиски Scout. Создадете приказни вредни за споделување.`;
    const [sortedList, setSortedList] = useState();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const newList = bloglist.filter((item) => item.id === 3 || item.id === 4);
        setSortedList(newList);
       }, [])
    
    
    return (
        <>
            <Header/>
            <div className="family_background">
                <img src={ScoutBackground} alt=""></img>
            </div>
            <HeroHeading heroTitle={heroTitle} heroDescription={heroDescription}/>
            <MeetTheFamily mtfdetails={Scout1250ccMTFDetails}/> 
            <div className="home_blog_container">
            {
                sortedList&&sortedList.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <a href={item.link} className="blog_item_title">{item.title}</a>
                        </div>
                    </>
                ))
            }
            </div>
            <Parallax/>
            <Footer/>
        </>
    )
}

export default Scout1250ccFamily;