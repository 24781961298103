
import cdh1 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-1.webp';
import cdh2 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-2.webp';
import cdh3 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-3.webp';
import cdh4 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-4.webp';
import cdh5 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-5.webp';
import cdh6 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-6.webp';
import cdh7 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-7.webp';
import cdh8 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-8.webp';
import cdh9 from '../Cruiser Motorcycles Images/chiefdarkhorse-item-9.webp';



import cbdh1 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-1.webp';
import cbdh2 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-2.webp';
import cbdh3 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-3.webp';
import cbdh4 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-4.webp';
import cbdh5 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-5.webp';
import cbdh6 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-6.webp';
import cbdh7 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-7.webp';
import cbdh8 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-8.webp';
import cbdh9 from '../Cruiser Motorcycles Images/chiefbobberdarkhorse-item-9.webp';


import scl1 from '../Cruiser Motorcycles Images/SCL-item-1.webp';
import scl2 from '../Cruiser Motorcycles Images/SCL-item-2.webp';
import scl3 from '../Cruiser Motorcycles Images/SCL-item-3.webp';
import scl4 from '../Cruiser Motorcycles Images/SCL-item-4.webp';
import scl5 from '../Cruiser Motorcycles Images/SCL-item-5.webp';
import scl6 from '../Cruiser Motorcycles Images/SCL-item-6.webp';
import scl7 from '../Cruiser Motorcycles Images/SCL-item-7.webp';
import scl8 from '../Cruiser Motorcycles Images/SCL-item-8.webp';
import scl9 from '../Cruiser Motorcycles Images/SCL-item-9.webp';


import sportchief1 from '../Cruiser Motorcycles Images/sportchief-item-1.webp';
import sportchief2 from '../Cruiser Motorcycles Images/sportchief-item-2.webp';
import sportchief3 from '../Cruiser Motorcycles Images/sportchief-item-3.webp';
import sportchief4 from '../Cruiser Motorcycles Images/sportchief-item-4.webp';
import sportchief5 from '../Cruiser Motorcycles Images/sportchief-item-5.webp';
import sportchief6 from '../Cruiser Motorcycles Images/sportchief-item-6.webp';
import sportchief7 from '../Cruiser Motorcycles Images/sportchief-item-7.webp';
import sportchief8 from '../Cruiser Motorcycles Images/sportchief-item-8.webp';
import sportchief9 from '../Cruiser Motorcycles Images/sportchief-item-9.webp';


const ChiefDarkHorseFeaturedItems = [
    {
      id: 1,
      mainTitle: ``,
      mainDescription: `Безвременски дизајн, легендарно минато и ненаметлива модерна технологија.`,
      members: [
        {
          id: 1.1,
          title: `THUNDERSTROKE 116`,
          description: `Thunderstroke 116 со воздушно ладење произведува 162 Nm необработен вртежен момент за пренесување на моќта во сите 6 брзини, што ви дава моќ да оставите сè — и секого — во вашиот "издув".`,
          image: cdh1,
        },
        {
          id: 1.2,
          title: `ДИЗАЈН`,
          description: `Наменски дизајн кој ги прикажува браниците, откриени задни амортизери и рамка од челична заварена цевка. Овој мотоцикл чека да стане ваш`,
          image: cdh2,
        },
        {
          id: 1.3,
          title: `4" RIDE COMMAND TOUCHSCREEN`,
          description: `Деталите на дизајнот ја исполнуваат модерната функција во водечкиот во класата и првиот индустриски екран на допир од 4 инчи, напојуван со RIDE COMMAND, кој се одликува со интуитивна навигација за пребарување со поврзување преку Bluetooth.`,
          image: cdh3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: `УБАВИНАТА Е ВО ДЕТАЛИТЕ.`,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `ЦРНО, И ПОВЕЌЕ ЦРНО`,
          description: `Темно по дизајн со врвни сјајно-црни завршетоци од крило до крило.`,
          image: cdh4,
        },
        {
          id: 2.2,
          title: `19" ТРКАЛО`,
          description: `Предното тркало од 19" и ABS го зголемува застрашувачкиот став и ќе ве моли да додадете гас.`,
          image: cdh5,
        },
        {
          id: 2.3,
          title: `ВОЗЕТЕ СО САМОДОВЕРБА`,
          description: `Со ниска висина на седиштето, соло седло, Chief му дава целосна контрола на секој возач.`,
          image: cdh6,
        },
      ],
    },
    {
      id: 3,
      mainTitle: `СОВРЕМЕНА ТЕХНОЛОГИЈА`,
      mainDescription: `Повеќе од она што ви треба, помалку од она што не ви треба.`,
      members: [
        {
          id: 2.1,
          title: `КАРАКТЕРИСТИКИ`,
          description: `Секој Chief Dark Horse доаѓа со палење без клуч, USB приклучок за полнење, темпомат, ABS и суперлепливи гуми Pirelli Night Dragon.`,
          image: cdh7,
        },
        {
          id: 2.2,
          title: `ОСВЕТЛУВАЊЕ`,
          description: `Целосното LED осветлување обезбедува одлична видливост на патот во ноќта.`,
          image: cdh8,
        },
        {
          id: 2.3,
          title: `РЕЖИМИ НА ВОЗЕЊЕ`,
          description: `Изберете помеѓу три режими на возење - Tour, Standard, and Sport  - за искуство што е прилагодено на вашиот стил на возење.`,
          image: cdh9,
        },
      ],
    },
  ];

export { ChiefDarkHorseFeaturedItems };


const ChiefBobberDarkHorseFeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `THUNDERSTROKE 116`,
        description: `Thunderstroke 116 со воздушно ладење произведува 162 Nm необработен вртежен момент за пренос на сила во сите 6 брзини.`,
        image: cbdh1,
      },
      {
        id: 1.2,
        title: `ЗАМРАЧЕН BOBBER СТИЛ`,
        description: `Оптоварени со став и затемнети завршетоци од браник до браник. Рамката од заварена челична цевка, големата корпа за предните фарови, и покриените вилушки и задните амортизери му даваат поздрав на традиционалниот стил на Bobber.`,
        image: cbdh2,
      },
      {
        id: 1.3,
        title: `4" RIDE COMMAND TOUCHSCREEN`,
        description: `Екранот на допир од 4 инчи одржува класична естетика со тоа што се маскира како аналоген мерач. Користете го како брзинометар и тач, но исто така обезбедува и навигација, ги контролира режимите на возење, ви овозможува да одговарате и одбивате повици и, се разбира, управување со вашата музика од кој било уред поврзан со Bluetooth.`,
        image: cbdh3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: `СТИЛ ЗА КИЛОМЕТРИ`,
    mainDescription: ``,
    members: [
      {
        id: 2.1,
        title: `ЦРНО, И ПОВЕЌЕ ЦРНО`,
        description: `Темно по дизајн со врвни сјајно-црни завршетоци од браник до браник.`,
        image: cbdh4,
      },
      {
        id: 2.2,
        title: `ПОСЕДУВАЈТЕ ГО ПАТОТ`,
        description: `Ниското соло седиште ви дава заповедничко чувство, додека напредните штипки и mini ape рачки ги ставаат вашите стапала напред и рацете на ветрот.`,
        image: cbdh5,
      },
      {
        id: 2.3,
        title: `ИКОНСКИ СТИЛ`,
        description: `Вкоренети во историјата, дизајнирани за сегашноста. Иконското лого на Indian Motorcycle е свеченост на оригиналниот Chief.`,
        image: cbdh6,
      },
    ],
  },
  {
    id: 3,
    mainTitle: `МОДЕРНА ТЕХНОЛОГИЈА`,
    mainDescription: `Повеќе од она што ви треба, помалку од она што не ви треба.`,
    members: [
      {
        id: 2.1,
        title: `КАРАКТЕРИСТИКИ`,
        description: `Секој Chief Bobber Dark Horse доаѓа со палење без клуч, USB приклучок за полнење, темпомат, ABS и суперлепливи гуми Pirelli Night Dragon.`,
        image: cbdh7,
      },
      {
        id: 2.2,
        title: `ОСВЕТЛУВАЊЕ`,
        description: `Целосното LED осветлување обезбедува одлична видливост на патот во ноќта.`,
        image: cbdh8,
      },
      {
        id: 2.3,
        title: `РЕЖИМ НА ВОЗЕЊЕ`,
        description: `Изберете помеѓу три режими на возење - Tour, Standard, and Sport - за искуство што е прилагодено на вашиот стил на возење.`,
        image: cbdh9,
      },
    ],
  },
];

export { ChiefBobberDarkHorseFeaturedItems };



const SportChiefFeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `ПЕРФОМАНСИ`,
        description: `Сè за Sport Chief беше наменски изградено од фабриката за да се изврши. Со компонентите со врвни перформанси познати низ индустријата, Sport Chief докажува дека високите стандарди никогаш не излегуваат од мода. Кога станува збор за способноста за изведба и агресивниот стил, Sport Chief ги турка американските Cruiser со перформанси V-Twin на следното ниво.`,
        image: sportchief1,
      },
      {
        id: 1.2,
        title: `ПРЕМИУМ КОМПОНЕНТИ`,
        description: `Sport Chief е Cruiser со врвни перформанси во друшството на Indian Motorcycle благодарение на компонентите со докажан бренд: подолги задни амортизери Fox®, превртени предни вилушки KYB® од 43 mm и двојни клешти со 4 клипови Brembo® со полулебдечки ротори од 320 mm.`,
        image: sportchief2,
      },
      {
        id: 1.3,
        title: `ВОЗЕТЕ СО САМОДОВЕРБА`,
        description: `Создавањето на најдоброто не застанува само на врвните компоненти за суспензија и сопирање. Ставањето на возачот во соодветна позиција за максимизирање на перформансите исто така е важно. Благодарение на соло седиштето во стилот на gunfighter со повисока потпора, зголемен агол на навалување од 29,5 степени и средни контроли, возачите можат да возат жестоко и подолго со поголема самодоверба и удобност.`,
        image: sportchief3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 2.1,
        title: `ПРИЛАГОДЕН СТИЛ`,
        description: `Sport Chief има стил на изведба што одговара на сите негови компоненти за подобрување на возењето. Новиот Quarter Fairing има шофершајбна од 6,5“ за заштита од ветер и агресивен изглед. Предните тркала од 19 инчи од лесна леана легура со контраст и виткото задно тркало од 16 инчи го поставуваат ставот и носат лепливи гуми Pirelli® Night Dragon, обезбедувајќи и стил и контрола.`,
        image: sportchief4,
      },
      {
        id: 2.2,
        title: `ДИЗАЈН`,
        description: `Подигнувањето на границата на стилот на изведба значи дека не се занемаруваат никакви детали. Стилски сјајни црни 6-инчни кревачи во мото стил со машински врвови се стандардни и значајни. Машинските тројки и рачките во мото стил го врзуваат сето тоа, како во прилагодениот изглед, така и во ергономијата што одговара на неговиот агресивен стил. `,
        image: sportchief5,
      },
      {
        id: 2.3,
        title: `МОДЕРНА ТЕХНОЛОГИЈА`,
        description: `4-инчниот екран на допир одржува класична естетика со тоа што се маскира како аналоген мерач. Обезбедува навигација со поврзани функции како што се временските услови и сообраќајните преклопувања, ви овозможува да ги контролирате режимите на возење, да одговарате и одбивате повици и да управувате со вашата музика од кој било уред поврзан со Bluetooth.`,
        image: sportchief6,
      },
    ],
  },
  {
    id: 3,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 3.1,
        title: `ГОЛЕМА МОЌ`,
        description: `На Cruiser со врвни перформанси му требаат докажани американски мускули во неговото јадро. Легендарниот Thunderstroke 116 V-twin со воздушно ладење произведува 162 Nm кои доминираат во сите 6 брзини, давајќи ви моќ да оставите сè, и секого, во вашиот издув и магла од чад од гумите.`,
        image: sportchief7,
      },
      {
        id: 3.2,
        title: `ПРИЛАГОДУВАЊЕ`,
        description: `Ги направивме тешките работи за возачите да можат да го направат свој. Sport Chief може да се пофали со класичен механички дизајн со едноставна рамка со челична цевка, V-twin мотор со воздушно ладење и иконски стил на Cruiser, што го прави лесен за прилагодување. Сечкањето и бришењето не е потребно благодарение на мноштвото фабрички додатоци за Indina Motorcycle кои лесно се инсталираат за уште постилски изглед и зголемени перформанси.`,
        image: sportchief8,
      },
      {
        id: 3.3,
        title: `КАРАКТЕРИСТИКИ`,
        description: `Нема ништо стандардно во погодностите за возење што ја менуваат играта со кои доаѓа секој Sport Chief; 3 режими на возење (Sport, Standard, Tour), темпомат, палење без клуч, ABS, LED осветлување и USB порта за полнење.`,
        image: sportchief9,
      },
    ],
  },
];

export { SportChiefFeaturedItems };

const SuperChiefLimitedFeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `THUNDERSTROKE 116`,
        description: `Thunderstroke 116 со воздушно ладење произведува 162 Nm суров вртежен момент за поминување на сила во сите 6 брзини, што ви дава моќ да оставите сè — и секого — во вашиот издув.`,
        image: scl1,
      },
      {
        id: 1.2,
        title: `ДИЗАЈНИРАН ЗА АВАНТУРА`,
        description: `Практичен дизајн значи да не се сечат агли. Создаден со седло, жичени тркала, ветробранско стакло и подни даски стандарди`,
        image: scl2,
      },
      {
        id: 1.3,
        title: `4" RIDE COMMAND TOUCHSCREEN`,
        description: `Екранот на допир од 4 инчи ја одржува класичната естетика со тоа што се маскира како аналоген мерач. Користете го како брзиномерач, но исто така обезбедува навигација, ги контролира режимите на возење, ви овозможува да одговарате и одбивате повици и, се разбира, управување со вашата музика од кој било уред поврзан со Bluetooth`,
        image: scl3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: `КЛАСИЧЕН СТИЛ КОЈ ЌЕ САКАТЕ ДА ГО ИСТАКНЕТЕ`,
    mainDescription: `Класичниот стил ги исполнува модерните способности и долгата листа на премиум карактеристики со Super Chief Limited.`,
    members: [
      {
        id: 2.1,
        title: `ПРЕМИУМ`,
        description: `Премиум хромирани завршни облоги, капаци за вилушка, жичени тркала, голема прекривка за предни светла`,
        image: scl4,
      },
      {
        id: 2.2,
        title: `КОМФОРТ`,
        description: `Подните даски, традиционалните рачки на Cruiser и седиштeто се стандардни за да гарантираат удобна положба за возење.`,
        image: scl5,
      },
      {
        id: 2.3,
        title: `ПОДГОТВЕН ЗА СЕ`,
        description: `Стандардните торбички за седло ви овозможуваат да ги продолжите вашите патувања, а шофершајбната брзо се откачува без напор за сосема поинаков изглед и чувство.`,
        image: scl6,
      },
    ],
  },
  {
    id: 3,
    mainTitle: `СОВРЕМЕНА ТЕХНОЛОГИЈА`,
    mainDescription: `Сè што ви треба за авантурата што претстои.`,
    members: [
      {
        id: 3.1,
        title: `ИЗБЕРЕТЕ РЕЖИМ НА ВОЗЕЊЕ`,
        description: `Изберете помеѓу три режими на возење - Tour, Standard, and Sport - за искуство што е прилагодено на вашиот стил на возење.`,
        image: scl7,
      },
      {
        id: 3.2,
        title: `ОСВЕТЛУВАЊЕ`,
        description: `Целосното LED осветлување обезбедува одлична видливост на патот во ноќта.`,
        image: scl8,
      },
      {
        id: 3.3,
        title: `КАРАКТЕРИСТИКИ`,
        description: `Секој Super Chief Limited доаѓа со палење без клуч, USB приклучок за полнење, темпомат, ABS и суперлепливи гуми Pirelli Night Dragon`,
        image: scl9,
      },
    ],
  },
];

export { SuperChiefLimitedFeaturedItems };